import React, { useContext } from 'react'
import Proptypes from 'prop-types'
import get from 'lodash/get'
import DoneIcon from '@material-ui/icons/Done';
import './coverageOption.scss'
import BodilyInjury from '../icons/coverages/bodilyInjury'
import ComprehensiveCoverage from '../icons/coverages/comprehensive'
import CollisionCoverage from '../icons/coverages/collisionCoverage'
import UninsuredMotorist from '../icons/coverages/uninsuredMotoristLiability'
import RoadsideAssistance from '../icons/coverages/roadsideAssistance'
import PhysicalDamage from '../icons/coverages/physicalDamage'
import themes from '../../themes'
import agencyContext from '../../context/agency-context';

const CoverageOption = ({
    selected = false,
    amount,
    coverageTitle = '',
    coveragesList,
    onClick
}) => {
    const { agencyLogo } = useContext(agencyContext)
    const primaryMainColor = themes.main.mui.palette.primary.main
    const secondaryMainColor = themes.main.mui.palette.secondary.main
    const tertiaryMainColor = themes.main.mui.palette.tertiary?.main

    const handleClick = evt => {
        coverageTitle.toLowerCase()
        onClick(coverageTitle.toLowerCase())
    }

    const getCoverageIcon = (iconName, index) => {
        const sanitizedName = iconName.replace(/\s/g, '-').toLowerCase()
        const combinedMainColor = get(themes, 'main.mui.palette.combined.main')
        switch (sanitizedName) {
            case 'bodily-injury-liability':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><BodilyInjury color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>
                )
            case 'comprehensive-coverage':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><ComprehensiveCoverage color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            case 'collision-coverage':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><CollisionCoverage color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            case 'uninsured-motorist-liability':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><UninsuredMotorist color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            case 'uninsured-motorist-property-damage':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><UninsuredMotorist color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            case 'nsd-roadside-assistance':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><RoadsideAssistance color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            case 'property-damage-single-limit':
                return (
                    <div className='coverage-options-description' key={index}>
                        <div className="coverage-option-icon"><PhysicalDamage color={selected ? combinedMainColor : '#C4C4C4'} /></div>
                        <div>{iconName}</div>
                    </div>)
            default:
                return null;
        }
    }

    const coverages = coveragesList && coveragesList.map((coverage, idx) => coverage.ChangeInWrittenPremium !== "0" && getCoverageIcon(coverage.CoverageDesc, idx))

    return (
        <div className='coverage-option' onClick={handleClick}>
            <div
                className="coverage-title"
                style={selected ? { '--title-color': primaryMainColor } : {}}>
                {coverageTitle}
            </div>
            <div
                id={coverageTitle}
                className={selected ? 'coverage-option-body-selected' : 'coverage-option-body'}
                style={selected ? { '--primary-color': primaryMainColor, '--secondary-color': secondaryMainColor, '--tertiary-color': tertiaryMainColor } : {}}
            >
                <img className={'pet-icon'} alt="Agency Logo" style={{ opacity: selected ? '1' : '0', visibility: selected ? 'visible' : 'hidden' }} src={agencyLogo} />
                <div className={selected ? "selected" : 'hidden'}>
                    <DoneIcon className="icon-style" style={{ '--icon-color': primaryMainColor }} />
                </div>
                <div className='coverage-option-pricing' >
                    <div className="as-low">As low as</div>
                    <div >
                        <span className='coverage-option-price'>
                            {`$${amount}`}
                        </span>
                        <span className='coverage-option-monthly'>
                            /mo
                        </span>
                    </div>
                </div>
                <div className='coverage-options-list'>
                    <div className='coverage-options-list-title'>
                        Included Coverage
                    </div>
                    {coverages}
                </div>
            </div>
        </div >
    )
}

CoverageOption.propTypes = {
    amount: Proptypes.string,
    coverageTitle: Proptypes.string,
    coveragesList: Proptypes.any
}

export default CoverageOption