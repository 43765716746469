import React from 'react'
import './form.scss'
import { withRouter } from 'react-router-dom'
import AgencyContext from '../../context/agency-context'
import BasicInput from '../../components/basicInput/basicInput'
import validator from 'validator'
import creditCardType from 'credit-card-type'
import MasterCard from '../../assets/cards/mastercard.png'
import AmericanExpress from '../../assets/cards/american-express.png'
import Visa from '../../assets/cards/visa.png'
import Maestro from '../../assets/cards/maestro.png'
import JCB from '../../assets/cards/jcb.png'
import Discover from '../../assets/cards/discover.png'
import UnionPay from '../../assets/cards/unionpay.png'
import DefaultCard from '../../assets/cards/default-card.png'
import Button from '../../components/button/button'
import themes from '../../themes'
import publicKeys from '../../helpers/publicKeys'
import * as openpgp from 'openpgp'
class Payment extends React.Component {
    state = {
        isError: [false, false, false],
        paymentPlan: '',
        cardNumber: '',
        expirationDate: '',
        titleCCV: 'CCV',
        cvv: '',
        lengthCCV: 3,
        isValidCard: false,
        showIconCorrect: false,
        typeCard: 'default',
        iconCard: DefaultCard,
        objectPayment: {
            cardNumber: '',
            expirationDate: '',
            cvv: '',
        }
    }

    static contextType = AgencyContext

    validateCreditCard = (value) => {
        const typeCard = this.getTypeCard(value)
        let iconCard = DefaultCard
        switch (typeCard) {
            case 'default':
                iconCard = DefaultCard
                break
            case creditCardType.types.VISA:
                iconCard = Visa
                break
            case creditCardType.types.MASTERCARD:
                iconCard = MasterCard
                break
            case creditCardType.types.AMERICAN_EXPRESS:
                iconCard = AmericanExpress
                break
            case creditCardType.types.DISCOVER:
                iconCard = Discover
                break
            case creditCardType.types.MAESTRO:
                iconCard = Maestro
                break
            case creditCardType.types.JCB:
                iconCard = JCB
                break
            case creditCardType.types.UNIONPAY:
                iconCard = UnionPay
                break
            default:
                iconCard = DefaultCard
                break
        }
        let errors = this.state.isError
        errors[0] = !validator.isCreditCard(value) && value !== ''
        this.setState({
            showIconCorrect: value !== '',
            cardNumber: value,
            isValidCard: validator.isCreditCard(value),
            typeCard: typeCard,
            iconCard: iconCard,
            isError: errors,
        })
    }

    validateExpirationDate = (value) => {
        let errors = this.state.isError
        errors[2] = false
        if (value.includes('/')) {
            const infoDate = value.split('/')
            const month = infoDate[0]
            const year = infoDate[1]
            const date = new Date(`${month}/01/20${year}`)
            errors[2] = date < Date.now() || parseInt(month) < 1 || parseInt(month) > 12
        } else {
            errors[2] = true
        }

        this.setState({
            expirationDate: value,
            isError: errors,
        })
    }

    validateCCV = (value) => {
        let errors = this.state.isError
        errors[1] = value === '' || value === undefined
        this.setState({
            cvv: value,
            isError: errors,
        })
    }

    getTypeCard = (value) => {
        try {
            const visaCards = creditCardType(value)
            this.setState({ lengthCCV: visaCards[0].code.size, titleCCV: visaCards[0].code.name })
            return visaCards[0].type
        } catch (error) {
            this.setState({ lengthCCV: 3, titleCCV: 'CCV' })
            return 'default'
        }
    }

    paymentEvent = () => {
        this.props.handlerProccessPayment(true)
        let errors = this.state.isError
        if (this.state.cardNumber === '' || !this.state.isValidCard) {
            errors[0] = true
        }

        if (this.state.cvv === '' || this.state.cvv === undefined) {
            errors[1] = true
        }

        if (this.state.expirationDate === '' && !this.state.expirationDate.includes('/')) {
            errors[2] = true
        }
        this.setState({ isError: errors })
        const dateVar = new Date()

        const decadeVal = dateVar.getFullYear().toString().substring(0, 2)
        if (!errors.some((error) => error)) {
            const infoExpDate = this.state.expirationDate.split('/')
            const cardExpirationDate = `${infoExpDate[0]}${decadeVal}${infoExpDate[1]}`
            this.generateKeyAndEncrypt(this.state.cvv, 'cvv')
                .then((result) => this.generateKeyAndEncrypt(cardExpirationDate, 'expirationDt'))
                .then((result) => this.generateKeyAndEncrypt(this.state.cardNumber, 'cardNumber'))
                .then((result) => {
                    const paymentObject = {
                        cardNumber: this.state.objectPayment.cardNumber,
                        expirationDate: this.state.objectPayment.expirationDate,
                        cvv: this.state.objectPayment.cvv,
                    }
                    this.props.successPayment(paymentObject)
                })
        } else {
            this.props.handlerProccessPayment(false)
            this.props.successPayment(null)
        }
    }

    toBase64 = (u8) => {
        return btoa(String.fromCharCode.apply(null, u8))
    }

    generateKeyAndEncrypt = async (item, type) => {
        const pubKeys = await openpgp.key.readArmored(publicKeys)

        item = item.replace(/\s/g, '') // remove any spaces in the items

        await openpgp
            .encrypt({
                message: openpgp.message.fromText(item),
                publicKeys: pubKeys.keys,
                armor: false,
            })
            .then((ciphertext) => {
                const encrypted = ciphertext.message.packets.write()
                const buffer = this.toBase64(encrypted)
                if (type === 'cardNumber') {
                    this.setState({
                        objectPayment: {
                            cardNumber: buffer,
                            expirationDate: this.state.objectPayment.expirationDate,
                            cvv: this.state.objectPayment.cvv,
                        },
                    })
                }
                if (type === 'expirationDt') {
                    this.setState({
                        objectPayment: {
                            cardNumber: this.state.objectPayment.cardNumber,
                            expirationDate: buffer,
                            cvv: this.state.objectPayment.cvv,
                        },
                    })
                }
                if (type === 'cvv') {
                    this.setState({
                        objectPayment: {
                            cardNumber: this.state.objectPayment.cardNumber,
                            expirationDate: this.state.objectPayment.expirationDate,
                            cvv: buffer,
                        },
                    })
                }
            })
    }

    render() {
        return (
            <div className="payment-content">
                <BasicInput
                    showIconCorrect={this.state.showIconCorrect}
                    isCorrect={this.state.isValidCard}
                    valueFront={this.state.cardNumber}
                    showImageInputRigth={this.state.cardNumber !== ''}
                    pathImageInputRigth={this.state.iconCard}
                    label={'Credit Card Number'}
                    defaultValue={this.state.cardNumber}
                    onChange={(val) => {
                        this.validateCreditCard(val)
                    }}
                    isError={this.state.isError[0]}
                    placeholder="1111 2222 3333 4444"
                    mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ' ',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ' ',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ' ',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                    ]}
                    errorMessage={'Please enter a valid card number'}></BasicInput>
                <div className="payment-content-info-card">
                    <div className="payment-content-info-card-expiration-date">
                        <BasicInput
                            type={'password'}
                            label={this.state.titleCCV}
                            defaultValue={this.state.cvv}
                            onChange={(val) => {
                                this.validateCCV(val)
                            }}
                            isError={this.state.isError[1]}
                            placeholder={this.state.lengthCCV === 3 ? '123' : '1234'}
                            mask={
                                this.state.lengthCCV === 3
                                    ? [/[0-9]/, /[0-9]/, /[0-9]/]
                                    : [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
                            }
                            errorMessage={'Please enter a ' + this.state.titleCCV}></BasicInput>
                    </div>
                    <div className="payment-content-info-card-cvv">
                        <BasicInput
                            valueFront={this.state.expirationDate}
                            label={'Expiration Date'}
                            defaultValue={this.state.expirationDate}
                            onChange={(val) => {
                                this.validateExpirationDate(val)
                            }}
                            isError={this.state.isError[2]}
                            placeholder="MM/YY"
                            mask={[/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
                            errorMessage={'Please enter a valid expiration date'}></BasicInput>
                    </div>
                </div>
                <div className="buttons-row">
                    {this.context.isEditable &&
                        <Button
                            align="center"
                            buttonText="Back"
                            onClick={() => {
                                this.props.history.push(`/${this.context.agencyName}/${this.props.backUrl}`)
                            }}
                            width="120px"
                        />
                    }
                    <Button
                        align="center"
                        buttonText={this.props.proccessPayment ? 'Processing Payment' : 'Pay'}
                        onClick={() => {
                            this.paymentEvent()
                        }}
                        width="200px"
                        disabled={this.props.proccessPayment}
                        color="#ffffff"
                        errorMessage={this.props.errorInPayment}
                        textColor={this.props.proccessPayment ? 'white' : themes.main.mui.palette.primary.main}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(Payment)
