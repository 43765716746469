import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as UninsuredMotoristIcon } from '../../../../assets/coverages/uninsured-motorist-liability.svg'

const UninsuredMotorist = ({ color = '#7F1819' }) => {
  return (
    <UninsuredMotoristIcon fill={color} />
  )
}

UninsuredMotorist.propTypes = {
  color: PropTypes.string
}

export default UninsuredMotorist