import { FormLabel, RadioGroup, FormHelperText, FormControlLabel, Radio } from '@material-ui/core'
import './radioInput.scss'
import themes from '../../themes'
import React, { useState, useEffect } from 'react'

const RadioInput = (props) => {
    const { isError, label, errorMessage, defaultValue, onChange, inLine, valueFront } = props

    const [value, setValue] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])

    useEffect(() => {
        setValue(valueFront)
    }, [valueFront])

    const getError = () => {
        if (isError) {
            return (
                <div className="helper">
                    <div className="sub-helper">
                        <FormHelperText error>{errorMessage}</FormHelperText>
                    </div>
                </div>
            )
        } else {
            return <div></div>
        }
    }
    const getRadioType = () => {
        if (inLine) {
            return (
                <div className="input-radio-in-line">
                    <div
                        className={
                            (valueFront ? valueFront : value) === 'Yes'
                                ? 'radio-inline-container radio-inline-container-yes'
                                : 'radio-inline-container-disabled radio-inline-container-yes'
                        }
                        onClick={() => {
                            onChange('Yes')
                            setValue('Yes')
                        }}>
                        {' '}
                        <div
                            className="radio-inline-div-circle"
                            style={{
                                border:
                                    (valueFront ? valueFront : value) === 'Yes' ? '1px solid rgb(224, 224, 222)' : '',
                            }}>
                            <div
                                className="radio-inline-div-circle-content"
                                style={{
                                    backgroundColor:
                                        (valueFront ? valueFront : value) === 'Yes'
                                            ? themes.main.mui.palette.primary.main
                                            : 'white',
                                }}></div>
                        </div>
                        <label
                            style={{
                                fontSize: props.yesText ? '14px' : '18px',
                                color: themes.main.mui.palette.primary.main,
                            }}>
                            {props.yesText ? props.yesText : 'Yes'}
                        </label>
                    </div>
                    <div
                        className={
                            (valueFront ? valueFront : value) === 'No'
                                ? 'radio-inline-container radio-inline-container-no'
                                : 'radio-inline-container-disabled radio-inline-container-no'
                        }
                        onClick={() => {
                            onChange('No')
                            setValue('No')
                        }}>
                        <div
                            className="radio-inline-div-circle"
                            style={{
                                border:
                                    (valueFront ? valueFront : value) === 'No' ? '1px solid rgb(224, 224, 222)' : '',
                            }}>
                            <div
                                className="radio-inline-div-circle-content"
                                style={{
                                    backgroundColor:
                                        (valueFront ? valueFront : value) === 'No'
                                            ? themes.main.mui.palette.primary.main
                                            : 'white',
                                }}></div>
                        </div>
                        <label
                            style={{
                                fontSize: props.noText ? '14px' : '18px',
                                color: themes.main.mui.palette.primary.main,
                            }}>
                            {props.noText ? props.noText : 'No'}
                        </label>
                    </div>
                </div>
            )
        } else {
            return (
                <RadioGroup
                    className="radio-input-group"
                    name={label}
                    value={valueFront ? valueFront : value}
                    onChange={(e) => {
                        onChange(e.target.value)
                        setValue(e.target.value)
                    }}>
                    <FormControlLabel
                        value={props.yesText ? props.yesText : 'Yes'}
                        control={<Radio color="primary" />}
                        label="Yes"
                    />
                    <FormControlLabel
                        value={props.noText ? props.noText : 'No'}
                        control={<Radio color="primary" />}
                        label="No"
                    />
                </RadioGroup>
            )
        }
    }
    return (
        <div className="radio-input">
            <div className={inLine ? 'input-label input-radio-label-in-line' : 'input-label'}>
                <FormLabel>{label}</FormLabel>
                {props.children && props.children}
            </div>
            {getRadioType()}
            {getError()}
        </div>
    )
}

export default RadioInput