import React from 'react'

import './VehicleInfo.scss'
const VehicleInfo = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 617.5 353.8"
            xmlSpace="preserve">
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="132.4" width="485.1" height="353.8" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="vi-st0"
                        style={{ fill: props.tertiary }}
                        d="M573.1,154.6c-3-50.7-15.5-86.5-38.6-111.4C493.8-0.6,427.9-0.3,351.7,0.1c-7.4,0-15,0.1-22.7,0.1
			c-41.7,0-75.9,14.4-101.7,42.8c-30.6,33.7-41.9,79.5-46.1,110.2c-28,7.6-48.9,35.2-48.9,67.8v0c0,27.7,15.1,51.8,36.9,63.1
			c4.1,39.1,34.9,69.7,72.1,69.7c36.9,0,67.6-30.2,72-68.9c0.3,2,0.5,3.9,0.7,6h108.6c0.1-1.3,0.2-2.5,0.4-3.8
			c5.3,37.7,35.5,66.7,71.7,66.7h0c35.5,0,65.2-27.9,71.4-64.4c29.2-6.7,51.3-34.9,51.3-68.4v0C617.5,190.1,598.8,163.8,573.1,154.6
			"
                    />
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" width="485.1" height="353.8" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="vi-st1"
                        style={{ fill: props.primary }}
                        d="M440.7,154.6c-3-50.7-15.5-86.5-38.6-111.4C361.4-0.6,295.6-0.3,219.3,0.1c-7.4,0-15,0.1-22.7,0.1
			C155,0.1,120.7,14.5,94.9,43C64.3,76.7,53,122.5,48.9,153.2C20.9,160.8,0,188.4,0,220.9v0c0,27.7,15.1,51.8,36.9,63.1
			c4.1,39.1,34.9,69.7,72.1,69.7c36.9,0,67.6-30.2,72-68.9c0.3,2,0.5,3.9,0.7,6h108.6c0.1-1.3,0.2-2.5,0.4-3.8
			c5.3,37.7,35.5,66.7,71.7,66.7h0c35.5,0,65.2-27.9,71.4-64.4c29.2-6.7,51.3-34.9,51.3-68.4v0C485.1,190.1,466.4,163.8,440.7,154.6
			"
                    />
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_5_" x="38.3" width="485.1" height="353.8" />
                    </defs>
                    <clipPath id="SVGID_6_">
                        <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="vi-st2"
                        style={{ fill: props.primary }}
                        d="M478.9,154.6c-3-50.7-15.5-86.5-38.6-111.4C399.7-0.6,333.8-0.3,257.5,0.1c-7.4,0-15,0.1-22.7,0.1
			C193.2,0.1,159,14.5,133.2,43c-30.6,33.7-41.9,79.5-46.1,110.2c-28,7.6-48.9,35.2-48.9,67.8v0c0,27.7,15.1,51.8,36.9,63.1
			c4.1,39.1,34.9,69.7,72.1,69.7h0c36.9,0,67.6-30.2,72-68.9c0.3,2,0.5,3.9,0.7,6h108.6c0.1-1.3,0.2-2.5,0.4-3.8
			c5.3,37.7,35.5,66.7,71.7,66.7c35.5,0,65.2-27.9,71.4-64.4c29.2-6.7,51.3-34.9,51.3-68.4v0C523.4,190.1,504.7,163.8,478.9,154.6"
                    />
                </g>
            </g>
            <path
                className="vi-st3"
                style={{ fill: props.secondary }}
                d="M477.1,154.6c-3-50.7-15.5-86.5-38.6-111.4C397.8-0.6,332-0.3,255.7,0.1c-7.4,0-15,0.1-22.7,0.1
	c-41.7,0-75.9,14.4-101.7,42.8c-30.6,33.7-41.9,79.5-46.1,110.2c-28,7.6-48.9,35.2-48.9,67.8v0c0,27.7,15.1,51.8,36.9,63.1
	c4.1,39.1,34.9,69.7,72.1,69.7c36.9,0,67.6-30.2,72-68.9c0.3,2,0.5,3.9,0.7,6h108.6c0.1-1.3,0.2-2.5,0.4-3.8
	c5.3,37.7,35.5,66.7,71.7,66.7c35.5,0,65.2-27.9,71.4-64.4c29.2-6.7,51.3-34.9,51.3-68.4v0C521.5,190.1,502.8,163.8,477.1,154.6"
            />
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_7_" x="84.4" width="485.1" height="353.8" />
                    </defs>
                    <clipPath id="SVGID_8_">
                        <use xlinkHref="#SVGID_7_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="vi-st4"
                        style={{ fill: props.tertiary }}
                        d="M525.1,154.6c-3-50.7-15.5-86.5-38.6-111.4C445.9-0.6,380-0.3,303.7,0.1c-7.4,0-15,0.1-22.7,0.1
			c-41.7,0-75.9,14.4-101.7,42.8c-30.6,33.7-41.9,79.5-46.1,110.2c-28,7.6-48.9,35.2-48.9,67.8v0c0,27.7,15.1,51.8,36.9,63.1
			c4.1,39.1,34.9,69.7,72.1,69.7c36.9,0,67.6-30.2,72-68.9c0.3,2,0.5,3.9,0.7,6h108.6c0.1-1.3,0.2-2.5,0.4-3.8
			c5.3,37.7,35.5,66.7,71.7,66.7h0c35.5,0,65.2-27.9,71.4-64.4c29.2-6.7,51.3-34.9,51.3-68.4v0C569.6,190.1,550.9,163.8,525.1,154.6
			"
                    />
                </g>
            </g>
            <path
                className="vi-st5"
                style={{ fill: props.combined }}
                d="M470.3,289.3c29.2-6.7,51.3-34.9,51.3-68.4v0c0-30.8-18.7-57.2-44.5-66.4c-3-50.7-15.5-86.5-38.6-111.4
	c-33.6-36.2-84.6-42.2-144.4-43c-4.3,0-8.6,0-13,0c-41.7,0-75.9,14.4-101.7,42.8c-30.6,33.7-41.9,79.5-46.1,110.2
	c-28,7.6-48.9,35.2-48.9,67.8v0c0,27.7,15.1,51.8,36.9,63.1c3.2,30.2,22.3,55.4,48.1,65.3c25.5-9.8,44.6-34.6,48-64.4
	c0.3,2,0.5,3.9,0.7,6h46.4c0.4-2,0.7-3.9,0.9-6c0.3,2,0.5,3.9,0.7,6h60.6c0.1-1.3,0.2-2.5,0.4-3.8c0.2,1.3,0.4,2.5,0.6,3.8h47
	c0.1-1.3,0.2-2.5,0.4-3.8c4.1,28.9,22.8,52.7,47.7,62.3C447.1,340,465.6,317.2,470.3,289.3z"
            />
            <path
                className="vi-st6"
                d="M551.3,159.1c-3-46.8-15.5-79.9-38.6-102.9C472.1,15.7,406.2,16,329.9,16.4c-7.4,0-15,0.1-22.7,0.1
	c-41.7,0-75.9,13.3-101.7,39.6c-30.6,31.1-41.9,73.5-46.1,101.8c-28,7.1-48.9,32.5-48.9,62.6v0c0,27.6,17.6,51.3,42.1,60.5
	c4.8-34.1,34.2-60.5,69.6-60.5h0c36.7,0,67.1,28.6,70,64.6H401c2.9-36,33.3-64.6,70-64.6h0c36.5,0,66.7,28.2,70,63.8
	c30.9-4.8,54.8-31.7,54.8-63.8v0C595.8,191.9,577.1,167.6,551.3,159.1 M334.5,155.8H200.7c4.1-22,13.2-51,33.7-71.8
	c18.2-18.4,42-27.4,72.9-27.4c7.8,0,15.4,0,22.8-0.1c1.5,0,2.9,0,4.4,0V155.8z M374.7,155.8V56.7c48.9,0.9,87.3,5.7,109.6,28
	c14.8,14.7,23.5,38.1,26.4,71.2H374.7z"
            />
            <path
                className="vi-st6"
                d="M222.7,232.1L222.7,232.1c-29.1,0-53,23.8-53,53c0,29.1,23.8,53,53,53s53-23.8,53-53
	C275.7,255.9,251.8,232.1,222.7,232.1"
            />
            <path
                className="vi-st6"
                d="M471.4,232.1L471.4,232.1c-29.1,0-53,23.8-53,53c0,29.1,23.8,53,53,53s53-23.8,53-53
	C524.4,255.9,500.5,232.1,471.4,232.1"
            />
            <rect className="vi-st7" width="617.5" height="353.8" />
            <rect className="vi-st7" width="617.5" height="353.8" />
        </svg>
    )
}
export default VehicleInfo
