import { createTheme } from '@material-ui/core'
import { grey, green, red, blue } from '@material-ui/core/colors'

const themes = {
    main: {
        mui: createTheme({
            typography: {
                useNextVariants: true,
            },
            palette: {
                primary: {
                   main: "#00A3ff",
                },
                secondary: {
                    main: "#0052B5"
                }
            },
            overrides: {
                MuiFormLabel: {
                    asterisk: {
                        color: '#db3131',
                    },
                },
               
            },
        }),
        logo: null,
        headerColor: "#00A3ff",
        footerColor: "#0052B5",
        headerTextColor: "#FFFFFF",
        backgroundColor: "#EEEEEE",
        formBakgroundColor: "#FFFFFF"
    },

    successTheme: createTheme({
        typography: {
            useNextVariants: true,
        },
        palette: {
            primary: green,
            secondary: blue,
        },
    }),

    errorTheme: createTheme({
        typography: {
            useNextVariants: true,
        },
        palette: {
            primary: red,
            secondary: grey,
        },
    })
}

export default themes
