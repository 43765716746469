import React from 'react'

import './selected.scss'
const Selected = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 492.4 412.1"
            xmlSpace="preserve">
            <path
                className="di-st0"
                style={{ fill: props.secondary }}
                d="M492.4,23.2v257.3c0,12.8-10.4,23.2-23.2,23.2h-324c-12.8,0-23.2-10.4-23.2-23.2V23.2C122,10.4,132.4,0,145.2,0
       h324C482,0,492.4,10.4,492.4,23.2"
            />
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" y="33.9" width="370.5" height="303.7" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="di-st1"
                        style={{ fill: props.primary }}
                        d="M370.5,57.1v257.3c0,12.8-10.4,23.2-23.2,23.2h-324C10.4,337.6,0,327.2,0,314.4V57.1
               c0-12.8,10.4-23.2,23.2-23.2h324C360,33.9,370.5,44.4,370.5,57.1"
                    />
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" x="86.9" y="108.4" width="370.5" height="303.7" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="di-st2"
                        style={{ fill: props.tertiary }}
                        d="M457.4,131.6v257.3c0,12.8-10.4,23.2-23.2,23.2h-324c-12.8,0-23.2-10.4-23.2-23.2V131.6
               c0-12.8,10.4-23.2,23.2-23.2h324C446.9,108.4,457.4,118.9,457.4,131.6"
                    />
                </g>
            </g>
            <path
                className="di-st3"
                style={{ fill: props.combined }}
                d="M434.2,108.4H122v172.1c0,12.8,10.4,23.2,23.2,23.2h312.2V131.6C457.4,118.9,446.9,108.4,434.2,108.4z"
            />
            <path
                className="di-st4"
                d="M429.3,89.9v257.3c0,12.8-10.4,23.2-23.2,23.2h-324c-12.8,0-23.2-10.4-23.2-23.2V89.9
       c0-12.8,10.4-23.2,23.2-23.2h324C418.9,66.7,429.3,77.2,429.3,89.9"
            />
            <line
                className="st5"
                x1="257.5"
                y1="176.9"
                x2="392"
                y2="176.9"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
            />
            <line
                className="st5"
                x1="257.5"
                y1="228.4"
                x2="392"
                y2="228.4"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
            />
            <line
                className="st5"
                x1="257.5"
                y1="276.2"
                x2="392"
                y2="276.2"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
            />
            <path
                className="di-st6"
                style={{ fill: props.combined }}
                d="M196.9,189.1c0,21.7-17.6,39.3-39.3,39.3s-39.3-17.6-39.3-39.3s17.6-39.3,39.3-39.3S196.9,167.4,196.9,189.1"
            />
            <path
                className="di-st6"
                style={{ fill: props.combined }}
                d="M92.9,281c16.7,15.7,39.1,25.3,63.8,25.3c25.9,0,49.3-10.5,66.2-27.6c-9.9-26-35.1-44.4-64.6-44.4
       C128,234.2,102.3,253.8,92.9,281"
            />
            <rect className="di-st7" width="492.4" height="412.1" />
            <rect className="di-st7" width="492.4" height="412.1" />
        </svg>
    )
}
export default Selected
