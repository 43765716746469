import React, { Component } from 'react'
import QuotingForm from '../../views/form/quotingForm'
import ProgressBar from '../../components/progress/bar'
import './main.scss'
import PropTypes from 'prop-types'
import SVGFooter from '../SVGFooter/SVGfooter'
import agencyContext from '../../context/agency-context'
class Main extends Component {
    static contextType = agencyContext;
    static propTypes = {
        location: PropTypes.object,
        fetchAgencyInfo: PropTypes.object,
    }

    state = {
        currentPath: 'start',
        pathsNotPadding: ['drivers/license', 'drivers/name', 'vin', 'address', 'name', 'start', 'pay', 'drivers', 'vehicles', 'review', 'policy/name', 'policy/home', 'sign', 'coverages', 'leasing'],
    }

    componentDidMount() {
        this.setState({ currentPath: this.getPage() })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ currentPath: this.getPage() })
        }
    }

    getPage = () => {
        let pathname =
            window.location.pathname[0] === '/' ? window.location.pathname.substr(1) : window.location.pathname
        let pathArray = pathname.split('/')
        pathArray.shift()
        pathArray = pathArray.filter((x) => isNaN(x))
        return pathArray.join('/')
    }

    renderProgressBar = () => {
        // TODO: remove && when changes for purchase page get merged
        if (this.getPage() !== 'start' && this.getPage() !== 'success') {
            return <ProgressBar currentPath={this.state.currentPath}></ProgressBar>
        }
    }

    render() {
        return (
            <main
                style={
                    this.state.pathsNotPadding.includes(this.state.currentPath)
                        ? { paddingLeft: '0px', paddingRight: '0px' }
                        : { paddingLeft: '5rem', paddingRight: '5rem' }
                }>
                {this.context.isEditable &&
                    this.renderProgressBar()
                }
                <QuotingForm fetchAgencyInfo={this.props.fetchAgencyInfo}></QuotingForm>
                <SVGFooter />
            </main>
        )
    }
}

export default Main
