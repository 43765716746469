import React from 'react'

const UnSelected = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 412.1 485.5"
            xmlSpace="preserve">
            <path
                className="qi-st0"
                style={{
                    fill: 'none',
                    stroke: props.primary,
                    strokeWidth: 18,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                d="M62,58.3h257.3c12.8,0,23.2,10.4,23.2,23.2v324c0,12.8-10.5,23.2-23.2,23.2H62c-12.8,0-23.2-10.4-23.2-23.2
	v-324C38.8,68.8,49.2,58.3,62,58.3"
            />
            <g>
                <path
                    className="qi-st1"
                    style={{ fill: props.primary }}
                    d="M386.1,33.2H128.8c-13,0-23.8,9.7-25.5,22.3c1.8-0.5,3.5-0.9,5.3-1.1c2.1-9.3,10.3-16.2,20.2-16.2h257.3
		c11.4,0,20.7,9.3,20.7,20.7v324c0,11.4-9.3,20.7-20.7,20.7h-36.6c-0.3,1.7-0.7,3.3-1.4,5h38c14.2,0,25.7-11.5,25.7-25.7v-324
		C411.8,44.7,400.3,33.2,386.1,33.2z"
                />
            </g>
            <g>
                <path
                    className="qi-st1"
                    style={{ fill: props.primary }}
                    d="M26,5h257.3c11.4,0,20.7,9.3,20.7,20.7v27.7c1.1-0.2,2.2-0.3,3.3-0.3c0.6,0,1.1,0,1.7,0.1V25.7
		C309,11.5,297.5,0,283.3,0H26C11.8,0,0.3,11.5,0.3,25.7v324c0,14.2,11.5,25.7,25.7,25.7h9.5c0-0.3-0.1-0.6-0.1-1
		c-0.1-1.4,0.1-2.7,0.3-4H26c-11.4,0-20.7-9.3-20.7-20.7v-324C5.3,14.3,14.6,5,26,5z"
                />
            </g>
            <g>
                <path
                    className="qi-st1"
                    style={{ fill: props.primary }}
                    d="M335,420.6v39.1c0,11.4-9.3,20.7-20.7,20.7H57c-11.4,0-20.7-9.3-20.7-20.7v-78.8c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.3-1.9-0.5-3.9-0.8-5.8c-0.1-2.2,0.2-4.3,1-6.3V190c-0.2-3.7-0.3-7.3-0.3-11c0-4.2,0.1-8.3,0.3-12.4v-30.8
		c0-2.8,0.6-5.4,1.5-7.8c0.1-3.3,0.3-6.7,0.6-10c-4.4,4.6-7.2,10.9-7.2,17.8v324c0,14.2,11.5,25.7,25.7,25.7h257.3
		c14.2,0,25.7-11.5,25.7-25.7v-41.5C338.5,419.2,336.8,420,335,420.6z"
                />
            </g>
            <path
                className="qi-st1"
                style={{ fill: props.primary }}
                d="M258.6,228.6c0-38.3-31-69.3-69.3-69.3c-38.3,0-69.3,31-69.3,69.3c0,25.3,13.6,47.5,33.9,59.6L134.2,343
	l22-13.8l8.8,25.6l20.4-57c1.3,0.1,2.6,0.1,3.9,0.1c2.6,0,5.1-0.2,7.7-0.4l20.5,57.3l8.8-25.6l22,13.8l-20.3-56.8
	C246.4,273.7,258.6,252.6,258.6,228.6z M189.2,250.2l-21,11c-0.5,0.3-1.1,0.4-1.6,0.4c-0.7,0-1.4-0.2-2.1-0.7
	c-1.1-0.8-1.6-2.1-1.4-3.4l4-23.3l-17-16.5c-1-0.9-1.3-2.3-0.9-3.6s1.5-2.2,2.8-2.4l23.4-3.4l10.5-21.2c0.6-1.2,1.8-2,3.1-2
	c1.3,0,2.5,0.8,3.1,2l10.5,21.2l23.4,3.4c1.3,0.2,2.4,1.1,2.8,2.4c0.4,1.3,0.1,2.7-0.9,3.6l-17,16.5l4,23.3c0.2,1.3-0.3,2.6-1.4,3.4
	c-1.1,0.8-2.5,0.9-3.7,0.3L189.2,250.2z"
            />
            <rect className="qi-st2" width="412.1" height="485.5" style={{ fill: 'none' }} />
            <rect className="qi-st2" width="412.1" height="485.5" style={{ fill: 'none' }} />
        </svg>
    )
}
export default UnSelected
