import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './purchaseForm.scss'
import get from 'lodash/get'
import { diamondDate } from '../../../helpers/dateHelpers'
import RadioInput from '../../../components/radioInput/radioInput'
import PaymentForm from '../../../components/payments/form'
import LocationInput from '../../../components/locationInput/locationInput'
import { issueNew, receipt, notify } from '../../../helpers/endpoints'
import AgencyContext from '../../../context/agency-context'
import LoadingModal from '../../../components/modal/modal'
import ErrorModal from '../../../components/modal/error/errorModal'
import { registerPayment } from '../../../helpers/analytics'
class Payment extends Component {
    static propTypes = {}

    static contextType = AgencyContext
    state = {
        selectedPayment: 0,
        payments: [],
        paymentsList: [],
        isError: [false, false, false],
        paymentPlan: '',
        address: '',
        asBillinAddressSamePolicy: null,
        proccessPayment: false,
        errorInPayment: undefined,
    }

    componentDidMount() {
        const { getters, values } = this.props
        const payments = getters.policy.PaymentPlan()
        const date = diamondDate(new Date())

        const filteredPayments = getters.general.isEditable() ? (payments.length > 0 &&
            payments.filter((payment) => {
                if (payment.NumPayments >= '4') {
                    return payment.Description.split(' ').pop() === 'CC'
                } else {
                    return payment.NumPayments === '3' || payment.NumPayments === '0'
                }
            })) : payments

        const ordered =
            filteredPayments.length &&
            filteredPayments.sort((a, b) => Number(a.DepositAmt.Amt) - Number(b.DepositAmt.Amt))

        const paymentOptions = ordered.map((payment) => {
            const { NumPayments, InstallmentInfo, Description } = payment
            const multiplePayments = NumPayments >= '1'
            const installmentMsg = multiplePayments ? 'Installments' : 'Installment'
            const payOption = getters.general.isEditable() ?
                (NumPayments === '0'
                    ? Description
                    : `${multiplePayments ? NumPayments : 1} ${installmentMsg} of $${InstallmentInfo.InstallmentAmt.Amt
                    }${multiplePayments ? '/month' : ''}`)
                : Description
            return payOption
        })

        const description = values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.Description
        let selectedPaymentIndex = 0

        for (var i in filteredPayments) {
            if (filteredPayments[i].Description === description) {
                selectedPaymentIndex = i
            }
        }

        this.setState({ paymentsList: paymentOptions, effectiveDate: date, payments: filteredPayments, selectedPayment: selectedPaymentIndex })

        const paymentOptionSelected = filteredPayments.filter((x) => x.Description === description)[0]
        this.props.setters.policy.PaymentPlan(paymentOptionSelected.Description)

        registerPayment()

        window.scrollTo(0, 0)
    }

    handlerPayment = async (payment) => {
        let errors = this.state.isError
        errors[0] = this.state.selectedPayment === ''
        errors[1] = !this.state.asBillinAddressSamePolicy
        errors[2] = this.state.address === '' && this.state.asBillinAddressSamePolicy === 'No'
        this.setState({ isError: errors })
        if (!payment || errors.some((error) => error)) {
            this.setState({ proccessPayment: false })
            return
        }

        this.props.setters.CardPayment.AccountNumberId(payment.cardNumber)
        this.props.setters.CardPayment.CreditCardExpirationDt(payment.expirationDate)
        this.props.setters.CardPayment.CVV(payment.cvv)

        const auth = this.props.getters.general.Auth()

        try {

            const paymentResponse = await issueNew(this.props.values.quoteInfo.request, auth)
            this.props.setters.general.quoteInfo(paymentResponse)

            const policyNumber = this.props.getters.policy.PolicyNumber()

            receipt(auth, this.context, policyNumber)
            if (this.props.getters.general.isEditable()) {
                notify(auth, policyNumber)
            }

            this.props.history.push(`/${this.context.agencyName}/success`)

        }
        catch (error) {
            this.setState({ proccessPayment: false, errorInPayment: error.message })
        }
    }

    selectZip = (zip) => {
        this.setState({
            zipcode: zip,
        })
    }

    selectAddress = (address) => {
        this.setState({
            address: address,
        })
    }

    selectState = (state) => {
        this.setState({
            state: state,
        })
    }

    selectCity = (city) => {
        this.setState({
            city: city,
        })
    }

    render() {
        const { selectedPayment, paymentsList, payments, errorInPayment } = this.state
        const priceToDisplay = payments.length && get(payments, `${selectedPayment}.DepositAmt.Amt`)
        return (
            <div className="purchase-main">
                <div className="purchase-main-header">
                    <h2>
                        Your payment today is
                        <br />
                        <span className="purchase-main-header-amount">{`$${priceToDisplay}`}</span>
                    </h2>
                    <h2>
                        {paymentsList[selectedPayment]}
                        <br /><br />
                    </h2>
                </div>

                <div className="purchase-main-content">
                    <RadioInput
                        valueFront={this.state.asBillinAddressSamePolicy}
                        onChange={(val) => {
                            this.setState({
                                asBillinAddressSamePolicy: val,
                            })
                        }}
                        inLine={true}
                        label="Is your billing address the same as your policy address?"
                        defaultValue={this.state.asBillinAddressSamePolicy}
                        isError={this.state.isError[1]}
                        errorMessage={'Is your billing address the same as your policy address?'}></RadioInput>
                    <div
                        className={
                            this.state.asBillinAddressSamePolicy === 'No'
                                ? 'purchase-main-content-address'
                                : 'purchase-main-content-address--hidden'
                        }>
                        <LocationInput
                            valueFront={this.state.address}
                            selectCity={this.selectCity}
                            selectZip={this.selectZip}
                            selectState={this.selectState}
                            selectAddress={this.selectAddress}
                            label={'Address'}
                            errorMessage={this.state.isError[2] ? 'Please enter a valid address' : ''}></LocationInput>
                    </div>
                    <PaymentForm
                        handlerProccessPayment={(inProccess) => {
                            this.setState({ proccessPayment: inProccess })
                        }}
                        proccessPayment={this.state.proccessPayment}
                        backUrl={'review'}
                        successPayment={(payment) => {
                            this.handlerPayment(payment)
                        }}
                        errorInPayment={this.state.errorInPayment}
                    />
                </div>
                <LoadingModal fontSize="18px" isOpen={this.state.isLoading ? true : (this.state.proccessPayment ? true : false)} headline={this.state.isLoading ? 'Fetching your payment plan' : 'Processing Your Payment'}></LoadingModal>
                <ErrorModal errorText={`An error has occurred with issuing your policy. Please call us at: `} isOpen={errorInPayment !== undefined} setter={() => { this.setState({ errorInPayment: undefined }) }} headline="An Error Occurred">
                    <span className='error-child'>
                        <a className='error-link' href={`tel:${this.context.agencyInfo.phone_number}`}>{this.context.agencyInfo.phone_number}</a>
                        <div className='error-modal-text'> and use {this.props.getters.policy.PolicyNumber()} as your reference number. <br></br></div>
                    </span>
                    {this.state.errorInPayment !== 'Internal Server Error' && <div className='error-modal-status'>{this.state.errorInPayment}</div>}
                </ErrorModal>
            </div>
        )
    }
}
export default withRouter(Payment)
