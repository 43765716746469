import React from 'react'

import './BasicInfo.scss'
const BasicInfo = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 421.4 469.1"
            xmlSpace="preserve">
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="69.8" y="52.3" width="351.5" height="416.9" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="bi-st0"
                        style={{ fill: props.primary }}
                        d="M421.3,264.3V52.3c0,0-172.8,99.7-193.8,18.8C206.5,152,70,52.3,70,52.3v212.1
			c-5.2,163.8,175.7,204.8,175.7,204.8S426.5,428.2,421.3,264.3"
                    />
                </g>
            </g>
            <path
                className="bi-st1"
                style={{ fill: props.secondary }}
                d="M387.3,237.1V25c0,0-154.7,55.9-175.7-25C190.7,80.9,36,25,36,25v212.1c-5.2,163.8,175.7,204.8,175.7,204.8
	S392.6,400.9,387.3,237.1"
            />
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" y="27.3" width="351.5" height="441.9" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="bi-st2"
                        style={{ fill: props.tertiary }}
                        d="M351.4,264.3V52.3c0,0-154.7,55.9-175.7-25c-21,80.9-175.7,25-175.7,25v212.1
			c-5.2,163.8,175.7,204.8,175.7,204.8S356.7,428.2,351.4,264.3"
                    />
                </g>
            </g>
            <path
                className="bi-st3"
                d="M227.5,71.1c-14.8,57-87.1,24.2-128.4,0c-9.7,0-19.6-0.9-29.1-2.1v195.4C66.2,382,158.4,436.3,210.7,457.5
	C263,436.3,355.2,382,351.4,264.3V85.2C300.9,104.6,239.4,117.3,227.5,71.1z"
            />
            <path
                className="bi-st4"
                style={{ fill: props.combined }}
                d="M351.4,264.3V52.3c0,0-142.1,51.3-172.4-15.7c-2.6,1.1-5.2,2-7.9,2.8C149,81.6,79.3,72.9,36,62.6v174.4
	c-5.2,163.8,175.7,204.8,175.7,204.8s38.9-8.8,80.1-34.6C325.8,375.8,353.5,329.9,351.4,264.3z"
            />
            <path
                className="bi-st5"
                d="M299.4,251.5V142c0,0-79.9,28.9-90.7-12.9C197.9,170.8,118,142,118,142v109.5c-2.7,84.6,90.7,105.8,90.7,105.8
	S302.1,336.1,299.4,251.5"
            />
            <rect className="bi-st6" width="421.4" height="469.1" />
            <rect className="bi-st6" width="421.4" height="469.1" />
        </svg>
    )
}
export default BasicInfo
