import React from 'react'
import './PhysicalDamage.scss'
const PhysicalDamage = ({ primary, secondary, tertiary, combined, className }) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 815.39 636.78" className="physical-damage-svg" style={{ enableBackground: 'new 0 0 815.39 636.78' }} >

      <path className="rbi-st0" style={{ fill: combined, stroke: combined }} d="M341.32,453.87c-5.89-3.69-14.98-6.05-34.87,0c2.61-8.94,9.04-28-1.61-38.2" />
      <path className="rbi-st0" style={{ fill: combined, stroke: combined }} d="M301.83,361.42c-0.21-0.01-0.42-0.01-0.62-0.02" />
      <path className="rbi-st0" style={{ fill: combined, stroke: combined }} d="M396.47,290.87c0,0-5.23,47.04,48.42,30.57" />
      <path className="rbi-st0" style={{ fill: combined, stroke: combined }} d="M419.81,435.67c0,0-35.77-31-52.65,22.52" />
      <path style={{ fill: combined }} d="M589.54,251.79c-25.5,0-45.22,7.49-60.29,22.9c-16.98,17.37-24.54,41.6-27.91,60.03l110.72,0l0-83
    c-1.21,0.01-2.41,0.01-3.63,0.02C602.25,251.77,595.95,251.79,589.54,251.79z M600.48,323.14l-84.59,0
    c3.86-13.66,10.44-28.89,21.64-40.35c12.77-13.06,29.78-19.41,52.01-19.41c3.69,0,7.38-0.01,10.94-0.02V323.14z"/>
      <g>

        <ellipse transform="matrix(0.9239 -0.3827 0.3827 0.9239 -69.181 154.0643)" style={{ fill: primary }} cx="352.68" cy="250.93" rx="40.81" ry="40.81" />
        <path style={{ fill: primary }} d="M396.34,337.19c-47.94-35.4-48.02-35.46-48.05-35.49c-0.13-0.09-0.27-0.17-0.4-0.26
      c-0.33-0.23-0.67-0.46-1.02-0.67c-0.21-0.13-0.42-0.24-0.63-0.35c-0.32-0.18-0.64-0.35-0.97-0.51c-0.26-0.13-0.53-0.23-0.79-0.35
      c-0.29-0.12-0.57-0.24-0.87-0.35c-0.32-0.12-0.65-0.22-0.98-0.31c-0.25-0.08-0.5-0.15-0.76-0.22c-0.37-0.09-0.75-0.17-1.12-0.24
      c-0.18-0.03-0.35-0.08-0.52-0.11l-57.22-9.2l-13.93-37.51c-3.58-9.64-14.29-14.55-23.93-10.97c-9.64,3.58-14.55,14.29-10.97,23.93
      l17.71,47.68c2.33,6.29,7.87,10.83,14.49,11.9l34.48,5.54c-2.98,5.29-6.1,10.86-8.92,15.91l-42.21-17.16
      c-7.73-3.14-16.57-1.79-23.01,3.53l-45.29,37.42c-9.66,7.98-11.03,22.29-3.04,31.95c4.49,5.43,10.97,8.24,17.51,8.24
      c5.09,0,10.21-1.7,14.44-5.2l34.84-28.79l16.81,6.83l-62.49,63.23c-8.81,8.92-8.73,23.29,0.19,32.1
      c4.42,4.37,10.19,6.55,15.95,6.55c5.85,0,11.71-2.25,16.14-6.74l86.28-87.31c0.05-0.05,0.09-0.1,0.14-0.14
      c0.41-0.42,0.79-0.85,1.16-1.28c0.07-0.09,0.15-0.17,0.22-0.26c0.4-0.49,0.78-0.99,1.13-1.51c0.03-0.05,0.07-0.1,0.1-0.15
      c0.38-0.57,0.74-1.14,1.06-1.73c0-0.01,0.01-0.01,0.01-0.02c0.17-0.31,13.43-24.36,22.42-40.12c7.09,5.24,14.91,11.01,20.77,15.34
      l-4.86,30.54c-1.62,10.15,5.3,19.69,15.45,21.31c0.99,0.16,1.98,0.23,2.95,0.23c8.99,0,16.9-6.53,18.36-15.69l6.64-41.71
      C404.76,348.22,401.93,341.32,396.34,337.19z"/>
      </g>
      <g>

        <ellipse transform="matrix(0.9239 -0.3827 0.3827 0.9239 -66.0601 146.9576)" style={{ fill: secondary }} cx="336.37" cy="239.53" rx="40.81" ry="40.81" />
        <path style={{ fill: secondary }} d="M380.04,325.79c-47.94-35.4-48.02-35.46-48.05-35.49c-0.13-0.09-0.27-0.17-0.4-0.26
      c-0.33-0.23-0.67-0.46-1.02-0.67c-0.21-0.12-0.42-0.24-0.63-0.35c-0.32-0.18-0.64-0.35-0.97-0.51c-0.26-0.13-0.53-0.23-0.79-0.35
      c-0.29-0.12-0.57-0.24-0.87-0.35c-0.32-0.12-0.65-0.22-0.98-0.31c-0.25-0.08-0.5-0.15-0.76-0.22c-0.37-0.09-0.75-0.17-1.12-0.24
      c-0.18-0.03-0.35-0.08-0.52-0.11l-57.22-9.2l-13.93-37.51c-3.58-9.64-14.29-14.55-23.93-10.97c-9.64,3.58-14.55,14.29-10.97,23.93
      l17.71,47.68c2.33,6.29,7.87,10.83,14.49,11.9l34.48,5.54c-2.98,5.29-6.1,10.86-8.92,15.91l-42.21-17.16
      c-7.73-3.14-16.57-1.79-23.01,3.53L165.15,358c-9.66,7.98-11.03,22.29-3.04,31.95c4.49,5.43,10.97,8.24,17.51,8.24
      c5.09,0,10.21-1.7,14.44-5.2l34.84-28.79l16.81,6.83l-62.49,63.23c-8.81,8.92-8.73,23.29,0.19,32.1
      c4.42,4.37,10.19,6.55,15.95,6.55c5.85,0,11.71-2.25,16.15-6.74l86.28-87.31c0.05-0.05,0.09-0.1,0.14-0.14
      c0.41-0.42,0.79-0.85,1.16-1.28c0.07-0.09,0.15-0.17,0.22-0.26c0.4-0.49,0.78-0.99,1.13-1.51c0.03-0.05,0.07-0.1,0.1-0.15
      c0.38-0.57,0.74-1.14,1.06-1.73c0-0.01,0.01-0.01,0.01-0.02c0.17-0.31,13.43-24.36,22.42-40.12c7.09,5.24,14.91,11.01,20.77,15.34
      l-4.86,30.54c-1.62,10.15,5.3,19.69,15.45,21.31c0.99,0.16,1.98,0.23,2.95,0.23c8.99,0,16.9-6.53,18.36-15.69l6.64-41.71
      C388.45,336.82,385.63,329.92,380.04,325.79z"/>
      </g>
      <g>

        <ellipse transform="matrix(0.9239 -0.3827 0.3827 0.9239 -62.2371 129.9554)" style={{ fill: tertiary }} cx="295.55" cy="221.42" rx="40.81" ry="40.81" />
        <path style={{ fill: tertiary }} d="M339.21,307.68c-47.94-35.4-48.02-35.46-48.05-35.49c-0.13-0.09-0.27-0.17-0.4-0.26
      c-0.33-0.23-0.67-0.46-1.02-0.67c-0.21-0.12-0.42-0.24-0.63-0.35c-0.32-0.18-0.64-0.35-0.97-0.51c-0.26-0.13-0.53-0.23-0.79-0.35
      c-0.29-0.12-0.57-0.24-0.87-0.35c-0.32-0.12-0.65-0.22-0.98-0.31c-0.25-0.08-0.5-0.15-0.76-0.22c-0.37-0.09-0.75-0.17-1.12-0.24
      c-0.18-0.03-0.35-0.08-0.52-0.11l-57.22-9.2l-13.93-37.51c-3.58-9.64-14.29-14.55-23.93-10.97c-9.64,3.58-14.55,14.29-10.97,23.93
      l17.71,47.68c2.33,6.29,7.87,10.83,14.49,11.9l34.48,5.54c-2.98,5.29-6.1,10.86-8.92,15.91l-42.21-17.16
      c-7.73-3.14-16.57-1.79-23.01,3.53l-45.29,37.42c-9.66,7.98-11.03,22.29-3.04,31.95c4.49,5.43,10.97,8.24,17.51,8.24
      c5.09,0,10.21-1.7,14.44-5.2l34.84-28.79l16.81,6.83l-62.49,63.23c-8.81,8.92-8.73,23.29,0.19,32.1
      c4.42,4.37,10.19,6.55,15.95,6.55c5.85,0,11.71-2.25,16.15-6.74l86.28-87.31c0.05-0.05,0.09-0.1,0.14-0.14
      c0.41-0.42,0.79-0.85,1.16-1.28c0.07-0.09,0.15-0.17,0.22-0.26c0.4-0.49,0.78-0.99,1.13-1.51c0.03-0.05,0.07-0.1,0.1-0.15
      c0.38-0.57,0.74-1.14,1.06-1.73c0-0.01,0.01-0.01,0.01-0.02c0.17-0.31,13.43-24.36,22.42-40.12c7.09,5.24,14.91,11.01,20.77,15.34
      l-4.86,30.54c-1.62,10.15,5.3,19.69,15.45,21.31c0.99,0.16,1.98,0.23,2.95,0.23c8.99,0,16.9-6.53,18.36-15.69l6.64-41.71
      C347.63,318.71,344.8,311.81,339.21,307.68z"/>
      </g>
      <line className="rbi-st5" style={{ stroke: combined }} x1="333.65" y1="154.49" x2="358.12" y2="105.74" />
      <line className="rbi-st5" style={{ stroke: combined }} x1="262.23" y1="172.71" x2="207.89" y2="142.7" />
      <line className="rbi-st5" style={{ stroke: combined }} x1="290.74" y1="143.04" x2="285.32" y2="127.48" />
      <path style={{ fill: combined }} d="M627.54,442.72H577.2c-0.82-10.23-4.27-19.74-9.64-27.84c-9.58-16.72-27.49-28.01-47.98-28.01
    c-17.92,0-33.88,8.64-44.01,21.99c-7.28,8.41-12.24,18.91-13.84,30.46c-20.29-7.67-34.84-27.5-34.84-50.6v0
    c0-25.17,17.27-46.46,40.43-52.36c3.43-23.7,12.76-59.08,38.09-85.12c21.35-21.94,49.66-33.07,84.13-33.07
    c6.36,0,12.61-0.03,18.74-0.06c5.63-0.02,11.19-0.05,16.68-0.05c0.87,0,1.72,0,2.59,0v-11.58c-0.86,0-1.72,0-2.59,0
    c-5.47,0-10.88,0.02-16.61,0.05l-0.12,0c-5.7,0.03-12.16,0.06-18.69,0.06c-37.71,0-68.81,12.31-92.43,36.57
    c-24.81,25.5-35.52,58.74-40.1,84.37c-10.8,4.23-20.46,11.38-27.71,20.64c-9.03,11.52-14,25.92-14,40.55
    c0,13.57,4.12,26.62,11.91,37.73c7.61,10.86,18.13,19.05,30.41,23.7c1.33,0.5,2.71,0.75,4.1,0.75c1.02,0,2.03-0.13,3.01-0.4
    c3.77,27.12,26.92,48.07,54.84,48.07c26.78,0,49.17-19.26,54.28-44.76c1.06,0.32,2.18,0.49,3.34,0.49h50.34V442.72z M519.58,486.99
    L519.58,486.99c-24.09,0-43.8-19.92-43.8-44.27c0-10.03,3.35-19.29,8.96-26.74c8.6-9.66,21.01-15.68,34.55-15.68
    c15.73,0,29.88,8.14,38.34,20.56c3.66,6.46,5.76,13.92,5.76,21.85C563.38,467.07,543.67,486.99,519.58,486.99z"/>
      <rect x="646.8" y="206.49" style={{ fill: combined }} width="21.11" height="11.41" />
      <rect x="645.56" y="442.46" style={{ fill: combined }} width="21.11" height="11.41" />
      <g>
        <g>
          <circle className="rbi-st6" cx="317.51" cy="222.92" r="39.06" />
          <path style={{ fill: combined }} d="M317.52,187.86c19.36,0,35.06,15.69,35.06,35.06c0,19.36-15.69,35.06-35.06,35.06
        c-19.36,0-35.06-15.69-35.06-35.06C282.46,203.56,298.15,187.86,317.52,187.86 M317.52,179.86c-23.74,0-43.06,19.31-43.06,43.06
        c0,23.74,19.31,43.06,43.06,43.06c23.74,0,43.06-19.31,43.06-43.06C360.57,199.18,341.26,179.86,317.52,179.86L317.52,179.86z"/>
        </g>
        <g>
          <path className="rbi-st6" d="M199.82,427.41c-6.22,0-12.09-2.41-16.52-6.79c-4.46-4.41-6.94-10.3-6.98-16.57s2.37-12.19,6.78-16.66
        l49.47-50.05l-6.68-2.71l-28.09,23.21c-4.2,3.47-9.51,5.38-14.96,5.38c-7.04,0-13.65-3.11-18.13-8.53
        c-4-4.84-5.87-10.95-5.28-17.19c0.59-6.25,3.59-11.89,8.43-15.89l38.91-32.15c4.2-3.47,9.52-5.38,14.97-5.38
        c3.05,0,6.02,0.58,8.85,1.73l32.98,13.41c1.02-1.83,2.08-3.71,3.13-5.59l-23.96-3.85c-7.1-1.14-13.06-6.04-15.56-12.78
        l-15.21-40.96c-3.84-10.33,1.45-21.86,11.78-25.7c2.24-0.83,4.58-1.25,6.96-1.25c8.31,0,15.85,5.24,18.74,13.03l11.15,30.03
        l46.85,7.53c0.18,0.03,0.35,0.07,0.53,0.11c0.44,0.08,0.84,0.17,1.23,0.27c0.23,0.06,0.45,0.12,0.67,0.19l0.14,0.04
        c0.36,0.11,0.71,0.22,1.06,0.34c0.31,0.11,0.62,0.24,0.93,0.37c0.34,0.14,0.6,0.25,0.86,0.37c0.36,0.17,0.7,0.36,1.05,0.54
        l0.13,0.07c0.18,0.1,0.36,0.2,0.54,0.31c0.38,0.23,0.74,0.47,1.1,0.72c0.14,0.08,0.29,0.18,0.43,0.29
        c0.01,0,0.01,0.01,41.28,30.48c6.03,4.45,9.04,11.82,7.87,19.22l-5.71,35.83c-1.55,9.76-9.85,16.85-19.72,16.85
        c-1.05,0-2.12-0.08-3.16-0.25c-5.27-0.84-9.9-3.68-13.04-8c-3.13-4.32-4.4-9.61-3.56-14.88l3.79-23.83l-12.26-9.05
        c-7.38,13.05-16.58,29.72-17.01,30.5l-0.06,0.1c-0.32,0.58-0.68,1.16-1.06,1.72l-0.08,0.12c-0.41,0.6-0.79,1.11-1.19,1.59
        l-0.18,0.22c-0.46,0.54-0.85,0.97-1.25,1.38l-74.25,75.13C212.09,424.93,206.15,427.41,199.82,427.41z"/>
          <path style={{ fill: combined }} d="M230.72,213.09c6.49,0,12.6,3.98,14.99,10.43l11.97,32.22l49.15,7.9c0.15,0.02,0.3,0.07,0.45,0.1
        c0.32,0.06,0.64,0.12,0.96,0.21c0.22,0.06,0.43,0.12,0.65,0.19c0.28,0.08,0.56,0.17,0.84,0.27c0.25,0.09,0.5,0.2,0.74,0.3
        c0.23,0.1,0.46,0.19,0.68,0.3c0.28,0.14,0.56,0.28,0.83,0.43c0.18,0.1,0.36,0.19,0.54,0.3c0.3,0.18,0.59,0.38,0.88,0.58
        c0.11,0.08,0.23,0.14,0.34,0.22c0.03,0.02,0.1,0.07,41.28,30.49c4.8,3.55,7.23,9.48,6.29,15.38l-5.71,35.83
        c-1.25,7.87-8.05,13.48-15.77,13.48c-0.84,0-1.68-0.07-2.53-0.2c-8.72-1.39-14.66-9.58-13.28-18.3l4.18-26.23
        c-5.04-3.72-11.75-8.68-17.85-13.18c-7.72,13.54-19.11,34.2-19.26,34.46c0,0.01-0.01,0.01-0.01,0.02c-0.28,0.51-0.58,1-0.91,1.49
        c-0.03,0.04-0.06,0.08-0.09,0.13c-0.3,0.44-0.63,0.87-0.97,1.29c-0.06,0.08-0.13,0.15-0.19,0.22c-0.32,0.38-0.65,0.75-1,1.1
        c-0.04,0.04-0.08,0.08-0.12,0.12l-74.12,75c-3.81,3.86-8.84,5.79-13.87,5.79c-4.95,0-9.9-1.87-13.7-5.63
        c-7.66-7.57-7.73-19.91-0.16-27.57l53.68-54.32l-14.44-5.87l-29.93,24.73c-3.63,3-8.03,4.47-12.41,4.47
        c-5.61,0-11.19-2.41-15.04-7.08c-6.86-8.3-5.69-20.59,2.61-27.45l38.91-32.15c3.56-2.94,7.96-4.47,12.42-4.47
        c2.48,0,4.97,0.47,7.34,1.44l36.26,14.74c2.42-4.34,5.1-9.13,7.66-13.67l-29.62-4.76c-5.69-0.91-10.44-4.82-12.45-10.22
        l-15.21-40.96c-3.07-8.28,1.14-17.48,9.42-20.55C226.99,213.41,228.87,213.09,230.72,213.09 M230.72,205.09
        c-2.85,0-5.66,0.51-8.35,1.5c-12.4,4.61-18.74,18.44-14.14,30.84l15.21,40.96c0.23,0.61,0.48,1.21,0.75,1.79
        c-0.81-0.07-1.62-0.11-2.44-0.11c-6.38,0-12.6,2.24-17.52,6.3l-38.91,32.15c-5.66,4.68-9.16,11.28-9.86,18.59
        c-0.7,7.31,1.5,14.46,6.18,20.12c5.24,6.35,12.97,9.98,21.21,9.98c6.37,0,12.59-2.24,17.5-6.3l17.95-14.83l-38.05,38.5
        c-5.16,5.22-7.98,12.15-7.94,19.49c0.04,7.34,2.94,14.23,8.17,19.4c5.18,5.12,12.04,7.94,19.33,7.94c7.41,0,14.35-2.9,19.56-8.17
        l74.12-75l0.14-0.15c0.46-0.47,0.92-0.97,1.4-1.54c0.1-0.12,0.19-0.22,0.27-0.32c0.46-0.57,0.92-1.18,1.36-1.82l-6.55-4.59
        l6.58,4.55l0.11-0.16c0.39-0.57,0.77-1.19,1.13-1.82l0.06-0.09l0.13-0.25c0.63-1.14,7.98-14.47,14.74-26.47l2.61,1.93l4.06,3
        l-3.41,21.43c-1.01,6.33,0.51,12.67,4.27,17.86c3.76,5.19,9.32,8.6,15.65,9.61c1.25,0.2,2.53,0.3,3.79,0.3
        c11.85,0,21.81-8.5,23.67-20.22l5.71-35.83c1.41-8.88-2.2-17.72-9.44-23.07c-41.18-30.42-41.26-30.47-41.3-30.5
        c-0.2-0.15-0.4-0.28-0.6-0.41c-0.33-0.23-0.75-0.51-1.22-0.79c-0.22-0.13-0.45-0.26-0.69-0.39l-0.1-0.06
        c-0.37-0.2-0.79-0.43-1.27-0.66c-0.29-0.14-0.6-0.27-0.92-0.4l-0.09-0.04l-0.09-0.04c-0.35-0.15-0.7-0.29-1.05-0.42
        c-0.49-0.18-0.95-0.31-1.25-0.41l-0.12-0.04c-0.29-0.09-0.57-0.17-0.87-0.25c-0.49-0.12-0.93-0.21-1.34-0.29
        c-0.27-0.06-0.51-0.11-0.76-0.15l-44.56-7.16l-10.34-27.85C249.73,211.37,240.7,205.09,230.72,205.09L230.72,205.09z"/>
        </g>
      </g>
    </svg>
  )
}
export default PhysicalDamage