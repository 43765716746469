import React from 'react'
import Button from '../../../components/button/button'
import LocationInput from '../../../components/locationInput/locationInput'
import AgencyContext from '../../../context/agency-context'
import './address.scss'
import { CircularProgress } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { validateZipCode } from '../../../helpers/endpoints'
import { registerAddress } from '../../../helpers/analytics'
class Address extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            zipCode: '',
            state: '',
            city: '',
            errorMessage: undefined,
            isLoading: false,
            savedAddress: ''
        }
    }
    forward = () => {
        this.props.history.push(`/${this.context.agencyName}/name`)
    }
    submit = () => {
        if (
            this.state.address !== '' &&
            this.state.zipCode !== '' &&
            this.state.state !== '' &&
            this.state.city !== ''
        ) {
            this.setState(
                {
                    errorMessage: undefined,
                    isLoading: true,
                },
                async () => {
                    const response = await validateZipCode(
                        this.context.agencyName,
                        this.state.zipCode,
                        this.context.agencyId
                    )

                    if (!response.agency_code) {
                        this.setState({
                            errorMessage: response.message,
                            isLoading: false,
                        })
                    } else if (response.agency_code !== this.context.agencyId) {
                        this.setState({
                            errorMessage: 'This address is outside of the area we rate for',
                            isLoading: false,
                        })
                    } else {

                        if (!this.state.address.match(/^\d/)) { // if they didn't include their street number
                            this.setState({
                                errorMessage: 'Please include a street number',
                                isLoading: false
                            })
                        }
                        else {
                            this.props.setters.policy.Addr1(this.state.address.split(',')[0])
                            this.props.setters.policy.City(this.state.city)
                            this.props.setters.policy.StateProvCd(this.state.state)

                            this.props.setters.policy.PostalCode(this.state.zipCode)

                            const vehicleOneYear = this.props.getters.vehicle.ModelYear('VEH1')
                            const vehicleOneModel = this.props.getters.vehicle.Model('VEH1')
                            const vehicleOneManufacturer = this.props.getters.vehicle.Manufacturer('VEH1')

                            const nextPath = (vehicleOneYear === '' && vehicleOneModel === '' && vehicleOneManufacturer === '') ? 'vin' : 'vehicles'

                            registerAddress(this.state.zipCode)

                            this.props.history.push(`/${this.context.agencyName}/${nextPath}`)
                        }
                    }
                }
            )
        } else {
            this.setState({
                errorMessage: 'Please select your address',
            })
        }
    }

    selectZip = (zip) => {
        this.setState({
            zipCode: zip,
        })
    }

    selectAddress = (address) => {
        this.setState({
            address: address,
        })
    }

    selectState = (state) => {
        this.setState({
            state: state,
        })
    }

    selectCity = (city) => {
        this.setState({
            city: city,
        })
    }

    clearAddressAcord = () => {
        this.props.setters.policy.Addr1("")
        this.props.setters.policy.City("")
        this.props.setters.policy.StateProvCd("")
        this.props.setters.policy.PostalCode("")
    }

    componentDidMount = () => {
        this.setState({
            address: this.props.getters.policy.Addr1(),
            state: this.props.getters.policy.StateProvCd(),
            zipCode: this.props.getters.policy.PostalCode(),
            city: this.props.getters.policy.City()
        })
    }

    render = () => {
        return (
            <div className="address">
                <div className="quote-headline-two" style={{ marginTop: '120px' }}>What is your current address?</div>
                <div className="address-section">
                    <LocationInput
                        clearAddressAcord={this.clearAddressAcord}
                        valueFront={this.props.getters.policy.CompleteAddress()}
                        label="Address"
                        savedAddress={this.state.savedAddress}
                        selectCity={this.selectCity}
                        selectZip={this.selectZip}
                        selectState={this.selectState}
                        selectAddress={this.selectAddress}
                        errorMessage={this.state.errorMessage}></LocationInput>
                    <div className="container-buttons-router">
                        <div className="container-buttons-router-left">
                            <Button
                                width="120px"
                                onClick={this.forward}
                                buttonText={'Back'}></Button>
                        </div>
                        <div className="container-buttons-router-right">
                            <Button width="120px" onClick={this.submit} buttonText={'Save'}></Button>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && (
                    <div
                        className="loading-progress"
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginBottom: '25px',
                            marginTop: '35px',
                        }}>
                        <CircularProgress color="primary" />
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(Address)
