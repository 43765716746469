import React from 'react'
import './name.scss'
import BasicInput from '../../../components/basicInput/basicInput'
import { withRouter } from 'react-router-dom'
import Button from '../../../components/button/button'
import AgencyContext from '../../../context/agency-context'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import RadioInput from '../../../components/radioInput/radioInput'
import InfoButton from '../../../components/infoButton/infoButton'
import { registerPolicyInfoPage } from '../../../helpers/analytics'
class PolicyName extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props)

        this.state = {
            isError: [false, false, false],
            id: 'DRV1', // defaulted to driver 1
        }
    }

    componentDidMount = () => {
        if (this.props.values.quoteInfo) {
            const { id } = this.state

            this.setState({
                fullName: `${this.props.getters.policy.GivenName() ? this.props.getters.policy.GivenName() : ''} ${this.props.getters.policy.Surname() ? this.props.getters.policy.Surname() : ''
                    }`,
                gender: this.props.getters.driver.GenderCd(id),
                maritalStatus: this.props.getters.driver.MaritalStatusCd(id),
                sr22: this.props.getters.driver.SR22(id),
            })
        }
    }

    nameCheck = (value) => {
        if (value && /^[a-z ,.'-]+$/i.test(value)) {
            return false
        }
        return true
    }

    maritalCheck = (value) => {
        if (value && (value === 'S' || value === 'M' || value === 'D' || value === 'W')) {
            return false
        }
        return true
    }

    genderCheck = (value) => {
        if (value && (value === 'M' || value === 'F')) {
            return false
        }
        return true
    }

    SR22Check = (value) => {
        if (value && (value === 'Yes' || value === 'No')) {
            return false
        }
        return true
    }

    checkAll = () => {
        const { setters } = this.props

        this.setState(
            {
                isError: [
                    this.nameCheck(this.state.fullName),
                    this.genderCheck(this.state.gender),
                    this.maritalCheck(this.state.maritalStatus),
                    this.SR22Check(this.state.sr22),
                ],
            },
            () => {
                var errors = this.state.isError

                if (!errors.includes(true)) {
                    const { id } = this.state

                    const fullName = this.state.fullName.split(' ')
                    if (id === 'DRV1') {
                        setters.policy.GivenName(fullName[0])
                        setters.policy.Surname(fullName[1])
                    }
                    setters.driver.GivenName(id, fullName[0])
                    setters.driver.Surname(id, fullName[1])
                    setters.driver.GenderCd(id, this.state.gender)
                    setters.driver.MaritalStatusCd(id, this.state.maritalStatus)
                    setters.driver.SR22(id, this.state.sr22)

                    registerPolicyInfoPage(this.state.gender, this.state.maritalStatus)

                    this.props.history.push(`/${this.context.agencyName}/policy/license`)
                }
            }
        )
    }

    goBack = () => {
        const { getters, history } = this.props
        const { agencyName } = this.context
        const comesFromReview = getters.general.editFromReviewScreen()
        const path = comesFromReview ? 'review' : 'vehicles'
        history.push(`/${agencyName}/${path}`)
    }

    render = () => {
        return (
            <div className="policy-name">
                <div className="quote-headline-two">Enter your policyholder information</div>
                <div className="policy-input-block">
                    <BasicInput
                        label="Full Name"
                        defaultValue={this.state.fullName}
                        onChange={(val) => {
                            this.setState({
                                fullName: val,
                            })
                        }}
                        isError={this.state.isError[0]}
                        errorMessage={'Please enter a valid name'} />
                    <div className="policy-name-dropdowns">
                        <DropdownInput
                            label="Gender"
                            options={[
                                { value: '', label: '' },
                                { value: 'M', label: 'Male' },
                                { value: 'F', label: 'Female' },
                            ]}
                            defaultValue={this.state.gender}
                            onChange={(val) => {
                                this.setState({
                                    gender: val,
                                })
                            }}
                            isError={this.state.isError[1]}
                            errorMessage={'Please enter your gender'} />
                        <DropdownInput
                            label="Marital Status"
                            options={[
                                { value: '', label: '' },
                                { value: 'S', label: 'Single' },
                                { value: 'M', label: 'Married' },
                                { value: 'D', label: 'Divorced' },
                                { value: 'W', label: 'Widowed' },
                            ]}
                            defaultValue={this.state.maritalStatus}
                            onChange={(val) => {
                                this.setState({
                                    maritalStatus: val,
                                })
                            }}
                            isError={this.state.isError[2]}
                            errorMessage={'Please enter your marital status'} />
                    </div>
                </div>
                <div className="policy-input-radio-block">
                    <RadioInput
                        inLine={true}
                        label="Do you need SR22?"
                        defaultValue={this.state.sr22}
                        onChange={(val) => {
                            this.setState({
                                sr22: val,
                            })
                        }}
                        isError={this.state.isError[3]}
                        errorMessage={'Please enter if you need SR22 Coverage'}>
                        <InfoButton
                            title="What is SR22?"
                            text={
                                "An SR22 form is an official document that proves that you've purchased the minimum liability insurance available in your state."
                            } />
                    </RadioInput>
                    <div className="container-buttons-router">
                        <div className="container-buttons-router-left">
                            <Button width="120px" onClick={this.goBack} buttonText={'Back'}></Button>
                        </div>
                        <div className="container-buttons-router-right">
                            <Button buttonText="Continue" onClick={this.checkAll}></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PolicyName)
