import React from 'react'
import './UninsuredMotorist.scss'

const UninsuredMotorist = ({ primary, secondary, tertiary, combined }) => {
  return (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 815.4 636.8" className="unninsured-motorist-svg" style={{ enableBackground: 'new 0 0 815.4 636.8' }} >
      <g>
        <path style={{ fill: primary }} d="M323.9,487c-3,0-6-0.9-8.6-2.7c-12.2-8.5-23.3-18.7-32.9-30.2c-59.8-71.5-50.3-178.4,21.2-238.2
          c15.2-12.7,32.3-22.6,50.8-29.3c7.8-2.8,16.4,1.2,19.2,9c2.8,7.8-1.2,16.4-9,19.2c-15.2,5.5-29.3,13.6-41.8,24.1
          c-58.8,49.2-66.7,137.1-17.5,196c7.9,9.5,17,17.8,27.1,24.9c6.8,4.7,8.4,14.1,3.7,20.9C333.3,484.7,328.6,487,323.9,487z"/>
      </g>
      <g>
        <path style={{ fill: tertiary }} d="M313.5,374.5c-7.7,0-14.3-5.9-14.9-13.7c-1.3-14.8,0.1-29.7,4.2-44.1c7.8-27.6,25.1-51.8,48.8-68
          c6.8-4.7,16.2-2.9,20.9,3.9c4.7,6.8,2.9,16.2-3.9,20.9c-17.9,12.3-31,30.5-36.9,51.4c-3.1,10.9-4.2,22.2-3.2,33.4
          c0.7,8.3-5.4,15.5-13.7,16.2C314.4,374.5,313.9,374.5,313.5,374.5z"/>
      </g>
      <g>
        <path style={{ fill: secondary }} d="M421.2,473.4c-11.2,0-22.5-1.5-33.5-4.6c-11.1-3.1-21.7-7.8-31.4-13.9c-7-4.4-9.2-13.6-4.8-20.7
          c4.4-7,13.6-9.2,20.7-4.8c7.4,4.6,15.4,8.1,23.8,10.5c29.1,8.2,60.5,1.8,84.1-17.3c6.4-5.2,15.9-4.2,21.1,2.2
          c5.2,6.4,4.2,15.9-2.2,21.1C476.7,463.9,449.1,473.4,421.2,473.4z"/>
      </g>
      <line className="st3" style={{ stroke: combined }} x1="413.2" y1="169.3" x2="388.7" y2="120.5" />
      <line className="st3" style={{ stroke: combined }} x1="484.6" y1="187.5" x2="539" y2="157.5" />
      <line className="st3" style={{ stroke: combined }} x1="456.1" y1="157.8" x2="461.5" y2="142.3" />
      <g>
        <g>

          <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -38.4085 371.5928)" className="st4" cx="429.3" cy="232.2" rx="39.1" ry="39.1" />
          <path style={{ fill: combined }} d="M429.3,197.1c19.4,0,35.1,15.7,35.1,35.1c0,19.4-15.7,35.1-35.1,35.1c-19.4,0-35.1-15.7-35.1-35.1
            C394.3,212.8,410,197.1,429.3,197.1 M429.3,189.1c-23.7,0-43.1,19.3-43.1,43.1c0,23.7,19.3,43.1,43.1,43.1
            c23.7,0,43.1-19.3,43.1-43.1C472.4,208.4,453.1,189.1,429.3,189.1L429.3,189.1z"/>
        </g>
        <g>
          <path className="st4" d="M547,442.2c-6.3,0-12.3-2.5-16.7-7l-74.2-75.1c-0.4-0.4-0.8-0.8-1.2-1.3l-0.2-0.3c-0.4-0.5-0.8-1-1.2-1.6
            l-0.1-0.2c-0.4-0.5-0.7-1.1-1-1.7l0-0.1l-0.1-0.1c-1.8-3.2-10.1-18.4-17-30.4l-12.3,9.1l3.8,23.8c1.7,10.9-5.7,21.2-16.6,22.9
            c-1,0.2-2.1,0.2-3.2,0.2c-9.9,0-18.2-7.1-19.7-16.8l-5.7-35.8c-1.2-7.4,1.8-14.8,7.9-19.2l41.3-30.5c0.2-0.1,0.3-0.2,0.4-0.3
            c0.4-0.2,0.7-0.5,1.1-0.7c0.2-0.1,0.4-0.2,0.6-0.3l0.1-0.1c0.3-0.2,0.7-0.4,1-0.5c0.2-0.1,0.5-0.2,0.8-0.3l0.1,0
            c0.4-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.2,1-0.3l0.1,0c0.2-0.1,0.5-0.1,0.7-0.2c0.4-0.1,0.8-0.2,1.2-0.3c0.2,0,0.4-0.1,0.6-0.1
            l46.9-7.5l11.2-30c2.9-7.8,10.4-13,18.7-13c2.4,0,4.7,0.4,7,1.3c10.3,3.8,15.6,15.4,11.8,25.7l-15.2,41
            c-2.5,6.7-8.5,11.6-15.6,12.8l-24,3.9c1.1,1.9,2.1,3.8,3.1,5.6l33-13.4c2.8-1.1,5.8-1.7,8.8-1.7c5.5,0,10.8,1.9,15,5.4l38.9,32.1
            c10,8.3,11.4,23.1,3.1,33.1c-4.5,5.4-11.1,8.5-18.1,8.5c-5.4,0-10.8-1.9-15-5.4L521,349.4l-6.7,2.7l49.5,50.1
            c4.4,4.5,6.8,10.4,6.8,16.7c0,6.3-2.5,12.2-7,16.6C559.1,439.8,553.3,442.2,547,442.2z"/>
          <path style={{ fill: combined }} d="M516.1,227.9c1.8,0,3.7,0.3,5.6,1c8.3,3.1,12.5,12.3,9.4,20.6l-15.2,41c-2,5.4-6.8,9.3-12.4,10.2l-29.6,4.8
            c2.6,4.5,5.2,9.3,7.7,13.7l36.3-14.7c2.4-1,4.9-1.4,7.3-1.4c4.5,0,8.9,1.5,12.4,4.5l38.9,32.1c8.3,6.9,9.5,19.1,2.6,27.5
            c-3.9,4.7-9.4,7.1-15,7.1c-4.4,0-8.8-1.5-12.4-4.5l-29.9-24.7l-14.4,5.9l53.7,54.3c7.6,7.7,7.5,20-0.2,27.6
            c-3.8,3.8-8.8,5.6-13.7,5.6c-5,0-10.1-1.9-13.9-5.8l-74.1-75c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.7-0.7-1-1.1
            c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.4-0.7-0.9-1-1.3c0,0-0.1-0.1-0.1-0.1c-0.3-0.5-0.6-1-0.9-1.5c0,0,0,0,0,0
            c-0.1-0.3-11.5-20.9-19.3-34.5c-6.1,4.5-12.8,9.5-17.8,13.2l4.2,26.2c1.4,8.7-4.6,16.9-13.3,18.3c-0.9,0.1-1.7,0.2-2.5,0.2
            c-7.7,0-14.5-5.6-15.8-13.5l-5.7-35.8c-0.9-5.9,1.5-11.8,6.3-15.4c41.2-30.4,41.3-30.5,41.3-30.5c0.1-0.1,0.2-0.1,0.3-0.2
            c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.2,0.5-0.3,0.8-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.3
            c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.7-0.2c0.3-0.1,0.6-0.1,1-0.2c0.2,0,0.3-0.1,0.4-0.1l49.2-7.9l12-32.2
            C503.5,231.9,509.7,227.9,516.1,227.9 M516.1,219.9L516.1,219.9c-10,0-19,6.3-22.5,15.6l-10.3,27.8l-44.5,7.2
            c-0.3,0-0.5,0.1-0.8,0.2c-0.4,0.1-0.9,0.2-1.4,0.3c-0.3,0.1-0.6,0.2-0.9,0.2l-0.1,0c-0.3,0.1-0.8,0.2-1.3,0.4
            c-0.3,0.1-0.7,0.3-1,0.4l-0.1,0l-0.1,0c-0.3,0.1-0.6,0.3-0.9,0.4c-0.5,0.2-0.9,0.4-1.2,0.6l-0.1,0.1c-0.2,0.1-0.5,0.3-0.7,0.4
            c-0.5,0.3-0.9,0.5-1.2,0.8c-0.2,0.1-0.4,0.3-0.6,0.4c0,0-0.1,0.1-41.3,30.5c-7.2,5.3-10.9,14.2-9.4,23.1l5.7,35.8
            c1.9,11.7,11.8,20.2,23.7,20.2c1.3,0,2.5-0.1,3.8-0.3c6.3-1,11.9-4.4,15.6-9.6c3.8-5.2,5.3-11.5,4.3-17.9l-3.4-21.4l4.1-3l2.6-1.9
            c6.7,12,14.1,25.4,14.7,26.5l0.1,0.2l0.1,0.1c0.4,0.6,0.7,1.3,1.2,1.9l0.1,0.1c0.5,0.7,0.9,1.3,1.4,1.9c0.1,0.1,0.2,0.2,0.2,0.3
            c0.5,0.6,1,1.1,1.4,1.6c0,0,0.1,0.1,0.1,0.2l74.1,75c5.2,5.3,12.2,8.2,19.6,8.2c7.3,0,14.1-2.8,19.3-7.9
            c5.2-5.2,8.1-12.1,8.2-19.4c0-7.3-2.8-14.3-7.9-19.5l-38.1-38.5l18,14.8c4.9,4.1,11.1,6.3,17.5,6.3c8.2,0,16-3.6,21.2-10
            c4.7-5.7,6.9-12.8,6.2-20.1c-0.7-7.3-4.2-13.9-9.9-18.6l-38.9-32.1c-4.9-4.1-11.1-6.3-17.5-6.3c-0.8,0-1.6,0-2.4,0.1
            c0.3-0.6,0.5-1.2,0.8-1.8l15.2-41c4.6-12.4-1.7-26.2-14.1-30.8C521.8,220.4,519,219.9,516.1,219.9L516.1,219.9z"/>
        </g>
      </g>
    </svg>

  )
}

export default UninsuredMotorist