import React from 'react';
import './displaySection.scss';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

const DisplaySection = ({
    editItem,
    item,
    itemIcon,
    itemSubtitle,
    itemTitle,
    removeItem,
    styleEditButton,
    styleRemoveButton,
}) => (
    <div className='item-display-tab'>
        <div className='item-title'>
            <div className='item-title-section'>
                <div className='item-image'>
                    {itemIcon}
                </div>
                <div className='item-text-section'>
                    <div className='item-title-text'>
                        {itemTitle}
                    </div>
                    <div className='item-subtitle-text'>
                        {itemSubtitle}
                    </div>
                </div>
            </div>
        </div>
        <div className='item-buttons'>
            <div className='item-button' onClick={() => { editItem(item['@id']) }} style={styleEditButton}>
                <EditIcon /> <span style={{ marginLeft: '10px' }}>Edit</span>
            </div>
            <div className='item-button' onClick={() => { removeItem(item['@id']) }} style={styleRemoveButton}>Remove</div>
        </div>
    </div>
);

DisplaySection.propTypes = {
    editItem: PropTypes.func,
    itemIcon: PropTypes.any,
    itemSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
    itemTitle: PropTypes.string,
    removeItem: PropTypes.func,
}

export default DisplaySection;