import React, { Component } from 'react'
import './App.css'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import capitalizeInJson from '../src/helpers/capitalize'
import AgencyContext from './context/agency-context';
import FormHeader from './components/formheader/formheader'
import Main from './components/main/main'
import { CircularProgress, MuiThemeProvider, createTheme } from '@material-ui/core';
import Favicon from 'react-favicon';
import mixpanel from 'mixpanel-browser'
import ReactPixel from 'react-facebook-pixel'
import themes from './themes'
import uuid from 'react-uuid'
import { init } from '@sentry/browser';
import ReactGa from 'react-ga'
import { withCookies } from 'react-cookie'
import { getWeekCount } from './helpers/dateHelpers'
import { shortCodeV2 } from './helpers/endpoints';
import localStorageService from './helpers/localStorage'
import { keysStorage } from './helpers/constants'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'

class App extends Component {
    static contextType = AgencyContext;

    state = {
        agencyId: null,
        agencyInfo: null,


        steps: [
            {
                target: '.form-standard',
                content: 'This exists'
            }
        ]
    }

    constructor(props) {
        super(props)

        const { cookies } = props

        this.state = {
            uuid: cookies.get('uuid') || uuid()
        }

        cookies.set('uuid', this.state.uuid)
    }

    render() {
        document.querySelector("body").style.background = themes.main.backgroundColor;
        return (
            <React.Fragment>
                <Helmet>
                    {this.state.agencyInfo &&
                        <title>{`${this.state.agencyInfo.agency_name} Get a Quote`}</title>
                    }
                </Helmet>
                {this.state.agencyFavicon && <Favicon url={this.state.agencyFavicon} />}
                <MuiThemeProvider theme={themes.main.mui}>
                    {!window.location.pathname === '/' && !this.state.agencyInfo &&
                        <CircularProgress style={{ position: "absolute", top: "10%", left: "50%" }}></CircularProgress>
                    }
                    <BrowserRouter>
                        <AgencyContext.Provider value={this.state}>

                            {this.state.agencyInfo &&
                                <React.Fragment>
                                    <Route exact path='/:agencyName' render={(props) =>
                                        <Redirect from='/:agencyName' to={`/${this.state.agencyName}/start`}></Redirect>
                                    }></Route>
                                    <Route path={`/:agencyName/:something`} render={(props) =>
                                        <React.Fragment>
                                            <FormHeader agencyInfo={this.state.agencyInfo}></FormHeader>
                                            <Main {...props}></Main>
                                        </React.Fragment>
                                    }></Route>
                                </React.Fragment>
                            }
                        </AgencyContext.Provider>
                        {this.state.customScriptURL && <Helmet>
                            <script src={this.state.customScriptURL} type="text/javascript" />
                        </Helmet>}
                    </BrowserRouter>
                </MuiThemeProvider>
            </React.Fragment>
        )
    }

    componentDidMount = async () => {

        init({ dsn: 'https://0f2b5f6fe1f841c9bd3d784eb95b2ca3@172.16.1.117:8080/22' });


        if (process.env.REACT_APP_ENV === 'production') {

            console.log(`Quoting Portal Production Version: w${getWeekCount()}${process.env.REACT_APP_VERSION}`)

            mixpanel.init("6fdd0eeb6dd4840981a28cd14dd55b06", { cross_site_cookie: true });

            mixpanel.identify(this.props.cookies.get('uuid'))

            ReactPixel.init('3023389271229971', {}, {})
            ReactPixel.pageView()

            const tagManagerArgs = {
                gtmId: 'GTM-5RRCBPR',
                dataLayer: {
                    userId: this.props.cookies.get('uuid')
                }
            }

            TagManager.initialize(tagManagerArgs)

        }
        else {
            console.log(`Quoting Portal Test Version: w${getWeekCount()}${process.env.REACT_APP_VERSION}`)
        }

        const path = window.location.pathname.split('/')
        const shortCode = path[1]
        let agencyCode = ''
        if (path.includes('bridgev4')) {
            agencyCode = path[2]
        }
        else {
            agencyCode = window.location.search ? queryString.parse(window.location.search).id : undefined
            if (agencyCode === undefined) {
                const dataFromStorage = await localStorageService.getFromStorage(keysStorage.STATE)
                agencyCode = dataFromStorage?.quoteInfo?.request?.ACORD?.InsuranceSvcRq?.PersAutoPolicyQuoteInqRq?.Producer?.ProducerInfo?.ContractNumber
            }
        }

        this.fetchAgencyInfo(shortCode, agencyCode);
    }


    initializeReactGA = () => {
        ReactGa.initialize('UA-85627864-3');
        ReactGa.pageview('/quickquote');
    }

    fetchAgencyInfo = async (shortCode, agencyCode) => {

        try {

            if (shortCode === '') {
                throw Error()
            }
            else {

                const agencyData = await shortCodeV2(shortCode, agencyCode)

                const customScriptURL = agencyData.custom_script_url ? agencyData.custom_script_url : undefined

                const defaultAgencyCode = agencyCode ? agencyCode : agencyData.agency_code

                const theme = agencyData.theme ? agencyData.theme : undefined

                if (theme) {
                    themes.main = {
                        mui: createTheme({
                            typography: {
                                useNextVariants: true,
                                color: theme.typographyColor
                            },
                            palette: {
                                primary: {
                                    main: theme.palettePrimaryMainColor
                                },
                                secondary: {
                                    main: theme.paletteSecondaryMainColor
                                },
                                tertiary: {
                                    main: theme.palettePrimaryTertiaryColor
                                },
                                combined: {
                                    main: theme.palettePrimaryCombinationColor
                                }
                            },
                            overrides: {
                                MuiFormLabel: {
                                    asterisk: {
                                        color: '#db3131',
                                    },
                                },
                            },
                        }),
                        footerColor: theme.footerColor
                    }
                }

                this.setState({
                    agencyId: defaultAgencyCode,
                    agencyLogo: agencyData.logo,
                    agencyInfo: capitalizeInJson(agencyData, ["agency_name", "address"]),
                    agencyFavicon: agencyData.favicon,
                    agencyName: shortCode,
                    customScriptURL: customScriptURL,
                    isEditable: agencyData.allow_editing === "True" ? true : false
                })
            }

        } catch (err) {
            console.log("Failed to fetch agency info.", err)
            alert('Invalid Agency Name')
        }

    }
}


export default withCookies(App)
