import React from 'react';
import './displayVehicle.scss';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress'
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { updateNew, save } from '../../../helpers/endpoints'
import AgencyContext from '../../../context/agency-context';
import Button from '../../../components/button/button';
import AddButton from '../../../components/addButton/addButton';
import DisplaySection from '../../../components/displaySection/displaySection';
import { registerVehiclesPage } from '../../../helpers/analytics';

class DisplayVehicle extends React.Component {
    state = {
        vehicles: undefined,
        fetchingQuotes: false
    }

    static contextType = AgencyContext

    componentDidMount = () => {

        const { values } = this.props

        this.setVehicles()

        const auth = this.props.getters.general.Auth()

        save(values.quoteInfo, this.context.agencyName, auth, this.context.uuid, this.props.nextStep({
            stepId: 'Vehicle Info',
            stepNum: 2
        }), this.props.getters.general.CustomerID())

    }

    setVehicles = async () => {
        let vehicles = this.props.values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh;

        for (var i in vehicles) { //check for invalid vehicles
            if (vehicles[i].Model === '' || vehicles[i].Manufacturer === '' || vehicles[i].ModelYear === '') {
                await this.props.removeVehicle(vehicles[i]['@id'])
            }
        }

        this.setState({
            vehicles: this.props.values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
        }, () => {
            this.props.setters.general.SelectedVehicle(`VEH${this.state.vehicles.length}`);
        });

    }

    editVehicle = (id) => {
        this.props.setters.general.SelectedVehicle(id);
        this.props.history.push(`/${this.context.agencyName}/vin`);
    }

    goBack = () => {
        const { getters, history } = this.props
        const { agencyName } = this.context
        const comesFromReview = getters.general.editFromReviewScreen()
        const path = comesFromReview ? 'review' : 'vin'

        history.push(`/${agencyName}/${path}`);
    }

    removeVehicle = async (id) => {
        if (this.state.vehicles.length > 1) {
            await this.props.removeVehicle(id);
            this.props.setters.general.SelectedVehicle(id);
            this.setVehicles();
        }
    }

    addAnotherVehicle = async () => {
        await this.props.addNewVehicle();
        const vehiclesLength = this.state.vehicles.length;
        this.props.setters.general.SelectedVehicle(`VEH${vehiclesLength}`);
        this.props.history.push(`/${this.context.agencyName}/vin`);
    }

    proceed = async () => {
        const { history, getters, setters } = this.props
        const { agencyName } = this.context
        const comesFromReview = getters.general.editFromReviewScreen()
        if (comesFromReview) {
            const quotes = await this.getCoveragesOptions()

            if (quotes) {
                setters.general.setQuoteCoverage(quotes)
                setters.general.EditFromReviewScreen(false)
                history.push(`/${agencyName}/coverages`);
            }
        } else {
            registerVehiclesPage()
            history.push(`/${agencyName}/policy/name`);
        }
    }

    getCoveragesOptions = async () => {
        this.setState({ fetchingQuotes: true })
        try {
            const minimumReq = this.updateAcordObject('minimum')
            const recommendedReq = this.updateAcordObject('recommended')
            const premiumReq = this.updateAcordObject('premium')
            const token = this.props.getters.general.Auth()

            const quoteArray = await Promise.all([
                updateNew(minimumReq, token),
                updateNew(recommendedReq, token),
                updateNew(premiumReq, token),
            ])

            return quoteArray
        } catch (error) {
            this.setState({ fetchingQuotes: false })
        }
    }

    updateAcordObject = (type) => {
        const { getters } = this.props
        const quoteInfo = getters.general.quoteInfo()
        const quotesCoverages = getters.general.quoteCoverage()
        const coverage = getters.general.Coverages()[type]
        switch (type) {
            case 'minimum': {
                const minimumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const minimumPolicyNumber = quotesCoverages[0].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = minimumPolicyNumber
                const vehicles = minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach(vehicle => {
                    vehicle.Coverage = coverage
                })
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles

                return minimumRq
            }
            case 'recommended': {
                const recommendedRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const recommendedPolicyNumber = quotesCoverages[1].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = recommendedPolicyNumber
                const vehicles = recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach(vehicle => {
                    vehicle.Coverage = coverage
                })

                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return recommendedRq
            }
            case 'premium': {
                const premiumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const premiumPolicyNumber = quotesCoverages[2].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = premiumPolicyNumber
                const vehicles = premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach(vehicle => {
                    vehicle.Coverage = coverage
                })

                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return premiumRq
            }
            default:
                break;
        }
    }



    componentWillUnmount = () => {
        this.setState({ fetchingQuotes: false })
    }

    render = () => {
        const { fetchingQuotes } = this.state
        return (
            <div className='display-vehicle'>
                <div className='quote-headline-two' style={{ fontSize: '36px' }}>Vehicles</div>
                {this.state.vehicles &&
                    <div className='display-vehicle-section'>
                        {this.state.vehicles.map((vehicle, id) => {
                            return (
                                <DisplaySection
                                    editItem={this.editVehicle}
                                    item={vehicle}
                                    itemIcon={(<DriveEtaIcon className='vehicle-icon' />)}
                                    itemId={`Vehicle ${id}`}
                                    itemSubtitle={vehicle.ModelYear}
                                    itemTitle={`${vehicle.Manufacturer} ${vehicle.Model}`}
                                    key={`Vehicle ${id}`}
                                    removeItem={this.removeVehicle}
                                    styleEditButton={{
                                        borderRight: '1px solid #eeeeee',
                                        color: '#008EDE',
                                        flex: 1,
                                    }}
                                    styleRemoveButton={{
                                        color: this.state.vehicles.length === 1 ? '#eeeeee' : '#008EDE',
                                        cursor: this.state.vehicles.length === 1 && 'unset',
                                        flex: 1,
                                    }}
                                />
                            )
                        }
                        )}
                        <AddButton onClick={this.addAnotherVehicle} label='Add Another Vehicle' />
                        <div className='buttons-row'>
                            <Button
                                align='center'
                                buttonText='Back'
                                onClick={this.goBack}
                                width='120px'
                            />
                            <Button
                                align='center'
                                buttonText='Save and Continue'
                                onClick={this.proceed}
                                width='250px'
                            />
                        </div>
                    </div>
                }
                <div
                    className="loading-quotes"
                    style={{
                        opacity: fetchingQuotes ? '1' : '0',
                        visibility: fetchingQuotes ? 'visible' : 'hidden',
                    }}>
                    <CircularProgress />
                </div>
            </div>
        )
    }
}

export default withRouter(DisplayVehicle);