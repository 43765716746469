const capitalizeInJson= (json, keys) => {

    for (var i in keys) {
        json[keys[i]] = json[keys[i]]
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    return json
  };

export default capitalizeInJson