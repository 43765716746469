import React, { useEffect, useState } from 'react'
import themes from '../../themes'
import './button.scss'
import PropTypes from 'prop-types'

const Button = (props) => {
    const { buttonText, onClick, width, color, align, disabled, textColor, noMargin } = props

    const [loaded, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        let timer = setTimeout(() => setLoad(false), 1500);

        return () => {
            clearTimeout(timer)
        }
    }, [])


    return (
        <div
            className={!loaded ? (disabled ? 'disabled' : 'button') : (disabled ? 'disabled float' : 'button float')}
            onClick={onClick}
            style={{
                alignSelf: align ? align : 'flex-end',
                width: width ? width : '130px',
                backgroundColor: color ? color : themes.main.mui.palette.primary.main,
                color: textColor ? textColor : '#ffffff',
                marginTop: noMargin && '0px',
                marginBottom: noMargin && '0px'
            }}>
            {buttonText}
        </div>
    )
}

Button.displayName = 'Button'
Button.propTypes = {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    width: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    align: PropTypes.string,
}
export default Button
