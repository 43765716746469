import { getAuth, uwQuestionLookup } from '../../../helpers/endpoints'

const populateVehicles = async (quote, props) => {
    let vehicles = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh

    if (!Array.isArray(vehicles)) {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = []
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.push(vehicles)
    }

    let quoteArray = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh

    for (var i in quoteArray) {

        if (quoteArray[i]['@id'].length < 4) {
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i]['@id'] = `VEH${quoteArray[i]['@id']}`
            props.values.vehicleErrors[quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i]['@id']] = {}
        }

        if (quoteArray[i]["AdditionalInterest"]) { 
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].payments = 'Yes'
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].ownership = 'No'
        }
        else {
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].payments = 'No'
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].ownership = 'Yes'
        }

        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].GaragingCd = 'No'

        for (var j in quoteArray[i].Coverage) {

            if (quoteArray[i].Coverage[j].CoverageCd === "RGP" && quoteArray[i].Coverage[j].CurrentTermAmt.Amt !== "0.00") {
                quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].Coverage[j].Option = {
                    OptionCd: "Yes",
                    OptionTypeCd: "YNInd1"
                }
            }
        }


    }

    

    return quote
}

const formatPhoneNumber = (phoneNumber) => {

    const splitNumber = phoneNumber.split('-')

    let firstPart = splitNumber[1]

    let secondPart = splitNumber[2].substring(0, 3)

    let lastPart = splitNumber[2].substring(3, splitNumber[2].length)

    return '(' + firstPart + ')' + secondPart + '-' + lastPart

}

const setNameInfo = async (quote) => {


    let nameInfo = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo

    if (Array.isArray(nameInfo)) {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo = nameInfo[0]
    }

    return quote;

}

const setCoverages = async (quote) => {


    if (quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy) {
        if (!quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName) {
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName = "OTHER"
        }
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd = 'Yes'
    }
    else {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd = 'No'
    }



    let coverages = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.Coverage

    if (coverages) {

        if (!Array.isArray(coverages)) {
            let list = []
            list.push(coverages)
            coverages = list
        }

    }
    else {
        coverages = [
            {
                "CoverageDesc": "LEGAL",
                "CoverageCd": "MILLI",
                "Option": {
                    "OptionCd": "No",
                    "OptionTypeCd": "YNInd1"
                }
            }
        ]
    }

    quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.Coverage = coverages

    const option = {
        OptionCd: null
    }

    for (var i in coverages) {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.Coverage[i].Option = option
    }

    

    return quote

}

const setQuestions = async (quote, agencyId) => {

    const state = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.InsuredOrPrincipal.GeneralPartyInfo.Addr.StateProvCd
    let questions = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer
    const token = await getAuth(agencyId)
    const uwQuestions = await uwQuestionLookup(token, agencyId, state)

    let formattedQuestions = []

    uwQuestions.underwriting_questions.forEach((uwQuestion, index) => {
        let question = questions ? questions[index] : uwQuestions.underwriting_questions[index]

        formattedQuestions.push({
            "YesNoCd": questions
                            ? questions[index].YesNoCd
                            : question.requires_explanation_on === "NO"
                                ? "YES"
                                : "NO",
            "Explanation": questions
                            ? questions[index].Explanation
                            : "",
            "QuestionCd": {
                "@CodeListRef": uwQuestion.description,
                "#text": uwQuestion.acord_code
            }
        })
    })

    quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer = formattedQuestions

    return quote
}

const setDriverInformation = async (quote, props) => {

    let drivers = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver

    if (!Array.isArray(drivers)) {
        drivers = []
        drivers.push(quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver)
    }

    for (var i in drivers) {

        if (drivers[i]['@id'].length < 4) {

            drivers[i]['@id'] = `DRV${drivers[i]['@id']}`
            props.values.driverErrors[drivers[i]['@id']] = {}
            
        }


        if (!drivers[i].PersDriverInfo.sr22) {
            drivers[i].PersDriverInfo.sr22 = 'No'
        }

        if (drivers[i].DriverInfo.License.LicensePermitNumber === 'EXCLUDED') {
            drivers[i].PersDriverInfo.sr22 = 'No'
            drivers[i].DriverInfo.License.LicenseStatusCd = 'Active'
            drivers[i].DriverInfo.PersonInfo.OccupationDesc = 'OTHER-OTHER'
        }

        drivers[i].DriverInfo.PersonInfo.MiscParty = {
            
            MiscPartyInfo: { 
                MiscPartyRoleCd: "Employer"
            },
            GeneralPartyInfo: {
                Addr: {
                    PostalCode: quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.InsuredOrPrincipal.GeneralPartyInfo.Addr.PostalCode
                }
            }
        }

    }

    quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver = drivers
    return quote

}

const checkEmailInfo = async (quote) => {

    let communications = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications

    if (communications.EmailInfo) {
        let emailInfo= {}

        if (Array.isArray(communications.EmailInfo)) {
            emailInfo= communications.EmailInfo[0]
        }
        else {
            emailInfo = communications.EmailInfo
        }

        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.EmailInfo = emailInfo

    }
    else {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.EmailInfo = {
            CommunicationUseCd: "Day",
            EmailAddr: ""
        }
    }

    return quote;
}

const checkPhoneInfo = async (quote) => {

    let communications = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications

    if (communications.PhoneInfo) {

        let phoneInfo = {}

        if (Array.isArray(communications.PhoneInfo)) {
            phoneInfo = communications.PhoneInfo[0]
        }
        else {
            phoneInfo = communications.PhoneInfo
        }
    
       phoneInfo.PhoneNumber = formatPhoneNumber(phoneInfo.PhoneNumber)

        
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.PhoneInfo = [
            {
                CommunicationUseCd: "Business",
                PhoneNumber: "",
                PhoneTypeCd: "Fax"
            }, 
            {
                CommunicationUseCd: "Business",
                PhoneNumber: "",
                PhoneTypeCd: "Phone"
            }, 
            phoneInfo
        ]
    }
    else {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.PhoneInfo = [
            {
                CommunicationUseCd: "Business",
                PhoneNumber: "",
                PhoneTypeCd: "Fax"
            }, 
            {
                CommunicationUseCd: "Home",
                PhoneNumber: "",
                PhoneTypeCd: "Phone"
            }, 
            {
                CommunicationUseCd: "Business",
                PhoneNumber: "",
                PhoneTypeCd: "Phone"
            }
        ]
        
    }

    return quote;

}


const setCommunicationsDefault = async (quote) => {

   let communications = quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications

    if (!communications) {
        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications = {

            EmailInfo: {
                CommunicationUseCd: "Day",
                EmailAddr: ""
            },
            PhoneInfo: [
                {
                    CommunicationUseCd: "Business",
                    PhoneNumber: "",
                    PhoneTypeCd: "Fax"
                }, 
                {
                    CommunicationUseCd: "Home",
                    PhoneNumber: "",
                    PhoneTypeCd: "Phone"
                }, 
                {
                    CommunicationUseCd: "Business",
                    PhoneNumber: "",
                    PhoneTypeCd: "Phone"
                }, 
            ]
        }
    }
    else  {
        
       quote = await checkPhoneInfo(quote)
       quote = await checkEmailInfo(quote)

    }
    return quote
}

export const setupQuote = async(quote, props, context) => {

    try {
        quote = await setNameInfo(quote)
        quote = await setQuestions(quote, context.agencyId)
        quote = await setCommunicationsDefault(quote)
        quote = await populateVehicles(quote, props)
        quote = await setCoverages(quote)
        quote = await setDriverInformation(quote, props)

        quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PayorCd = 'IN'

        if (quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption) {
            quote.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.MethodPaymentCd = 'CreditCard'
        }

        quote.request.ACORD.SignonRq = {
            "ClientApp": {
                "Name": "Web",
                "Org": "Web",
                "Version": "1.0.0.0"
            },
            "ClientDt": "2021-10-01T19:00:00",
            "CustLangPref": "EN-US",
            "SignonTransport": {
                "CustId": {
                    "CustPermId": context.agencyId,
                    "SPName": "insurancemgrs.com"
                },
                "SignonRoleCd": "Agent"
            }
        }

        return quote
    }
    catch(err) {
        console.log(err)
        return null;
    }
}