import { FormLabel, Select, FormHelperText } from '@material-ui/core'
import './dropdownInput.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import themes from '../../themes'
import React, { useState, useEffect } from 'react'

const DropdownInput = (props) => {
    const { isError, label, errorMessage, defaultValue, onChange, options, valueFront, icon } = props

    const [value, setValue] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])

    useEffect(() => {
        setValue(valueFront)
    }, [valueFront])

    return (
        <div className={icon ? 'dropdown-input-icon' : 'dropdown-input'}>
            <div className="input-label">
                <FormLabel>{label}</FormLabel>
                {props.children && props.children}
            </div>
            {icon && (
                <FontAwesomeIcon
                    icon={icon}
                    style={{ position: 'absolute', zIndex: '1000', paddingTop: '4px', paddingLeft: '15px', color: themes.main.mui.palette.primary.main }}
                />
            )}

            <Select
                style={
                    icon
                        ? {
                            marginTop: '-20px',
                        }
                        : {}
                }
                disableUnderline
                name={label}
                native
                value={valueFront ? valueFront : value}
                onChange={(e) => {
                    onChange(e.target.value)
                    setValue(e.target.value)
                }}>
                {options.map((option) => {
                    if (typeof option === 'string') {
                        option = {
                            label: option,
                            value: option,
                        }
                    }

                    return (
                        <option key={option.label} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </Select>
            <div className="helper">
                <div className="sub-helper" style={{ opacity: isError ? '1' : '0' }}>
                    <FormHelperText error>{errorMessage}</FormHelperText>
                </div>
            </div>
        </div>
    )
}

export default DropdownInput
