import React, { useEffect, useState, useContext } from 'react'
import get from 'lodash/get'
import { useParams, useHistory } from 'react-router-dom'
import AgencyContext from '../../../context/agency-context'
import { getAuth, quotingBridgeV4, getQuoteByZipCode } from '../../../helpers/endpoints'
import LoadingModal from '../../../components/modal/modal'
import { setupQuote } from '../setupQuote/setupQuote'
import localStorageService from '../../../helpers/localStorage'
import { keysStorage } from '../../../helpers/constants'
import { updateNew } from '../../../helpers/endpoints'
import './bridgeV4.scss';

const BridgeV4 = (props) => {

  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const context = useContext(AgencyContext)
  const { agency_code, added_date, policy_id, quote_number } = useParams()

  const fetchQuote = async () => {
    try {

      context.agencyId = agency_code
      const token = await getAuth(context.agencyId)
      const { data, status } = await quotingBridgeV4(quote_number, policy_id, added_date, token)
      if (status === 200) {
        const ProducerInfo = {
          "ProducerSubCode": context.agencyId,
          "ContractNumber": context.agencyId
        }
        const producerNode = Object.assign({}, data.acord.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.Producer, { ProducerInfo })
        data.acord.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.Producer = producerNode

        const quoteInfo = await setupQuote({ request: data.acord }, props, { agencyId: context.agencyId })
        const drivers = get(quoteInfo, 'request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver')

        const driverWithMissinInfo = drivers.reduce((driversWithEmpty, driver) => {
          const licenseStatus = get(driver, 'DriverInfo.License.LicenseStatusCd', '')
          const licensePermitNum = get(driver, 'DriverInfo.License.LicensePermitNumber', '')
          const licenseState = get(driver, 'DriverInfo.License.StateProvCd', '')
          const sr22 = get(driver, 'PersDriverInfo.sr22', '')

          if (!licenseStatus || !licensePermitNum || !sr22 || !licenseState || licenseState == "NA" || licenseState == "CW") {
            driversWithEmpty.push(driver)
          }

          return driversWithEmpty
        }, [])

        const postalCode = get(quoteInfo, 'request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.InsuredOrPrincipal.GeneralPartyInfo.Addr.PostalCode', '')
        const quoteByZipRes = await getQuoteByZipCode(context.agencyName, postalCode, context.agencyId)
        const coverages = get(quoteInfo, 'request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[0].Coverage')

        props.setters.general.Auth(token)
        props.setters.general.LicenseStatuses(quoteByZipRes.license_statuses)
        props.setters.general.quoteInfo(quoteInfo)
        props.setters.general.ComesFromQuotingBridge(true)
        props.setters.general.Coverages(coverages)
        props.setters.general.isEditable(context.isEditable)

        await localStorageService.saveInStorage(keysStorage.STATE, props.values)

        if (!props.getters.general.isEditable()) {
          try {

            const auth = await getAuth(context.agencyId)
            const updateResponse = await updateNew(data.acord, auth)

            if (props.getters.general.ComesFromQuotingBridge()) {
              const quoteInfo = updateResponse.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.QuoteInfo
              const persPolicyNode = Object.assign({}, updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy, { QuoteInfo: quoteInfo })
              updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy = persPolicyNode
              props.setters.general.setQuoteCoverage([updateResponse])
            }
            setLoading(false)
            props.setters.general.quoteInfo(updateResponse)
            history.push(`/${context.agencyName}/policy/home`)
          }
          catch (error) {
            setLoading(false)
            console.error(error)
          }
        }
        else {
          history.push(`/${context.agencyName}/driver-missing-info`)

        }

      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchQuote()
  }, [])

  return (
    <div className="bridgeV4-container">
      <LoadingModal isOpen={loading} headline={'Loading Your Quote'} />
      {!loading && <div className="not-found">
        Could not load quote
      </div>}
    </div>
  )
}

export default BridgeV4