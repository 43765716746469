import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {CookiesProvider} from 'react-cookie'

import './index.scss'


ReactDOM.render((
       <CookiesProvider>
              <App></App>
       </CookiesProvider>
), document.getElementById('root'))