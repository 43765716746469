import React from 'react'
import { withRouter } from 'react-router-dom'
import './index.scss'
import AgencyContext from '../../context/agency-context'
import Button from '../../components/button/button'
import BasicInput from '../../components/basicInput/basicInput'
import LoadingModal from '../../components/modal/modal'
import { getAuth, lookup, getQuoteByZipCode } from '../../helpers/endpoints'
import { diamondDate } from '../../helpers/dateHelpers'
import utils from '../../helpers/utils'
import Alert from '@material-ui/lab/Alert'
import { registerDriversPage } from '../../helpers/analytics'
import { submitNew, updateNew } from '../../helpers/endpoints'
class Index extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props)

        this.state = {
            isError: [false, false, false, false, false, false],
            birthDateMessage: 'Please enter a valid birthdate',
            email: '',
            loaded: true,
            buttonClicked: false,
            createdLead: false,
            firstName: '',
            lastName: '',
            birthDate: '',
            zipcode: '',
            uuid: '',
            cust_id: '',
            viewForm: false,
            ACCORD_OBJECT: undefined,
        }
    }
    componentDidMount = async () => {
        const query = new URLSearchParams(this.props.location.search)
        const uuid = query.get('uuid')
        const cust_id = query.get('cust_id')

        const shortCode = window.location.pathname.split('/')[1]
        if (uuid !== null && uuid !== undefined && cust_id !== null && cust_id !== undefined) {
            const auth = await getAuth(this.context.agencyId)

            this.props.setters.general.Auth(auth)

            this.setState({
                loaded: false,
            })

            const data = {
                uuid: uuid,
                zoho_customer_id: cust_id,
            }
            const result = await lookup(data, auth, shortCode)
            if (result.status !== 404) {
                this.setAccordObject(result)
            } else {
                this.setState({
                    loaded: true,
                    viewForm: true,
                })
            }
        } else {
            this.setState({
                loaded: true,
                viewForm: true,
            })
        }
    }

    checkAll = async () => {
        this.setState({
            loaded: false,
        })
        let errors = this.state.isError
        errors[0] = this.state.firstName === ''
        errors[1] = this.state.lastName === ''
        errors[2] = this.state.birthDate === ''
        errors[3] = this.state.email === '' || !utils.isValidEmail(this.state.email)
        errors[4] = this.state.zipcode === ''
        errors[5] = false
        if (errors.some((x) => x === true)) {
            this.setState({
                isError: errors,
                loaded: true,
            })
            return
        }

        const shortCode = window.location.pathname.split('/')[1]

        const auth = this.props.getters.general.Auth()

        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            birth_date: this.convertBirthDateToDiamondDate(this.state.birthDate),
            zip_code: this.state.zipcode,
            email: this.state.email,
        }
        const result = await lookup(data, auth, shortCode)
        if (result.status !== 404) {
            this.setAccordObject(result)
        } else {
            window.scrollTo(0, 0)
            errors[5] = true
            this.setState({
                isError: errors,
                loaded: true,
            })
        }
        return
    }

    setAccordObject = async (response) => {
        const { setters } = this.props
        let data = response.acord_req
        data.ACORD = data.ACORD.ACORD === undefined ? data.ACORD : data.ACORD.ACORD
        let vehicles = data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
        if (!Array.isArray(vehicles)) {
            data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = []
            data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.push(vehicles)
        }
        let drivers = data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver
        if (!Array.isArray(drivers)) {
            drivers = []
            drivers.push(data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver)
            data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.push(drivers)
        }
        setters.general.setAccord(data)
        let url = 'name'
        if (response.step_id === 'Vehicle Info') {
            url = 'vehicles'
        } else if (response.step_id === 'Policy Info') {
            url = 'policy/home'
        } else if (response.step_id === 'Driver Info') {
            url = 'drivers'
        } else if (response.step_id === 'Quote Review') {
            url = 'coverages'
        }
        const zip =
            data.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.InsuredOrPrincipal.GeneralPartyInfo.Addr.PostalCode

        getQuoteByZipCode(this.context.agencyName, zip, this.context.agencyId).then((res) => {
            this.props.setters.general.quoteLoaded(true)

            var licenseStatuses = res.license_statuses

            licenseStatuses.unshift('')

            this.props.setters.general.LicenseStatuses(licenseStatuses)
            this.props.setters.general.Coverages(res.coverages)
            this.context.agencyId = res.agency_code
            if (response.step_id === 'Quote Review') {
                this.setCoverageReview(url)
            } else {
                this.props.history.push(`/${this.context.agencyName}/${url}`)
            }
        })
    }

    setCoverageReview = async (url) => {
        const { setters } = this.props
        const quotes = await this.getCoveragesOptions()
        if (quotes) {
            setters.general.setQuoteCoverage(quotes)
            setters.general.EditFromReviewScreen(false)
            registerDriversPage()
            this.setState({
                fetchingQuotes: false,
            })
            this.props.history.push(`/${this.context.agencyName}/${url}`)
        } else {
            window.scrollTo(0, 0)
            let errors = this.state.isError
            errors[5] = true
            this.setState({
                isError: errors,
                loaded: true,
                viewForm: true,
            })
        }
    }

    getCoveragesOptions = async () => {
        const { getters, values } = this.props
        try {
            this.setState({ fetchingQuotes: true })
            let quoteReq = values.quoteInfo.request
            const token = this.props.getters.general.Auth()
            const comesFromReview = getters.general.editFromReviewScreen()

            let quoteArray

            if (comesFromReview) {
                const minimumReq = this.updateAcordObject('minimum')
                const recommendedReq = this.updateAcordObject('recommended')
                const premiumReq = this.updateAcordObject('premium')
                quoteArray = await Promise.all([
                    updateNew(minimumReq, token),
                    updateNew(recommendedReq, token),
                    updateNew(premiumReq, token),
                ])
            } else {
                quoteArray = await Promise.all([
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['minimum']),
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['recommended']),
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['premium']),
                ])
            }

            return quoteArray
        } catch (error) {
            this.setState({
                fetchingQuotes: false,
                submitError: error.message,
            })
        }
    }
    
    updateAcordObject = (type) => {
        const { getters } = this.props
        const quoteInfo = getters.general.quoteInfo()
        const quotesCoverages = getters.general.quoteCoverage()
        const coverage = getters.general.Coverages()[type]
        switch (type) {
            case 'minimum': {
                const minimumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const minimumPolicyNumber =
                    quotesCoverages[0].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber =
                    minimumPolicyNumber
                const vehicles = minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles

                return minimumRq
            }
            case 'recommended': {
                const recommendedRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const recommendedPolicyNumber =
                    quotesCoverages[1].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber =
                    recommendedPolicyNumber
                const vehicles =
                    recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })

                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return recommendedRq
            }
            case 'premium': {
                const premiumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const premiumPolicyNumber =
                    quotesCoverages[2].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber =
                    premiumPolicyNumber
                const vehicles = premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })

                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return premiumRq
            }
            default:
                break
        }
    }

    convertBirthDateToDiamondDate = (date) => {
        const birthDate = new Date(date)
        return diamondDate(birthDate)
    }

    render = () => {
        if (this.state.viewForm) {
            return (
                <div className="resume">
                    <div className="quote-headline-two" style={{ marginTop: '75px', marginBottom: '75px' }}>
                        Look up your quote
                    </div>

                    <div className="resume-input-block">
                        {this.state.isError[5] && (
                            <Alert className="resume-error-message" severity="error">
                                Could not find your quote. Please call {this.context.agencyInfo.phone_number}
                            </Alert>
                        )}
                        <BasicInput
                            label="Email Address"
                            defaultValue={this.state.email}
                            onChange={(val) => {
                                this.setState({
                                    email: val,
                                })
                            }}
                            isError={this.state.isError[3]}
                            errorMessage={'Please enter a valid email'}
                        />
                        <BasicInput
                            label="First Name"
                            defaultValue={this.state.firstName}
                            onChange={(val) => {
                                this.setState({
                                    firstName: val,
                                })
                            }}
                            isError={this.state.isError[0]}
                            errorMessage={'Please enter a valid first name'}
                        />
                        <BasicInput
                            label="Last Name"
                            defaultValue={this.state.lastName}
                            onChange={(val) => {
                                this.setState({
                                    lastName: val,
                                })
                            }}
                            isError={this.state.isError[1]}
                            errorMessage={'Please enter a valid last name'}
                        />
                        <BasicInput
                            defaultValue={this.state.birthDate}
                            label="Date of Birth"
                            onChange={(val) => {
                                this.setState({
                                    birthDate: val,
                                })
                            }}
                            isError={this.state.isError[2]}
                            errorMessage={this.state.birthDateMessage}
                            mask={[
                                /[01]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                            ]}
                            placeholder="MM/DD/YYYY"
                        />
                        <BasicInput
                            label="Zip Code"
                            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                            defaultValue={this.state.zipcode}
                            onChange={(val) => {
                                this.setState({
                                    zipcode: val,
                                })
                            }}
                            isError={this.state.isError[4]}
                            errorMessage={'Please enter a valid Zip code'}
                        />
                        <Button width={'200px'} buttonText="Look up Quote" onClick={this.checkAll} />
                        <LoadingModal isOpen={!this.state.loaded} headline="Loading your quote"></LoadingModal>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {' '}
                    <LoadingModal isOpen={!this.state.loaded} headline="Loading your quote"></LoadingModal>
                </div>
            )
        }
    }
}

export default withRouter(Index)
