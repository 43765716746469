import React from 'react'
import './name.scss'
import BasicInput from '../../../components/basicInput/basicInput'
import { withRouter } from 'react-router-dom'
import { validateBirthDate } from '../../../views/form/validate'
import { formatDateString } from '../../../helpers/dateHelpers'
import Button from '../../../components/button/button'
import AgencyContext from '../../../context/agency-context'
import PropTypes from 'prop-types'
import { getQuoteByZipCode, save } from '../../../helpers/endpoints'
import LoadingModal from '../../../components/modal/modal'
import { registerName } from '../../../helpers/analytics'

class Name extends React.Component {
    static contextType = AgencyContext
    static propTypes = {
        values: PropTypes.object,
        getters: PropTypes.object.isRequired,
        setters: PropTypes.object.isRequired,
        history: PropTypes.object,
        closeBurgerMenu: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            isError: [false, false, false, false],
            birthDateMessage: 'Please enter a valid birthdate',
            email: '',
            loaded: props.getters.general.quoteLoaded() === false ? undefined : true,
            buttonClicked: false,
            createdLead: props.getters.general.CustomerID() === undefined ? "" : true
        }
    }

    componentDidMount = async () => {

        if (!this.props.values.quoteInfo && !this.props.getters.general.quoteLoaded()) {

            const zip = this.props.getters.general.SelectedZip()

            getQuoteByZipCode(this.context.agencyName, zip, this.context.agencyId).then((res) => {

                this.props.setters.general.quoteLoaded(true)

                let quoteRes = res.acord.ACORD

                quoteRes.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer = this.defaultUwQuestions(quoteRes.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer)
                quoteRes.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[0].AdditionalInterest = undefined

                const quoteInfo = {
                    request: {
                        ACORD: quoteRes,
                    },
                }

                var licenseStatuses = res.license_statuses

                licenseStatuses.unshift('')

                this.props.setters.general.LicenseStatuses(licenseStatuses)
                this.props.setters.general.Coverages(res.coverages)
                this.props.setters.general.quoteInfo(quoteInfo)
                this.context.agencyId = res.agency_code

                this.setState({
                    loaded: true
                })

            })
        }
        else if (this.props.values.quoteInfo) {
            this.setState({
                firstName: this.props.getters.policy.GivenName() ? this.props.getters.policy.GivenName() : '',
                lastName: this.props.getters.policy.Surname() ? this.props.getters.policy.Surname() : '',
                birthDate: this.props.getters.driver.BirthDt('DRV1') ? formatDateString(this.props.getters.driver.BirthDt('DRV1')) : '',
                email: this.props.getters.policy.EmailAddr() ? this.props.getters.policy.EmailAddr() : ''
            })
        }
    }

    defaultUwQuestions = (questions) => {
        let uw_questions = questions

        for (let i in uw_questions) {
            if (uw_questions[i].QuestionCd['@CodeListRef'].toUpperCase().includes("YES,")) {
                uw_questions[i].YesNoCd = 'NO'
            }
            else {
                uw_questions[i].YesNoCd = 'YES'
            }
        }
        return uw_questions
    }

    nameCheck = (value) => {
        if (value && /^[a-zA-Z ]+$/i.test(value)) {
            return false
        }
        return true
    }

    emailCheck = value => {
        const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !regex.test(value)
    }

    handleSetEmail = email => {
        this.setState({ email })
    }

    birthDateCheck = (value) => {
        const error = validateBirthDate(value)

        this.setState({
            birthDateMessage: error ? error : 'Please enter a valid birthdate',
        })

        if (!error) {
            return false
        } else {
            return true
        }
    }

    checkAll = async () => {
        this.setState(
            {
                isError: [
                    this.nameCheck(this.state.firstName),
                    this.nameCheck(this.state.lastName),
                    this.birthDateCheck(this.state.birthDate),
                    this.emailCheck(this.state.email)
                ],
            }, () => {
                if (!this.state.isError.includes(true)) {
                    if (this.state.loaded === undefined) {
                        this.setState({
                            loaded: false,
                        })
                    }

                    this.setState({
                        buttonClicked: true,
                        createdLead: this.props.getters.general.CustomerID() === undefined ? false : true
                    }, async () => {

                        if (this.props.getters.general.CustomerID() === undefined) {
                            this.props.setters.general.CustomerID("")
                            this.setState({
                                createdLead: true
                            })

                        }
                    })
                }
            })

    }

    render = () => {

        const { setters, values } = this.props


        if (this.state.buttonClicked && this.state.loaded === true && this.state.createdLead === true) {

            setters.policy.EmailAddr(this.state.email)
            setters.policy.GivenName(this.state.firstName)
            setters.policy.Surname(this.state.lastName)
            setters.driver.GivenName('DRV1', this.state.firstName)
            setters.driver.Surname('DRV1', this.state.lastName)
            setters.driver.BirthDt('DRV1', new Date(this.state.birthDate))

            const auth = this.props.getters.general.Auth()

            const customerId = this.props.getters.general.CustomerID()

            save(values.quoteInfo, this.context.agencyName, auth, this.context.uuid, this.props.nextStep({
                stepId: 'Basic Info',
                stepNum: 1
            }), customerId)

            registerName(this.context, this.state.firstName, this.state.lastName, this.state.email, this.state.birthDate)

            this.props.history.push(`/${this.context.agencyName}/address`)
        }


        return (
            <div className="policy-name">
                <div className="quote-headline-two">Enter some information</div>
                <div className="input-block">
                    <BasicInput
                        label="First Name"
                        defaultValue={this.state.firstName}
                        onChange={(val) => {
                            this.setState({
                                firstName: val,
                            })
                        }}
                        isError={this.state.isError[0]}
                        errorMessage={'Please enter a valid first name'}
                    />
                    <BasicInput
                        label="Last Name"
                        defaultValue={this.state.lastName}
                        onChange={(val) => {
                            this.setState({
                                lastName: val,
                            })
                        }}
                        isError={this.state.isError[1]}
                        errorMessage={'Please enter a valid last name'}
                    />
                    <BasicInput
                        defaultValue={this.state.birthDate}
                        label="Date of Birth"
                        onChange={(val) => {
                            this.setState({
                                birthDate: val,
                            })
                        }}
                        isError={this.state.isError[2]}
                        errorMessage={this.state.birthDateMessage}
                        mask={[
                            /[01]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                        ]}
                        placeholder="MM/DD/YYYY"

                    />
                    <BasicInput
                        label="Email Address"
                        defaultValue={this.state.email}
                        onChange={this.handleSetEmail}
                        isError={this.state.isError[3]}
                        errorMessage={'Please enter a valid email'}
                    />
                    <Button buttonText="Continue" onClick={this.checkAll} />
                    <LoadingModal isOpen={this.state.loaded === false || this.state.createdLead === false} headline="Loading your quote"></LoadingModal>
                </div>
            </div>
        )
    }
}

export default withRouter(Name)
