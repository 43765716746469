import React from 'react'

import './selected.scss'
const Selected = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 412.1 485.5"
            xmlSpace="preserve">
            <path
                className="qis-st0"
                style={{ fill: props.secondary }}
                d="M23.2,0h257.3c12.8,0,23.2,10.4,23.2,23.2v324c0,12.8-10.4,23.2-23.2,23.2H23.2C10.4,370.5,0,360,0,347.2v-324
	C0,10.4,10.4,0,23.2,0"
            />
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="33.4" y="115" width="303.7" height="370.5" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="qis-st1"
                        style={{ fill: props.primary }}
                        d="M56.7,115h257.3c12.8,0,23.2,10.4,23.2,23.2v324c0,12.8-10.5,23.2-23.2,23.2H56.7
			c-12.8,0-23.2-10.4-23.2-23.2v-324C33.4,125.5,43.9,115,56.7,115"
                    />
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" x="108.4" y="35" width="303.7" height="370.5" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="qis-st2"
                        style={{ fill: props.tertiary }}
                        d="M131.6,35h257.3c12.8,0,23.2,10.4,23.2,23.2v324c0,12.8-10.5,23.2-23.2,23.2H131.6
			c-12.8,0-23.2-10.4-23.2-23.2v-324C108.4,45.5,118.9,35,131.6,35"
                    />
                </g>
            </g>
            <path
                className="qis-st3"
                style={{ fill: props.combined }}
                d="M131.6,35c-12.8,0-23.2,10.5-23.2,23.2v312.2h172.1c12.8,0,23.2-10.4,23.2-23.2V35H131.6z"
            />
            <path
                className="qis-st4"
                d="M62,58.3h257.3c12.8,0,23.2,10.4,23.2,23.2v324c0,12.8-10.5,23.2-23.2,23.2H62c-12.8,0-23.2-10.4-23.2-23.2
	v-324C38.8,68.8,49.2,58.3,62,58.3"
            />
            <path
                className="qis-st5"
                style={{ fill: props.combined }}
                d="M258.6,228.6c0,38.3-31,69.3-69.3,69.3c-38.3,0-69.3-31-69.3-69.3c0-38.3,31-69.3,69.3-69.3
	C227.6,159.3,258.6,190.3,258.6,228.6"
            />
            <polygon
                className="qis-st5"
                points="157,279.4 134.2,343 156.2,329.1 165,354.8 188,290.5 "
                style={{ fill: props.combined }}
            />
            <polygon
                className="qis-st5"
                points="225.5,279.4 248.2,343 226.2,329.1 217.4,354.8 194.5,290.5 "
                style={{ fill: props.combined }}
            />
            <polygon
                className="qis-st4"
                points="189.2,246.2 166.6,258.1 170.9,233 152.6,215.2 177.9,211.5 189.2,188.6 200.4,211.5 225.7,215.2 
	207.4,233 211.7,258.1 "
            />
            <polygon
                className="qis-st6"
                points="189.2,246.2 166.6,258.1 170.9,233 152.6,215.2 177.9,211.5 189.2,188.6 200.4,211.5 225.7,215.2 
	207.4,233 211.7,258.1 "
            />
            <rect className="qis-st7" width="412.1" height="485.5" />
            <rect className="qis-st7" width="412.1" height="485.5" />
        </svg>
    )
}
export default Selected
