import React from 'react'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import Button from '../../../components/button/button'
import { withRouter } from 'react-router-dom'
import './home.scss'
import AgencyContext from '../../../context/agency-context'
import { save, updateNew } from '../../../helpers/endpoints'
import { registerPolicyHome } from '../../../helpers/analytics'
import LoadingModal from '../../../components/modal/modal'

class PolicyHome extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props)

        this.state = {
            isError: [false],
            isLoading: false,
        }
    }

    componentDidMount = () => {
        if (this.props.values.quoteInfo) {
            this.setState({
                residenceOwnedCD: this.props.getters.policy.ResidenceCd(),
            })
        }
    }

    checkResidence = (value) => {
        if (value && value !== '') {
            return false
        }
        return true
    }


    checkAll = () => {
        const { values, setters, getters, history } = this.props
        const { agencyName } = this.context

        this.setState(
            {
                isError: [this.checkResidence(this.state.residenceOwnedCD)],
            },
            async () => {
                var errors = this.state.isError
                const comesFromReview = getters.general.editFromReviewScreen()
                const path = comesFromReview ? 'review' : 'drivers'

                if (!errors.includes(true)) {
                    setters.policy.ResidenceCd(this.state.residenceOwnedCD)
                    getters.policy.ResidenceCd() === "OWNED" ? setters.policy.ResidenceTypeCd("DW") : setters.policy.ResidenceTypeCd()
                    const auth = this.props.getters.general.Auth()
                    save(values.quoteInfo, this.context.agencyName, auth, this.context.uuid, this.props.nextStep({
                        stepId: 'Policy Info',
                        stepNum: 3
                    }), this.props.getters.general.CustomerID())
                    registerPolicyHome(this.state.residenceOwnedCD)
                    if (getters.general.ComesFromQuotingBridge()) {
                        if (!getters.general.isEditable()) {
                            try {
                                this.setState({ isLoading: true })
                                const acord = getters.general.quoteInfo().request
                                const updateResponse = await updateNew(acord, auth)
                                const quoteInfo = updateResponse.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.QuoteInfo
                                const persPolicyNode = Object.assign({}, updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy, { QuoteInfo: quoteInfo })
                                updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy = persPolicyNode
                                setters.general.setQuoteCoverage([updateResponse])
                                this.setState({ isLoading: false })
                                this.props.history.push(`/${agencyName}/review`)
                            }
                            catch (error) {
                                this.setState({ isLoading: false })
                                console.error(error)
                            }
                        }
                    } else {
                        history.push(`/${agencyName}/${path}`)
                    }
                }
            }
        )
    }

    forward = () => {
        this.props.history.push(`/${this.context.agencyName}/policy/name`)
    }

    render = () => {
        return (
            <div className="policy-home" style={{ height: "92vh" }}>
                <LoadingModal isOpen={this.state.isLoading} headline={'Loading Your Quote'} />
                <div className="quote-headline-two" style={{ marginTop: '140px' }}>
                    Do you own or rent your home?
                </div>
                <div className="policy-input-block">
                    <DropdownInput
                        defaultValue={this.state.residenceOwnedCD}
                        label="Ownership Status"
                        options={[
                            { value: '', label: '' },
                            { value: 'OWNED', label: 'Own' },
                            { value: 'RENTD', label: 'Rent' },
                        ]}
                        onChange={(val) => {
                            this.setState({
                                residenceOwnedCD: val,
                            })
                        }}
                        isError={this.state.isError[0]}
                        errorMessage={'Please enter in your home ownership status'}></DropdownInput>
                    <div className="container-buttons-router">
                        {!this.props.getters.general.ComesFromQuotingBridge() &&
                            <div className="container-buttons-router-left">
                                <Button width="120px" onClick={this.forward} buttonText={'Back'}></Button>
                            </div>
                        }
                        <div className="container-buttons-router-right">
                            <Button buttonText="Continue" onClick={this.checkAll}></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PolicyHome)
