import React from 'react'
import './RoadsideAssistance.scss'

const RoadsideAssistance = ({ primary, secondary, tertiary, combined }) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 815.39 636.78" className="roadside-assistance-svg" style={{ enableBackground: 'new 0 0 815.39 636.78' }} >
      <g>
        <path style={{ fill: primary }} d="M464.99,283.26l111.63-83.51l-5.99,9.83l5.99-4.23v60.52c-7.13,3.49-22.47,13.34-22.47,33.67
		c0,6.96,2.79,13.29,7.86,17.82c4.53,4.05,10.59,6.37,16.63,6.37c10.77,0,21.64-7.48,21.64-24.19c0-2.9-2.35-5.24-5.24-5.24
		s-5.24,2.35-5.24,5.24c0,8.58-4.17,13.7-11.16,13.7c-6.89,0-14.01-5.13-14.01-13.7c0-18.36,18.18-25,18.94-25.27l3.53-1.22v-75.11
		l4.64-3.28l-4.64-2.76l21.88-16.37l-15.5-14.72l-156.55,83.7C471.82,257.69,464.99,283.26,464.99,283.26z"/>
        <path style={{ fill: primary }} d="M614.34,395.25l-4.96-0.57v-56.94c0-1.82-1.49-3.3-3.3-3.3H453.22v-23.81c-6.68,6.8-15.98,11.03-26.26,11.03
		c-10.78,0-20.48-4.64-27.21-12.02v24.8H357.9c0-28.47,0-86.84,0-101.84c0-20.05-25.57-24.47-25.57-24.47s-51.15,0-93,0
		c-41.85,0-44.17,28.33-44.17,28.33l-27.9,97.98h-27.7c-1.82,0-3.3,1.49-3.3,3.3v62.31l-0.86,16.25h-7.15v19.51v1.82h9.5
		c0,0,0,0,0,0h41.42c3.17-36.52,33.8-65.18,71.14-65.18c37.34,0,67.97,28.66,71.14,65.18h130.39c3.17-36.52,33.8-65.18,71.14-65.18
		s67.97,28.66,71.14,65.18h19.72c1.82,0,3.3-1.49,3.3-3.3l0.52-35.78C617.64,396.74,616.16,395.25,614.34,395.25z M328.7,245.29
		v57.36l-129.98-0.24c0,0,10.37-38,16.57-58.15c6.2-20.15,20.15-18.08,20.15-18.08s60.19-0.06,78.27-0.06S328.7,245.29,328.7,245.29
		z"/>
        <path style={{ fill: primary }} d="M522.97,382.27c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35
		c31.67,0,57.35-25.68,57.35-57.35C580.32,407.94,554.64,382.27,522.97,382.27z M522.97,474.35c-19.19,0-34.74-15.55-34.74-34.74
		c0-19.19,15.55-34.74,34.74-34.74c19.19,0,34.74,15.55,34.74,34.74C557.71,458.8,542.15,474.35,522.97,474.35z"/>
        <path style={{ fill: primary }} d="M249.4,382.27c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35s57.35-25.68,57.35-57.35
		C306.75,407.94,281.07,382.27,249.4,382.27z M249.4,474.35c-19.19,0-34.74-15.55-34.74-34.74c0-19.19,15.55-34.74,34.74-34.74
		s34.74,15.55,34.74,34.74C284.14,458.8,268.58,474.35,249.4,474.35z"/>
        <path style={{ fill: primary }} d="M424.81,316.85c18.98,0,34.37-15.39,34.37-34.37c0-18.98-15.39-34.37-34.37-34.37
		c-18.98,0-34.37,15.39-34.37,34.37C390.44,301.46,405.83,316.85,424.81,316.85z"/>
      </g>
      <g>
        <path style={{ fill: secondary }} d="M451.99,230.78l111.63-83.51l-5.99,9.83l5.99-4.23v60.52c-7.13,3.49-22.47,13.34-22.47,33.67
		c0,6.96,2.79,13.29,7.86,17.82c4.53,4.05,10.59,6.37,16.63,6.37c10.77,0,21.64-7.48,21.64-24.19c0-2.9-2.35-5.24-5.24-5.24
		s-5.24,2.35-5.24,5.24c0,8.58-4.17,13.7-11.16,13.7c-6.89,0-14.01-5.13-14.01-13.7c0-18.36,18.18-25,18.94-25.27l3.53-1.22v-75.11
		l4.64-3.28l-4.64-2.76l21.88-16.37l-15.5-14.72l-156.55,83.7C458.82,205.21,451.99,230.78,451.99,230.78z"/>
        <path style={{ fill: secondary }} d="M601.34,342.76l-4.96-0.57v-56.94c0-1.82-1.49-3.3-3.3-3.3H440.22v-23.81c-6.68,6.8-15.98,11.03-26.26,11.03
		c-10.78,0-20.48-4.64-27.21-12.02v24.8H344.9c0-28.47,0-86.84,0-101.84c0-20.05-25.57-24.47-25.57-24.47s-51.15,0-93,0
		c-41.85,0-44.17,28.33-44.17,28.33l-27.9,97.98h-27.7c-1.82,0-3.3,1.49-3.3,3.3v62.31l-0.86,16.25h-7.15v19.51v1.82h9.5
		c0,0,0,0,0,0h41.42c3.17-36.52,33.8-65.18,71.14-65.18c37.34,0,67.97,28.66,71.14,65.18h130.39c3.17-36.52,33.8-65.18,71.14-65.18
		s67.97,28.66,71.14,65.18h19.72c1.82,0,3.3-1.49,3.3-3.3l0.52-35.78C604.65,344.25,603.16,342.76,601.34,342.76z M315.71,192.81
		v57.36l-129.98-0.24c0,0,10.37-38,16.57-58.15c6.2-20.15,20.15-18.08,20.15-18.08s60.19-0.06,78.27-0.06
		C318.81,173.63,315.71,192.81,315.71,192.81z"/>
        <path style={{ fill: secondary }} d="M509.97,329.78c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35s57.35-25.68,57.35-57.35
		C567.32,355.46,541.64,329.78,509.97,329.78z M509.97,421.87c-19.19,0-34.74-15.55-34.74-34.74c0-19.19,15.55-34.74,34.74-34.74
		s34.74,15.55,34.74,34.74C544.71,406.32,529.16,421.87,509.97,421.87z"/>
        <path style={{ fill: secondary }} d="M236.4,329.78c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35s57.35-25.68,57.35-57.35
		C293.75,355.46,268.07,329.78,236.4,329.78z M236.4,421.87c-19.19,0-34.74-15.55-34.74-34.74c0-19.19,15.55-34.74,34.74-34.74
		s34.74,15.55,34.74,34.74C271.14,406.32,255.59,421.87,236.4,421.87z"/>
        <path style={{ fill: secondary }} d="M411.82,264.36c18.98,0,34.37-15.39,34.37-34.37s-15.39-34.37-34.37-34.37c-18.98,0-34.37,15.39-34.37,34.37
		S392.84,264.36,411.82,264.36z"/>
      </g>
      <g>
        <path style={{ fill: tertiary }} d="M510.83,260.65l111.63-83.51l-5.99,9.83l5.99-4.23v60.52c-7.13,3.49-22.47,13.34-22.47,33.67
		c0,6.96,2.79,13.29,7.86,17.82c4.53,4.05,10.59,6.37,16.63,6.37c10.77,0,21.64-7.48,21.64-24.19c0-2.9-2.35-5.24-5.24-5.24
		c-2.9,0-5.24,2.35-5.24,5.24c0,8.58-4.17,13.7-11.16,13.7c-6.89,0-14.01-5.13-14.01-13.7c0-18.36,18.18-25,18.94-25.27l3.53-1.22
		v-75.11l4.64-3.28l-4.64-2.76l21.88-16.37l-15.5-14.72l-156.55,83.7C517.66,235.08,510.83,260.65,510.83,260.65z"/>
        <path style={{ fill: tertiary }} d="M660.18,372.64l-4.96-0.57v-56.94c0-1.82-1.49-3.3-3.3-3.3H499.06v-23.81c-6.68,6.8-15.98,11.03-26.26,11.03
		c-10.78,0-20.48-4.64-27.21-12.02v24.8h-41.85c0-28.47,0-86.84,0-101.84c0-20.05-25.57-24.47-25.57-24.47s-51.15,0-93,0
		c-41.85,0-44.17,28.33-44.17,28.33l-27.9,97.98h-27.7c-1.82,0-3.3,1.49-3.3,3.3v62.31l-0.86,16.25h-7.15v19.51v1.82h9.5
		c0,0,0,0,0,0H225c3.17-36.52,33.8-65.18,71.14-65.18c37.34,0,67.97,28.66,71.14,65.18h130.39c3.17-36.52,33.8-65.18,71.14-65.18
		c37.34,0,67.97,28.66,71.14,65.18h19.72c1.82,0,3.3-1.49,3.3-3.3l0.52-35.78C663.48,374.13,662,372.64,660.18,372.64z
		 M374.54,222.68v57.36l-129.98-0.24c0,0,10.37-38,16.57-58.15c6.2-20.15,20.15-18.08,20.15-18.08s60.19-0.06,78.27-0.06
		S374.54,222.68,374.54,222.68z"/>
        <path style={{ fill: tertiary }} d="M568.8,359.66c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35
		c31.67,0,57.35-25.68,57.35-57.35C626.15,385.33,600.48,359.66,568.8,359.66z M568.8,451.74c-19.19,0-34.74-15.55-34.74-34.74
		c0-19.19,15.55-34.74,34.74-34.74c19.19,0,34.74,15.55,34.74,34.74C603.54,436.19,587.99,451.74,568.8,451.74z"/>
        <path style={{ fill: tertiary }} d="M295.24,359.66c-31.67,0-57.35,25.68-57.35,57.35c0,31.67,25.68,57.35,57.35,57.35s57.35-25.68,57.35-57.35
		C352.58,385.33,326.91,359.66,295.24,359.66z M295.24,451.74c-19.19,0-34.74-15.55-34.74-34.74c0-19.19,15.55-34.74,34.74-34.74
		c19.19,0,34.74,15.55,34.74,34.74C329.98,436.19,314.42,451.74,295.24,451.74z"/>
        <path style={{ fill: tertiary }} d="M470.65,294.24c18.98,0,34.37-15.39,34.37-34.37c0-18.98-15.39-34.37-34.37-34.37
		c-18.98,0-34.37,15.39-34.37,34.37C436.28,278.85,451.67,294.24,470.65,294.24z"/>
      </g>
      <polygon className="st3" points="229.97,289.81 359.95,290.05 354.68,185.38 241.27,184.35 210.22,227.16 203.54,292.54 " />
      <polygon className="st3" points="232.07,444.37 306.85,444.61 303.82,339.94 238.57,338.91 220.7,381.72 216.85,447.1 " />
      <polygon className="st3" points="510.18,444.37 584.97,444.61 581.94,339.94 516.68,338.91 498.81,381.72 494.97,447.1 " />
      <g>
        <g>
          <g>
            <path className="st3" d="M437.7,288c-19.81,0-35.92-16.11-35.92-35.92s16.11-35.92,35.92-35.92s35.92,16.11,35.92,35.92
				S457.51,288,437.7,288z"/>
            <path style={{ fill: combined }} d="M437.7,220.66c17.35,0,31.42,14.07,31.42,31.42c0,17.35-14.07,31.42-31.42,31.42
				c-17.35,0-31.42-14.07-31.42-31.42C406.28,234.72,420.35,220.66,437.7,220.66 M437.7,211.66c-22.29,0-40.42,18.13-40.42,40.42
				s18.13,40.42,40.42,40.42c22.29,0,40.42-18.13,40.42-40.42S459.99,211.66,437.7,211.66L437.7,211.66z"/>
          </g>
          <g>
            <path className="st3" d="M590.13,297.04c-7.13,0-14.29-2.74-19.63-7.51c-6.04-5.4-9.37-12.92-9.37-21.17
				c0-20.71,14.16-31.71,22.47-36.38v-54.41l-114.83,85.9l3.35-12.54c0.21-0.86,5.11-21.89-25.29-33.38l-9.27-3.5l168.15-89.9
				l21.72,20.63l-24.35,18.21v82.1l-6.57,2.26c-0.62,0.22-15.91,5.79-15.91,21.01c0,5.76,4.83,9.2,9.51,9.2
				c4.42,0,6.66-3.1,6.66-9.2c0-5.37,4.37-9.74,9.74-9.74c5.37,0,9.74,4.37,9.74,9.74c0,11.28-4.43,17.96-8.14,21.58
				C603.43,294.52,597.03,297.04,590.13,297.04z"/>
            <path style={{ fill: combined }} d="M604.98,129.63l15.5,14.72l-21.88,16.37v81.14l-3.53,1.22c-0.76,0.27-18.94,6.91-18.94,25.27
				c0,8.58,7.12,13.7,14.01,13.7c6.99,0,11.16-5.12,11.16-13.7c0-2.9,2.35-5.24,5.24-5.24c2.9,0,5.24,2.35,5.24,5.24
				c0,16.71-10.87,24.19-21.64,24.19c-6.04,0-12.1-2.32-16.63-6.37c-5.07-4.53-7.86-10.86-7.86-17.82
				c0-20.32,15.34-30.18,22.47-33.67v-66.12l-111.63,83.51c0,0,6.83-25.57-28.05-38.75L604.98,129.63 M606.46,118.63l-5.73,3.06
				l-156.55,83.7l-17.49,9.35l18.55,7.01c11.4,4.31,18.62,10.35,21.47,17.95c1.93,5.17,1.16,9.53,1.05,10.12l-7.88,26.13
				l21.99-16.67l97.24-72.74v42.87c-9.24,5.78-22.47,17.76-22.47,38.95c0,9.55,3.86,18.27,10.87,24.53
				c6.15,5.5,14.4,8.66,22.63,8.66c8.08,0,15.59-2.98,21.14-8.38c4.33-4.22,9.5-11.95,9.5-24.81c0-7.85-6.39-14.24-14.24-14.24
				s-14.24,6.39-14.24,14.24c0,2.39-0.5,3.85-0.94,4.38c-0.07,0.09-0.26,0.32-1.22,0.32c-1.3,0-5.01-0.86-5.01-4.7
				c0-11.81,12.14-16.49,12.93-16.78l3.47-1.2l6.07-2.09v-6.42v-76.64l18.27-13.67l8.54-6.39l-7.73-7.34l-15.5-14.72L606.46,118.63
				L606.46,118.63z"/>
          </g>
          <g>
            <path className="st3" d="M601.47,410.95l-0.36-4.11c-1.44-16.62-9.01-32.02-21.32-43.37c-12.38-11.42-28.48-17.7-45.34-17.7
				s-32.96,6.29-45.33,17.7c-12.31,11.35-19.88,26.75-21.32,43.37l-0.36,4.11H328.8l-0.36-4.11c-1.44-16.62-9.01-32.02-21.32-43.37
				c-12.38-11.42-28.48-17.7-45.34-17.7c-16.85,0-32.95,6.29-45.33,17.7c-12.31,11.35-19.88,26.75-21.32,43.37l-0.36,4.11h-59.55
				v-30.33h7.38l0.63-11.87v-62.19c0-4.3,3.5-7.8,7.8-7.8h24.3l26.87-94.37c0.26-1.97,1.58-9.11,7.34-16.13
				c10.72-13.07,28.36-15.81,41.26-15.81h93.38l0.38,0.07c0.29,0.05,7.24,1.28,14.23,5.23c9.86,5.57,15.07,13.76,15.07,23.67v97.34
				h32.85v-31.91l7.83,8.58c6.11,6.71,14.82,10.55,23.88,10.55c8.74,0,16.92-3.44,23.05-9.68l7.71-7.85v30.31h148.36
				c4.3,0,7.8,3.5,7.8,7.8v52.93l0.77,0.09c4.16,0.16,7.49,3.6,7.49,7.8l-0.52,35.84c0,4.24-3.5,7.74-7.8,7.74H601.47z
				 M335.69,266.95v-53.21l0.06-0.36c0.29-1.92,0.44-7.69-2.39-10.99c-1.71-2-4.38-2.97-8.15-2.97c-18.08,0-78.27,0.06-78.27,0.06
				l-0.33,0c0,0-0.33-0.05-0.33-0.05c-0.02,0-0.19-0.01-0.44-0.01c-3.05,0-10.59,1.46-14.74,14.97
				c-4.68,15.22-11.84,40.97-14.97,52.34L335.69,266.95z"/>
            <path style={{ fill: combined }} d="M343.81,176.94c0,0,25.57,4.42,25.57,24.47c0,15,0,73.37,0,101.84h41.85v-24.8
				c6.73,7.38,16.43,12.02,27.21,12.02c10.29,0,19.58-4.22,26.26-11.03v23.81h152.86c1.82,0,3.3,1.49,3.3,3.3v56.94l4.96,0.57
				c1.82,0,3.3,1.49,3.3,3.3l-0.52,35.78c0,1.82-1.49,3.3-3.3,3.3h-19.72c-3.17-36.52-33.8-65.18-71.14-65.18
				c-37.34,0-67.97,28.66-71.14,65.18H332.93c-3.17-36.52-33.8-65.18-71.14-65.18c-37.34,0-67.97,28.66-71.14,65.18h-41.42
				c0,0,0,0,0,0h-9.5v-1.82v-19.51h7.15l0.86-16.25v-62.31c0-1.82,1.49-3.3,3.3-3.3h27.7l27.9-97.98c0,0,2.32-28.33,44.17-28.33
				C292.66,176.94,343.81,176.94,343.81,176.94 M340.19,271.46V214.1c0,0,3.1-19.18-14.98-19.18s-78.27,0.06-78.27,0.06
				s-0.4-0.06-1.11-0.06c-3.42,0-13.9,1.42-19.04,18.14c-6.2,20.15-16.57,58.15-16.57,58.15L340.19,271.46 M344.58,167.94h-0.77h-93
				c-13.87,0-32.9,3.03-44.74,17.45c-6.2,7.55-7.86,15.2-8.28,18.06l-25.85,90.8h-20.9c-6.78,0-12.3,5.52-12.3,12.3v62.07l-0.4,7.49
				h-7.61v9v19.51v1.82v9h9h9.5h41.42h8.25l0.71-8.22c1.34-15.5,8.41-29.86,19.89-40.45c11.55-10.65,26.56-16.51,42.28-16.51
				c15.72,0,30.74,5.86,42.29,16.51c11.48,10.59,18.54,24.95,19.89,40.45l0.71,8.22h8.25h130.39h8.25l0.71-8.22
				c1.34-15.5,8.41-29.86,19.89-40.45c11.55-10.65,26.56-16.51,42.28-16.51c15.72,0,30.74,5.86,42.28,16.51
				c11.48,10.59,18.54,24.95,19.89,40.45l0.71,8.22h8.25h19.72c6.75,0,12.26-5.47,12.3-12.21l0.52-35.74l0-0.06v-0.07
				c0-5.37-3.46-9.95-8.27-11.62v-49.19c0-6.78-5.52-12.3-12.3-12.3H473.7v-14.81v-22.01l-15.42,15.71
				c-5.28,5.37-12.32,8.33-19.84,8.33c-7.8,0-15.3-3.31-20.56-9.08l-15.65-17.17v23.23v15.8h-23.85v-92.84
				c0-11.47-6.17-21.27-17.36-27.59c-7.53-4.26-14.87-5.61-15.68-5.75L344.58,167.94L344.58,167.94z M246.62,204.12l0.33-0.13
				c0.6,0,60.39-0.06,78.26-0.06c2.34,0,3.93,0.47,4.73,1.39c1.54,1.77,1.58,5.77,1.37,7.39l-0.14,0.76l0.03,0.64v48.34l-109.17-0.2
				c3.55-12.8,9.37-33.53,13.36-46.53c3.33-10.83,8.73-11.77,10.39-11.79L246.62,204.12L246.62,204.12z"/>
          </g>
          <g>
            <path className="st3" d="M534.45,470.28c-34.1,0-61.85-27.75-61.85-61.85c0-34.1,27.75-61.85,61.85-61.85s61.85,27.75,61.85,61.85
				C596.3,442.53,568.56,470.28,534.45,470.28z M534.45,378.19c-16.67,0-30.24,13.57-30.24,30.24c0,16.67,13.57,30.24,30.24,30.24
				s30.24-13.56,30.24-30.24C564.69,391.75,551.13,378.19,534.45,378.19z"/>
            <path style={{ fill: combined }} d="M534.45,351.08c31.67,0,57.35,25.68,57.35,57.35c0,31.67-25.68,57.35-57.35,57.35s-57.35-25.68-57.35-57.35
				C477.1,376.76,502.78,351.08,534.45,351.08 M534.45,443.17c19.19,0,34.74-15.55,34.74-34.74c0-19.19-15.55-34.74-34.74-34.74
				c-19.19,0-34.74,15.55-34.74,34.74C499.71,427.61,515.27,443.17,534.45,443.17 M534.45,342.08c-36.58,0-66.35,29.76-66.35,66.35
				c0,36.58,29.76,66.35,66.35,66.35s66.35-29.76,66.35-66.35C600.8,371.84,571.04,342.08,534.45,342.08L534.45,342.08z
				 M534.45,434.17c-14.19,0-25.74-11.55-25.74-25.74c0-14.19,11.55-25.74,25.74-25.74c14.19,0,25.74,11.55,25.74,25.74
				C560.19,422.62,548.65,434.17,534.45,434.17L534.45,434.17z"/>
          </g>
          <g>
            <path className="st3" d="M260.88,470.28c-34.1,0-61.85-27.75-61.85-61.85c0-34.1,27.75-61.85,61.85-61.85s61.85,27.75,61.85,61.85
				C322.73,442.53,294.99,470.28,260.88,470.28z M260.88,378.19c-16.67,0-30.24,13.57-30.24,30.24c0,16.67,13.57,30.24,30.24,30.24
				s30.24-13.56,30.24-30.24C291.12,391.75,277.56,378.19,260.88,378.19z"/>
            <path style={{ fill: combined }} d="M260.88,351.08c31.67,0,57.35,25.68,57.35,57.35c0,31.67-25.68,57.35-57.35,57.35s-57.35-25.68-57.35-57.35
				C203.54,376.76,229.21,351.08,260.88,351.08 M260.88,443.17c19.19,0,34.74-15.55,34.74-34.74c0-19.19-15.55-34.74-34.74-34.74
				s-34.74,15.55-34.74,34.74C226.15,427.61,241.7,443.17,260.88,443.17 M260.88,342.08c-36.58,0-66.35,29.76-66.35,66.35
				c0,36.58,29.76,66.35,66.35,66.35s66.35-29.76,66.35-66.35C327.23,371.84,297.47,342.08,260.88,342.08L260.88,342.08z
				 M260.88,434.17c-14.19,0-25.74-11.55-25.74-25.74c0-14.19,11.55-25.74,25.74-25.74c14.19,0,25.74,11.55,25.74,25.74
				C286.62,422.62,275.08,434.17,260.88,434.17L260.88,434.17z"/>
          </g>
        </g>
        <circle className="st5" style={{ stroke: combined }} cx="436.3" cy="251.29" r="34.37" />
        <line className="st5" style={{ stroke: combined }} x1="411.17" y1="298.01" x2="464.64" y2="298.01" />
        <polygon style={{ fill: combined }} points="598.6,160.72 588.11,168.57 582.11,178.4 603.23,163.48 	" />
      </g>
    </svg>

  )
}

export default RoadsideAssistance