import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './diagram.scss'
import { mapSteps } from '../../helpers/constants'
import AgencyContext from '../../context/agency-context'
import themes from '../../themes'
import BasicInfo from '../icons/Basic/BasicInfo'
import VehicleInfo from '../icons/Vehicle/selected'
import VehicleInfoUnselected from '../icons/Vehicle/unSelected'
import PolicyInfo from '../icons/Policy/selected'
import PolicyInfoUnselected from '../icons/Policy/unSelected'
import DriverInfo from '../icons/Driver/selected'
import DriverInfoUnselected from '../icons/Driver/unSelected'
import QuoteInfo from '../icons/Quote/selected'
import QuoteInfoUnselected from '../icons/Quote/unSelected'
class DiagramProgress extends Component {
    static propTypes = {
        currentStep: PropTypes.object.isRequired,
        stepsVisited: PropTypes.array.isRequired,
        history: PropTypes.object,
        closeBurgerMenu: PropTypes.func,
    }
    static contextType = AgencyContext
    state = {}

    navigateToRoute = (step) => {
        if (this.props.currentStep.index >= step.index || this.props.stepsVisited.includes(step.index)) {
            const path = step.path[0]
            this.props.history.push(`/${this.context.agencyName}/${path}`)
            this.props.closeBurgerMenu()
        }
    }

    getObjectImage = (step) => {
        switch (step.key) {
            case 'basic-info':
                return (
                    <BasicInfo
                        className={'progress-diagram-icon'}
                        primary={themes.main.mui.palette.primary.main}
                        secondary={themes.main.mui.palette.secondary.main}
                        tertiary={themes.main.mui.palette.tertiary.main}
                        combined={themes.main.mui.palette.combined.main}
                    />
                )
            case 'vehicles-info':
                if (this.props.stepsVisited.includes(step.index)) {
                    return (
                        <VehicleInfo
                            className={'progress-diagram-icon'}
                            primary={themes.main.mui.palette.primary.main}
                            secondary={themes.main.mui.palette.secondary.main}
                            tertiary={themes.main.mui.palette.tertiary.main}
                            combined={themes.main.mui.palette.combined.main}
                        />
                    )
                } else {
                    return <VehicleInfoUnselected className={'progress-diagram-icon'} primary={'#B2B1B1'} />
                }
            case 'policy-info':
                if (this.props.stepsVisited.includes(step.index)) {
                    return (
                        <PolicyInfo
                            className={'progress-diagram-icon'}
                            primary={themes.main.mui.palette.primary.main}
                            secondary={themes.main.mui.palette.secondary.main}
                            tertiary={themes.main.mui.palette.tertiary.main}
                            combined={themes.main.mui.palette.combined.main}
                        />
                    )
                } else {
                    return <PolicyInfoUnselected className={'progress-diagram-icon'} primary={'#B2B1B1'} />
                }
            case 'drivers-info':
                if (this.props.stepsVisited.includes(step.index)) {
                    return (
                        <DriverInfo
                            className={'progress-diagram-icon'}
                            primary={themes.main.mui.palette.primary.main}
                            secondary={themes.main.mui.palette.secondary.main}
                            tertiary={themes.main.mui.palette.tertiary.main}
                            combined={themes.main.mui.palette.combined.main}
                        />
                    )
                } else {
                    return <DriverInfoUnselected className={'progress-diagram-icon'} primary={'#B2B1B1'} />
                }
            case 'coverages-info':
                if (this.props.stepsVisited.includes(step.index)) {
                    return (
                        <QuoteInfo
                            className={'progress-diagram-icon'}
                            primary={themes.main.mui.palette.primary.main}
                            secondary={themes.main.mui.palette.secondary.main}
                            tertiary={themes.main.mui.palette.tertiary.main}
                            combined={themes.main.mui.palette.combined.main}
                        />
                    )
                } else {
                    return <QuoteInfoUnselected className={'progress-diagram-icon'} primary={'#B2B1B1'} />
                }
            default:
                return null
        }
    }

    diagramObject = () => {
        const listItems = mapSteps
            .filter((x) => !x.hiddenInBurger)
            .map((step, i) => (
                <div
                    className="progress-diagram"
                    key={i}
                    onClick={() => {
                        this.navigateToRoute(step)
                    }}>
                    {this.getObjectImage(step)}
                    <div className="progress-diagram-step">
                        <p>{step.name}</p>
                    </div>
                </div>
            ))
        return <div>{listItems}</div>
    }

    render() {
        return this.diagramObject()
    }
}
export default DiagramProgress
