const encrypted = {
    encrypt(value) {
      for (let index = 0; index < 5; index++) {
        value = btoa(value);
      }
      return value;
    },
    decrypt(value) {
      for (let index = 0; index < 5; index++) {
        value = atob(value);
      }
      return value;
    },
  };
  
export default encrypted
