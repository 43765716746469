import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import AgencyContext from '../../context/agency-context'
import DropdownInput from '../../components/dropdownInput/dropdownInput'
import BasicInput from '../../components/basicInput/basicInput'
import Button from '../../components/button/button'
import { convertShortDate, addDaysTodate, formatDateString, diamondDate } from '../../helpers/dateHelpers'
import LoadingModal from '../../components/modal/modal'
import ErrorModal from '../../components/modal/error/errorModal'
import ReviewPolicyInfo from './reviewPolicyInfo'
import './review.scss'
import themes from '../../themes'
import { save, updateNew, docusign } from '../../helpers/endpoints'
import localStorageService from '../../helpers/localStorage'
import { keysStorage } from '../../helpers/constants'
import { registerRateCallTwo } from '../../helpers/analytics'
class QuoteReview extends PureComponent {
    state = {
        phone: '',
        email: '',
        effectiveDate: '',
        errorEffectiveDate: '',
        errorEmail: '',
        selectedPayment: 0,
        payments: [],
        paymentsList: [],
        isError: [false, false, false],
        isLoading: false,
        licenseState: '',
        licenseNumber: '',
        licenseNumberError: '',
    }
    static contextType = AgencyContext

    componentDidMount() {
        const { getters, setters, values } = this.props
        const payments = getters.policy.PaymentPlan()
        let effectiveDate = values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt
        const date = diamondDate(!getters.general.isEditable() ? new Date(effectiveDate) : new Date())
        const auth = this.props.getters.general.Auth()
        save(
            values.quoteInfo,
            this.context.agencyName,
            auth,
            this.context.uuid,
            this.props.nextStep({
                stepId: 'Quote Review',
                stepNum: 5,
            }),
            this.props.getters.general.CustomerID()
        )
        const filteredPayments = getters.general.isEditable() ? (
            payments.length > 0 && payments.filter((payment) => {
                if (payment.NumPayments >= '4') {
                    return payment.Description.split(' ').pop() === 'CC'
                } else {
                    return payment.NumPayments === '3' || payment.NumPayments === '0'
                }
            }))
            : payments.filter((payment) => !payment.Description.includes("Recurring") && !payment.Description.includes("CC"))

        let ordered = filteredPayments.length &&
            filteredPayments.sort((a, b) => Number(a.DepositAmt.Amt) - Number(b.DepositAmt.Amt))

        if (!getters.general.isEditable()) {
            ordered = ordered.sort((a, b) => a.Description === "5 Pay 20% Down" ? -1 : b === "5 Pay 20% Down" ? 1 : 0)
        }

        const paymentOptions = ordered.map((payment) => {
            const { NumPayments, InstallmentInfo, Description } = payment
            const multiplePayments = NumPayments > '1'
            const installmentMsg = multiplePayments ? 'Installments' : 'Installment'
            const payOption = getters.general.isEditable() ?
                (NumPayments === '0'
                    ? Description
                    : `${multiplePayments ? NumPayments : 1} ${installmentMsg} of $${InstallmentInfo.InstallmentAmt.Amt
                    }${multiplePayments ? '/month' : ''}`) : Description
            return payOption
        })

        setters.policy.PaymentPlan(filteredPayments[0].Description)
        const licenseState = getters.driver.StateProvCd('DRV1')
        const licenseNumber = getters.driver.LicensePermitNumber('DRV1')
        const phone = getters.policy.PhoneNumber()
        const email = getters.policy.EmailAddr()
        this.setState(
            {
                email: email,
                phone: phone,
                paymentsList: paymentOptions,
                effectiveDate: formatDateString(date),
                payments: filteredPayments,
                licenseState,
                licenseNumber,
            },
            () => {
                get(this.state.payments, `${this.state.selectedPayment}.DepositAmt.Amt`)
            }
        )
    }

    handleSelectPayment = (value) => {
        const { setters } = this.props
        const { paymentsList } = this.state
        const paymentIndex = paymentsList.indexOf(value)
        this.setState({ selectedPayment: paymentIndex }, () => {
            setters.policy.PaymentPlan(this.state.payments[paymentIndex].Description)
        })
    }

    handleSetPhoneNumber = (phone) => {
        this.setState({
            phone: phone,
        })
    }

    handleSetEmail = (email) => {
        this.setState({
            email: email,
        })
    }

    handleSetEffectiveDate = (date) => {
        this.setState({ effectiveDate: date })
    }

    valueCheck = (value) => {
        return value === ''
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !re.test(String(email).toLowerCase())
    }

    validateEffectiveDate = () => {
        const { effectiveDate } = this.state
        let invalid = false
        if (effectiveDate === '' || effectiveDate.length < 10) {
            invalid = true
            this.setState({ errorEffectiveDate: 'Please enter a valid date' })
        } else {
            const today = new Date()
            const effective = new Date(effectiveDate)
            const effectiveDateLimit = new Date()
            addDaysTodate(effectiveDateLimit, 30)
            today.setHours(0, 0, 0, 0)
            if (effective < today) {
                invalid = true
                this.setState({ errorEffectiveDate: 'Effective date cannot be a date before today' })
            } else if (effectiveDateLimit < effective) {
                invalid = true
                this.setState({
                    errorEffectiveDate: 'Your policy effective date cannot be more than 30 days in the future',
                })
            }
        }
        return invalid
    }

    checkAll = async () => {
        const { phone, email, effectiveDate, selectedPayment } = this.state
        const { setters, getters, values, history } = this.props
        const { agencyName } = this.context
        const invalidPhone = getters.general.isEditable() ? this.valueCheck(phone) || phone.length < 13 : false
        const invalidEmail = getters.general.isEditable() ? this.validateEmail(email) : false
        const invalidDate = getters.general.isEditable() ? this.validateEffectiveDate() : false

        if (invalidPhone || invalidDate || invalidEmail) {
            this.setState({ isError: [invalidPhone, invalidDate, invalidEmail] })
        } else {
            this.setState({
                isLoading: true,
            })
            const payments = this.state.payments
            setters.policy.EmailAddr(email)
            setters.policy.PhoneNumber(phone)
            setters.policy.EffectiveDt(convertShortDate(effectiveDate))
            setters.policy.ExpirationDt(effectiveDate)
            setters.policy.PaymentPlan(payments[selectedPayment].Description)
            const auth = this.props.getters.general.Auth()
            const res = await updateNew(values.quoteInfo.request, auth)

            if (!res.error) {
                res.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.BillingMethodCd = 'CPB'
                setters.general.quoteInfo(res)
                const quote = save(
                    values.quoteInfo,
                    this.context.agencyName,
                    auth,
                    this.context.uuid,
                    this.props.nextStep({
                        stepId: 'Quote Review',
                        stepNum: 5,
                    }),
                    this.props.getters.general.CustomerID()
                )
                if (!!quote) {
                    const body = {
                        callback_url: `https://www.blank.org/`,
                        policy_number: res.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                    }

                    const docusignResponse = await docusign(body, auth)

                    if (!!docusignResponse.url) {
                        const hostName =
                            window.location.hostname === 'localhost'
                                ? 'https://uat.insurancemgrs.com'
                                : `https://${window.location.hostname}`

                        const newUrl = docusignResponse.url.replace('https://uniqueinsuranceco.com', hostName)

                        localStorageService.saveInStorage(keysStorage.DOCUSIGN_URL, newUrl)
                        setters.general.docuSignURL(newUrl)

                        registerRateCallTwo(
                            res.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption
                                .DepositAmt.Amt,
                            this.state.phone
                        )

                        history.push(`/${agencyName}/esign`)
                        return
                    } else if (docusignResponse.message === 'This esign has already been completed.') {
                        history.push(`/${agencyName}/pay`)
                        return
                    } else {
                        this.setState({
                            isLoading: false,
                            updateError: 'Error: Request Timed Out',
                        })
                    }
                }
            } else {
                this.setState({
                    isLoading: false,
                    updateError: res.error,
                })
            }
        }
    }

    handleGoBack = (evt) => {
        const { history } = this.props
        const { agencyName } = this.context
        history.push(`/${agencyName}/leasing`)
    }

    render() {
        const {
            phone,
            email,
            effectiveDate,
            errorEffectiveDate,
            isError,
            selectedPayment,
            paymentsList,
            payments,
            updateError,
        } = this.state
        const { getters, setters } = this.props
        const priceToDisplay = payments.length && get(payments, `${selectedPayment}.DepositAmt.Amt`)

        return (

            <div className="review-section">
                <div className="header">
                    <div className="your-quote-label">Down Payment Today</div>
                    <div className="price">{`$${priceToDisplay}`}</div>
                    <div className="payplan-dropdown">
                        <DropdownInput
                            icon={faPlus}
                            options={paymentsList}
                            defaultValue={paymentsList[selectedPayment]}
                            onChange={this.handleSelectPayment}
                        />
                    </div>
                </div>
                <div className="review-quote">
                    <ReviewPolicyInfo {...{ getters, setters }} />
                    <div className="user-form">
                        {!getters.general.isEditable()
                            ? (<React.Fragment>
                                <div><b>Email Address:</b> {email}</div>
                                <div><b>Phone Number:</b> {phone}</div>
                                <div><b>Effective Date:</b> {effectiveDate}</div>
                            </React.Fragment>)
                            : (<div>
                                <BasicInput
                                    label="Email"
                                    defaultValue={email}
                                    onChange={this.handleSetEmail}
                                    isError={isError[2]}
                                    errorMessage={'Please enter a valid email address.'}
                                />
                                <BasicInput
                                    label="Phone Number"
                                    defaultValue={phone}
                                    onChange={this.handleSetPhoneNumber}
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    isError={isError[0]}
                                    errorMessage={'Please enter a valid phone number'}
                                />
                                <BasicInput
                                    defaultValue={effectiveDate}
                                    label="Effective Date"
                                    onChange={this.handleSetEffectiveDate}
                                    isError={isError[1]}
                                    errorMessage={errorEffectiveDate}
                                    mask={[/[01]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    placeholder="MM/DD/YYYY"
                                />
                            </div>
                            )}
                    </div>
                    <div className="buttons-row">
                        {getters.general.isEditable() && (
                            <Button
                                buttonText="Back"
                                color="#ffffff"
                                textColor={themes.main.mui.palette.primary.main}
                                onClick={this.handleGoBack}
                            />)
                        }
                        <Button
                            width="200px"
                            buttonText="Save and Continue"
                            color="#ffffff"
                            textColor={themes.main.mui.palette.primary.main}
                            onClick={this.checkAll}
                        />
                    </div>
                    <LoadingModal isOpen={this.state.isLoading} headline={'Saving your Quote'}></LoadingModal>
                    <ErrorModal
                        errorText={`An error has occurred with obtaining your quote. Please call us at: `}
                        isOpen={updateError !== undefined}
                        setter={() => {
                            this.setState({ updateError: undefined })
                        }}
                        headline="An Error Occurred">
                        <span className="error-child">
                            <a className="error-link" href={`tel:${this.context.agencyInfo.phone_number}`}>
                                {this.context.agencyInfo.phone_number}
                            </a>
                        </span>
                        {this.state.updateError !== 'Internal Server Error' && (
                            <div className="error-modal-status">{this.state.updateError}</div>
                        )}
                    </ErrorModal>
                </div>
            </div>
        )
    }
}

export default withRouter(QuoteReview)
