import React, { Component } from 'react';
import { withRouter } from 'react-router'
import './docuSign.css'
import { CircularProgress } from '@material-ui/core';
import AgencyContext from '../../context/agency-context'
import { docusign } from '../../helpers/endpoints';
import { registerDocusign } from '../../helpers/analytics';

class DocuSign extends Component {

    static contextType = AgencyContext

    state = {
        isLoading: true
    }

    render() {

        if (!this.props.values.quoteInfo) {
            this.props.resetQuote();
            return null;
        }
        else {

            return (
                <React.Fragment>
                    <div className='loading-block' style={{ height: '700px' }}>
                        <div className='loading-message'>Please wait while we redirect you to DocuSign</div>
                        <div className='loading-progress' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '25px' }}>
                            <CircularProgress
                                color="primary"
                            />
                        </div>
                    </div>
                </React.Fragment>)

        }
    }

    componentDidMount = async () => {

        this.scrollToTop()

        var quoteId = this.props.values.quoteId;

        registerDocusign(true)

        const formData = {
            callback_url: `https://insurancemgrs.com/${this.context.agencyId}/${quoteId}/payment`,
            policy_number: quoteId,
            return_completed_attachment: false,
            is_remote: false,
            agent_email: "skim@uniqueinsuranceco.com",
            override_agent_signature_required: true,
            single_sign_on_authentication: true,
            initiate_signing: true
        }

        const esignResponseJson = await docusign(formData, this.context.agencyId)

        if (esignResponseJson) {

            if (esignResponseJson["Status"] === 'AlreadySigned') {
                window.location.replace(`https://insurancemgrs.com/${this.context.agencyId}/${quoteId}/payment`)
            }
            else {

                this.setState({
                    urlEsign: esignResponseJson.GetESignResult.URL.replace('http://', 'https://'),
                    isLoading: false
                })

                window.location.replace(this.state.urlEsign)
            }
        }
    }

    scrollToTop = () => {
        document.querySelector('.form-header').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    }
}

export default withRouter(DocuSign);
