import React from 'react'

const BodilyInjury = ({ primary, secondary, tertiary, combined }) => {
  return (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 815.39 636.78" style={{ enableBackground: 'new 0 0 815.39 636.78' }} >
      <g>
        <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -19.2372 73.9147)" style={{ fill: primary }} cx="448.84" cy="156.28" rx="65.66" ry="65.66" />
        <path style={{ fill: primary }} d="M609.69,318.86l-70.71-58.98c0,0,0,0,0,0c-5.96-6.15-11.69-10.75-17.03-14.2l-2.05-1.71
          c-6.41-5.35-14.22-7.9-21.96-7.82c-6.54-0.93-10.44-0.02-10.44-0.02h-82.43c-2.47,0-4.93,0.27-7.33,0.81l-88.76,19.75
          c-16.28,3.62-27.47,18.6-26.34,35.24l0.68,9.97l-33.02,3.13c-2.24,0.21-4.28,1.34-5.65,3.12c-1.37,1.78-1.94,4.05-1.58,6.26
          l25.76,156.48c0.65,3.93,4.04,6.78,7.99,6.78c0.11,0,0.23,0,0.34-0.01l43.37-1.82l1.69,27.68c0.26,4.3,3.83,7.61,8.08,7.61
          c0.17,0,0.33-0.01,0.5-0.01c4.47-0.27,7.86-4.11,7.59-8.58l-1.67-27.38l41.58-1.75c2.16-0.09,4.19-1.04,5.65-2.64
          c1.46-1.6,2.21-3.71,2.1-5.87l-2.86-55.28c3.8,5.07,9.15,8.58,15.63,11.09l1.33,0.26l-52.09,42.35c0,2.64,1.81,58.52,5.39,67.14
          l153.9,2.88c0.52-2.49,4.71-64.49,4.71-67.13v-51.92l13.59-8.29l30.45-7.53l2.02-12.27l3.82-4.28c0.48,1.83,0.8,3.05,0.92,3.52
          l32.97-7.79c12.44-2.94,22.15-12.66,25.09-25.1C623.85,340.08,619.5,327.04,609.69,318.86z M283.64,461.16l-9.33-56.66
          c0.82,0.12,1.64,0.2,2.49,0.2c0.26,0,0.53-0.01,0.79-0.02l7.16,0.37l-1.09-3.14l-3.75-31.78l-3.87-0.52
          c-2.58,0.11-4.99,0.79-7.15,1.89l-8.43-51.18l23.96-2.27l2.47,36.41l3.84,56.1l-0.03-0.1l0.79,11.64
          c1.21,17.82,16.04,31.46,33.64,31.46c0.77,0,1.54-0.03,2.32-0.08c18.6-1.26,32.65-17.37,31.39-35.96l-1.12-16.47l-2.38-34.96
          l-3.39-49.83l0.02,0l0.26,4.08l9.79-6.32l2.82,54.56c-1.78-1.07-0.46-2.09-2.57-2.48l1.6,34.13c2.12-0.59,0.77-1.29,2.48-2.58
          l3.1,59.89L283.64,461.16z"/>
      </g>
      <g>

        <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -17.754 69.7088)" style={{ fill: secondary }} cx="423.5" cy="144.98" rx="65.66" ry="65.66" />
        <path style={{ fill: secondary }} d="M584.34,307.55l-70.71-58.98c0,0,0,0,0,0c-5.96-6.15-11.69-10.75-17.03-14.2l-2.05-1.71
          c-6.41-5.35-14.22-7.9-21.96-7.82c-6.54-0.93-10.44-0.02-10.44-0.02h-82.43c-2.47,0-4.93,0.27-7.33,0.81l-88.76,19.75
          c-16.28,3.62-27.47,18.6-26.34,35.24l0.68,9.97l-33.02,3.13c-2.24,0.21-4.28,1.34-5.65,3.12c-1.37,1.78-1.94,4.05-1.58,6.26
          l25.76,156.48c0.65,3.93,4.04,6.78,7.99,6.78c0.11,0,0.23,0,0.34-0.01l43.37-1.82l1.69,27.68c0.26,4.3,3.83,7.61,8.08,7.61
          c0.17,0,0.33-0.01,0.5-0.01c4.47-0.27,7.86-4.11,7.59-8.58l-1.67-27.38l41.58-1.75c2.16-0.09,4.19-1.04,5.65-2.64
          c1.46-1.6,2.21-3.71,2.1-5.87l-2.86-55.28c3.8,5.07,9.15,8.58,15.63,11.09l1.33,0.26l-28.87,49.67c0,2.64,4.5,57.5,8.08,66.12
          l131.1,1.1c-1.28-16.28,1.6-69,1.6-71.64v-51.92l13.59-8.29l30.45-7.53l2.02-12.27l3.82-4.28c0.48,1.83,0.8,3.05,0.92,3.52
          l32.97-7.79c12.44-2.94,22.15-12.66,25.09-25.1C598.51,328.77,594.16,315.74,584.34,307.55z M258.3,449.86l-9.33-56.66
          c0.82,0.12,1.64,0.2,2.49,0.2c0.26,0,0.53-0.01,0.79-0.02l7.16,0.37l-1.09-3.14l-3.75-31.78l-3.87-0.52
          c-2.58,0.11-4.99,0.79-7.15,1.89l-8.43-51.18l23.96-2.27l2.47,36.41l3.84,56.1l-0.03-0.1l0.79,11.64
          c1.21,17.82,16.04,31.46,33.64,31.46c0.77,0,1.54-0.03,2.32-0.08c18.6-1.26,32.65-17.37,31.39-35.96l-1.12-16.47l-2.38-34.96
          l-3.39-49.83l0.02,0l0.26,4.08l9.79-6.32l2.82,54.56c-1.78-1.07-0.46-2.09-2.57-2.48l1.6,34.13c2.12-0.59,0.77-1.29,2.48-2.58
          l3.1,59.89L258.3,449.86z"/>
      </g>
      <g>

        <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -20.3683 64.1646)" style={{ fill: tertiary }} cx="387.8" cy="158.42" rx="65.66" ry="65.66" />
        <path style={{ fill: tertiary }} d="M548.65,321l-70.71-58.98c0,0,0,0,0,0c-5.96-6.15-11.69-10.75-17.03-14.2l-2.05-1.71
          c-6.41-5.35-14.22-7.9-21.96-7.82c-6.54-0.93-10.44-0.02-10.44-0.02h-82.43c-2.47,0-4.93,0.27-7.33,0.81l-88.76,19.75
          c-16.28,3.62-27.47,18.6-26.34,35.24l0.68,9.97l-33.02,3.13c-2.24,0.21-4.28,1.34-5.65,3.12c-1.37,1.78-1.94,4.05-1.58,6.26
          l25.76,156.48c0.65,3.93,4.04,6.78,7.99,6.78c0.11,0,0.23,0,0.34-0.01l43.37-1.82l1.69,27.68c0.26,4.3,3.83,7.61,8.08,7.61
          c0.17,0,0.33-0.01,0.5-0.01c4.47-0.27,7.86-4.11,7.59-8.58l-1.67-27.38l41.58-1.75c2.16-0.09,4.19-1.04,5.65-2.64
          c1.46-1.6,2.21-3.71,2.1-5.87l-2.86-55.28c3.8,5.07,9.15,8.58,15.63,11.09l1.33,0.26l2.51,46.37c0,2.64-0.76,45.71,2.82,54.33
          l104.01,0.08c0.52-2.49,2.57-52.9,2.57-55.54v-51.92l13.59-8.29l30.45-7.53l2.02-12.27l3.82-4.28c0.48,1.83,0.8,3.05,0.92,3.52
          l32.97-7.79c12.44-2.94,22.15-12.66,25.09-25.1C562.81,342.22,558.46,329.19,548.65,321z M222.6,463.31l-9.33-56.66
          c0.82,0.12,1.64,0.2,2.49,0.2c0.26,0,0.53-0.01,0.79-0.02l7.16,0.37l-1.09-3.14l-3.75-31.78l-3.87-0.52
          c-2.58,0.11-4.99,0.79-7.15,1.89l-8.43-51.18l23.96-2.27l2.47,36.41l3.84,56.1l-0.03-0.1l0.79,11.64
          c1.21,17.82,16.04,31.46,33.64,31.46c0.77,0,1.54-0.03,2.32-0.08c18.6-1.26,32.65-17.37,31.39-35.96l-1.12-16.47l-2.38-34.96
          l-3.39-49.83l0.02,0l0.26,4.08l9.79-6.32l2.82,54.56c-1.78-1.07-0.46-2.09-2.57-2.48l1.6,34.13c2.12-0.59,0.77-1.29,2.48-2.58
          l3.1,59.89L222.6,463.31z"/>
      </g>
      <g>
        <polygon style={{ fill: '#ffffff' }} points="453.86,263.92 422.14,424.49 481.4,418.17 525.18,404.21 484.58,275.05 467.87,257.03 	" />
      </g>
      <g>
        <polygon style={{ fill: combined }} points="636.33,167.83 607.61,167.83 607.61,139.12 565.56,139.12 565.56,167.83 536.85,167.83 536.85,209.89 
          565.56,209.89 565.56,238.6 607.61,238.6 607.61,209.89 636.33,209.89 	"/>
      </g>
      <g>
        <g>
          <circle style={{ fill: '#ffffff' }} cx="413.61" cy="171.88" r="70.16" />
          <path style={{ fill: combined }} d="M413.61,106.22c36.26,0,65.66,29.4,65.66,65.66c0,36.26-29.4,65.66-65.66,65.66s-65.66-29.4-65.66-65.66
            C347.95,135.62,377.35,106.22,413.61,106.22 M413.61,97.22c-41.17,0-74.66,33.49-74.66,74.66s33.49,74.66,74.66,74.66
            s74.66-33.49,74.66-74.66S454.78,97.22,413.61,97.22L413.61,97.22z"/>
        </g>
        <g>
          <path style={{ fill: '#ffffff' }} d="M295.06,531.23c-6.64,0-12.16-5.2-12.57-11.83l-1.42-23.28l-38.96,1.64c-0.2,0.01-0.37,0.01-0.53,0.01
            c-6.19,0-11.42-4.44-12.43-10.55l-25.76-156.48c-0.56-3.43,0.33-6.98,2.45-9.74c2.12-2.75,5.33-4.52,8.79-4.85l28.66-2.71
            l-0.38-5.6c-1.29-19,11.26-35.8,29.85-39.93l88.76-19.75c2.73-0.61,5.52-0.91,8.31-0.91h82.43c1.79,0,3.61,0.13,5.42,0.39
            c1.78-0.25,3.59-0.38,5.39-0.38c8.92,0,17.62,3.15,24.47,8.87l89.79,74.9c11.2,9.34,16.07,23.96,12.73,38.15
            c-3.35,14.19-14.24,25.09-28.43,28.45l-37.25,8.81l-1.4-5.41c-6.82-26.58-29.91-114.84-40.58-134.58
            c-2.95-5.46-9.35-8.66-19.02-9.51c-0.36-0.03-0.72-0.05-1.06-0.05c-4.69,0-7.91,3.13-9.57,9.3c-7.69,28.69-13.6,57.92-18.37,82.73
            l-3.12,16.44c-0.76,4.05-1.52,8.09-2.28,12.14c-2.59,13.81-5.26,28.09-8.03,42.04c0,0-1.01,5.04-1.22,6.12
            c0.37-0.01,0.76-0.01,1.17-0.02c10.77-0.15,33.17-0.47,55.05-4.76l5.37-1.05v61.99c0,2.76-2.04,25.36-2.66,28.3l-0.75,3.57H370.67
            l-1.15-2.77c-3.56-8.57-3.29-21.76-3.19-26.73c0.01-0.35,0.01-0.65,0.02-0.89l-6.31-49.37c-2.75-1.67-5.24-3.64-7.43-5.87
            l2.7,52.22c0.17,3.34-1.02,6.67-3.27,9.13c-2.25,2.47-5.45,3.97-8.79,4.11l-37,1.55l1.39,22.8c0.42,6.93-4.87,12.92-11.81,13.34
            C295.55,531.22,295.31,531.23,295.06,531.23z M252.2,472.11l23.92-1c-13.45-5.24-23.33-17.91-24.36-33.08l-0.87-12.78l-6.46-0.34
            L252.2,472.11z M327.97,439.49c-1.4,13.22-9.63,24.63-21.28,30.33l22.8-0.96L327.97,439.49z M399.77,426.94l10.64-0.71l0.34-1.71
            L399.77,426.94z M247.94,381.64l-0.77-11.26l-2.16-31.8l-14.56,1.38l6.78,41.18c1.11-0.22,2.23-0.36,3.38-0.41l0.4-0.02
            L247.94,381.64z M349.99,377.44c0.78-0.98,1.6-1.92,2.47-2.82l-4.4-34.4L349.99,377.44z M324.72,376.73l-2.15-41.54l-1.1,0.24
            l2.8,41.22L324.72,376.73z M390.59,366.07l32.4-5.48c0.37-1.94,0.73-3.87,1.1-5.8L390.59,366.07z"/>
          <path style={{ fill: combined }} d="M452.27,251.73c1.84,0,3.65,0.15,5.42,0.44c1.78-0.29,3.58-0.43,5.39-0.43c7.62,0,15.28,2.56,21.59,7.82
            l89.79,74.9c9.82,8.19,14.17,21.22,11.23,33.67c-2.93,12.44-12.65,22.16-25.09,25.1l-32.97,7.79
            c-1.39-5.36-29.02-114.07-41.27-136.74c-4.27-7.9-13.24-11.03-22.58-11.85c-0.5-0.04-0.99-0.07-1.46-0.07
            c-7.55,0-11.99,5.47-13.91,12.64c-7.29,27.21-13.07,55.08-18.44,83.06l-73.2,24.67l-6.18-48.38l-7.75,1.72l3.65,70.62
            c1.08-7.87,4.92-15.1,10.73-20.4l69.62-11.78c-4.15,22.07-8.2,44.15-12.67,65.93c0,0,0,0,0,0l2.38-11.78l-53.56,11.77
            c-7.68-4.46-13.59-11.91-15.85-21.21l3.69,71.29c0.11,2.16-0.64,4.27-2.1,5.87c-1.46,1.6-3.49,2.55-5.65,2.64l-41.58,1.75
            l1.67,27.38c0.27,4.47-3.13,8.31-7.59,8.58c-0.17,0.01-0.34,0.01-0.5,0.01c-4.25,0-7.82-3.31-8.08-7.61l-1.69-27.68l-43.37,1.82
            c-0.12,0-0.23,0.01-0.34,0.01c-3.95,0-7.34-2.86-7.99-6.78L207.83,330c-0.36-2.21,0.21-4.48,1.58-6.26
            c1.37-1.78,3.42-2.91,5.65-3.12l33.02-3.13l-0.68-9.97c-1.13-16.64,10.06-31.61,26.34-35.24l88.76-19.75
            c2.41-0.54,4.87-0.81,7.33-0.81H452.27 M289.89,469.19c-17.6,0-32.43-13.65-33.64-31.46l-0.79-11.64l0.03,0.1l-3.84-56.1
            l-2.47-36.41l-23.96,2.27l8.43,51.18c2.16-1.1,4.57-1.78,7.15-1.89l3.87,0.52l3.75,31.78l1.09,3.14l-7.16-0.37
            c-0.27,0.01-0.53,0.02-0.79,0.02c-0.85,0-1.67-0.08-2.49-0.2l9.33,56.66l85.81-3.6l-3.1-59.89c-1.71,1.29-0.35,1.98-2.48,2.58
            l-1.6-34.13c2.11,0.38,0.79,1.4,2.57,2.47l-2.82-54.56l-10.07,2.24l3.39,49.83l2.38,34.96l1.12,16.47
            c1.26,18.6-12.79,34.7-31.39,35.96C291.44,469.16,290.66,469.19,289.89,469.19 M517.5,354.78l13.57-4.39l-20.41-14.43l8.48,10.45
            L517.5,354.78 M429.96,348.06l-3.12,16.45l0,0C427.87,359.02,428.9,353.54,429.96,348.06 M476.82,425.28v56.52
            c0,2.64-2.04,24.88-2.57,27.37H373.68c-3.58-8.62-2.82-23.52-2.82-26.16l-6.29-49.22l49.58-3.29c0-0.02,0.01-0.04,0.01-0.07
            C415.13,429.65,446.28,431.26,476.82,425.28 M414.16,430.43l-0.01,0.07C414.13,430.47,414.14,430.45,414.16,430.43 M452.27,242.73
            h-82.43c-3.12,0-6.24,0.34-9.29,1.02l-88.76,19.75c-9.97,2.22-18.84,7.95-24.95,16.13c-6.12,8.18-9.11,18.3-8.41,28.5l0.08,1.23
            l-24.3,2.3c-4.7,0.45-9.05,2.85-11.93,6.58c-2.88,3.74-4.1,8.56-3.33,13.22l25.76,156.48c1.37,8.3,8.46,14.32,16.87,14.32
            c0.22,0,0.44,0,0.66-0.01l0.03,0l0.03,0l34.55-1.45l1.15,18.87c0.55,9.01,8.04,16.06,17.06,16.06c0.33,0,0.66-0.01,0.99-0.03
            l0.03,0l0.03,0c9.41-0.58,16.6-8.7,16.03-18.11l-1.11-18.22l32.42-1.36c4.53-0.19,8.88-2.22,11.93-5.58
            c3.05-3.35,4.67-7.87,4.43-12.39l-1.09-20.97l3.13,24.47c0,0.16-0.01,0.34-0.01,0.55c-0.17,8.2,0.01,20.09,3.53,28.55l2.3,5.55
            h6.01h100.58h7.3l1.51-7.14c0.69-3.27,2.76-26.05,2.76-29.23v-56.52v-10.94l-10.73,2.1c-18.86,3.7-38.21,4.39-49.85,4.61
            l2.67-13.17l-0.11,0.09c1.92-9.96,3.78-19.92,5.61-29.69c0.76-4.03,1.51-8.07,2.27-12.1h0l3.12-16.45
            c4.75-24.73,10.65-53.85,18.29-82.41c1.6-5.97,4.14-5.97,5.22-5.97c0.21,0,0.44,0.01,0.67,0.03c8.07,0.71,13.27,3.13,15.46,7.17
            c8.29,15.33,26.19,79.05,40.18,133.57l0.29,1.15l2.21,8.53l8.58-2.03l32.97-7.79c15.86-3.75,28.04-15.93,31.78-31.79
            c3.74-15.86-1.71-32.2-14.23-42.64l-89.79-74.9c-7.67-6.39-17.38-9.91-27.36-9.91c-1.79,0-3.6,0.11-5.38,0.34
            C455.88,242.84,454.06,242.73,452.27,242.73L452.27,242.73z M235.67,343.99l5.16-0.49l1.85,27.19l0.04,0.61l0.35,5.16L242,376.3
            l-0.79-0.11l-0.23,0.01L235.67,343.99L235.67,343.99z M256,467.44l-1.29-7.82c1.92,2.79,4.17,5.34,6.68,7.6L256,467.44L256,467.44
            z M321.08,464.71c0.36-0.38,0.71-0.77,1.06-1.17c0.85-0.98,1.66-1.99,2.41-3.02l0.21,4.04L321.08,464.71L321.08,464.71z"/>
        </g>
      </g>
      <polygon style={{ fill: combined }} points="487.27,411.96 522.89,401.78 520.87,414.05 484.58,423.03 " />
      <g>
        <path style={{ fill: combined }} d="M363.59,436.3c-13.33-5.15-21.92-14.51-21.97-33.1c-0.05-19.79,7.64-28.49,7.83-28.98l7.76,2.06
          c-0.06,0.15-2.05,6.59-3.48,15.01c-2.75,16.17-3.43,39.64,15.61,39.93L363.59,436.3z"/>
      </g>
      <polygon style={{ fill: combined }} points="317,335.96 315.17,306.9 317.06,310.59 326.79,329.64 " />
      <path style={{ fill: combined }} d="M342.83,326.07c0,0-0.42-11.09-4.61-12.29c-4.19-1.2-20.35,0-20.35,0l-2.69-6.88c0,0,26.94-3.89,31.13,0
        c4.19,3.89,10.46,65.83,10.46,65.83l-5.07-8.06L342.83,326.07z"/>
      <path style={{ fill: combined }} d="M366.83,374.66c0,0-9.61,0.47-13.1,16.63c-3.49,16.17-5.79-0.82-5.79-0.82l6.55-14.91L366.83,374.66z" />
      <path style={{ fill: combined }} d="M370.93,428.67c0,0-9.12,3.68-15.44-5.64s-4.32,3.18-4.32,3.18s4.62,9.33,6.22,8.69
        S370.93,428.67,370.93,428.67z"/>
      <polyline style={{ fill: combined }} points="448.11,266.1 453.46,248.9 463.33,247.68 479.27,249.51 488.57,268.9 477.94,262.01 467.87,257.03 
        461.23,260.61 456.2,265.5 "/>
    </svg>

  )
}

export default BodilyInjury