import React from 'react'
import { withRouter } from 'react-router-dom'
import vinValidator from 'vin-validator';
import CircularProgress from '@material-ui/core/CircularProgress'
import AgencyContext from '../../../../context/agency-context'
import Button from '../../../../components/button/button'
import RadioInput from '../../../../components/radioInput/radioInput'
import InfoButton from '../../../../components/infoButton/infoButton'
import BasicInput from '../../../../components/basicInput/basicInput'
import LocationInput from '../../../../components/locationInput/locationInput'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { updateNew, vin } from '../../../../helpers/endpoints'
import LoadingModal from '../../../../components/modal/modal'
import ErrorModal from '../../../../components/modal/error/errorModal'
import { registerLeasingPage } from '../../../../helpers/analytics'
import './buyInfo.scss'
class Leasing extends React.Component {
    state = {
        isError: [false, false, false],
        selectedVehicleLeasing: {
            '@id': null,
        },
        isLoading: false,
        type: null,
        company: '',
        address: '',
        zipCode: '',
        state: '',
        city: '',
        isPrevius: false,
        selectedItem: 0,
        vehiclesList: [],
        vinNumber: '',
        vinErrorMsg: '',
        fetchingVinInfo: false,
        vinfetchError: ''
    }

    static contextType = AgencyContext

    UNSAFE_componentWillMount = async () => {

        const vehicles = this.props.getters.general.getVehicles()
        this.setState({
            vehiclesList: vehicles,
        })
    }

    componentDidMount = async () => {
        const vehicle = this.props.getters.general.getVehicles()[0]
        this.setInfoVehicle(vehicle)
        window.scrollTo(0, 0)
    }

    setInfoVehicle = (vehicle) => {
        this.setState({
            isError: [false, false, false],
            selectedVehicleLeasing: vehicle,
            type:
                vehicle.payments !== undefined && vehicle.payments !== null && vehicle.payments !== ''
                    ? vehicle.payments
                    : null,
            company:
                vehicle.AdditionalInterest !== undefined && vehicle.AdditionalInterest[0].GeneralPartyInfo.NameInfo.CommlName.CommercialName !== undefined &&
                    vehicle.AdditionalInterest[0].GeneralPartyInfo.NameInfo.CommlName.CommercialName !== null
                    ? vehicle.AdditionalInterest[0].GeneralPartyInfo.NameInfo.CommlName.CommercialName
                    : '',
            address:
                vehicle.AdditionalInterest !== undefined && vehicle.AdditionalInterest[0].GeneralPartyInfo.Addr.Addr1 !== undefined &&
                    vehicle.AdditionalInterest[0].GeneralPartyInfo.Addr.Addr1 !== null
                    ? vehicle.AdditionalInterest[0].GeneralPartyInfo.Addr.Addr1
                    : '',
            vinNumber: vehicle.VehIdentificationNumber,
            fetchingVinInfo: false
        })
        this.props.setters.vehicle.VehIdentificationNumber(vehicle['@id'], '')
    }

    forwardVehicleCarousel = (vehicle, vehicles) => {
        if (vehicle['@id'] === vehicles[0]['@id']) {
            this.props.history.push(`/${this.context.agencyName}/coverages`)
        } else {
            const index = vehicles.findIndex((x) => x['@id'] === vehicle['@id'])
            const previousVehicle = vehicles[index - 1]
            this.setInfoVehicle(previousVehicle)
            this.setState({ selectedItem: index - 1 })
        }
    }

    nextVehicleCarousel = async (vehicle, vehicles) => {
        const { getters, setters, values, history } = this.props
        const fromBridge = getters.general.ComesFromQuotingBridge()
        const finishVehicle = vehicles[vehicles.length - 1]
        if (vehicle['@id'] === finishVehicle['@id'] && this.state.vinNumber) {
            this.setState({
                isLoading: true,
                fetchingVinInfo: false
            })
            const auth = getters.general.Auth()

            try {

                this.props.runMVR()
                const updateResponse = await updateNew(values.quoteInfo.request, auth)
                if (fromBridge) {
                    const quoteInfo = updateResponse.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.QuoteInfo
                    const persPolicyNode = Object.assign({}, updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy, { QuoteInfo: quoteInfo })
                    updateResponse.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy = persPolicyNode
                    setters.general.setQuoteCoverage([updateResponse])
                }

                setters.general.quoteInfo(updateResponse)

                this.setState({
                    isLoading: false,
                })
                registerLeasingPage()
                history.push(`/${this.context.agencyName}/review`)

            }
            catch (error) {
                this.setState({
                    isLoading: false,
                    updateError: error.message
                })
            }
        }
        else {
            const index = vehicles.findIndex((x) => x['@id'] === vehicle['@id'])
            const nextVehicle = vehicles[index + 1]
            this.setInfoVehicle(nextVehicle)
            this.setState({ selectedItem: index + 1 })
        }
    }

    saveDataFromVehicle = async (vehicle, vehicles) => {
        let errors = this.state.isError
        errors[0] = this.state.type === null
        errors[1] = this.state.type === 'Yes' && this.state.company === ''
        errors[2] = this.state.type === 'Yes' && this.state.address === ''
        errors[3] = false

        const validVin = vinValidator.validate(this.state.vinNumber)

        let vinErrorMsg = ''
        if (!this.state.vinNumber || !validVin) {
            vinErrorMsg = 'Please enter a valid VIN number'
            errors[3] = true
        }

        if (errors.some((error) => error)) {
            this.setState({ isError: errors, vinErrorMsg })
            return
        }

        await this.fetchVinInformation(this.state.vinNumber, vehicle['@id'])

        this.props.setters.vehicle.infoBuy(
            vehicle['@id'],
            this.state.type === 'Yes',
            this.state.company,
            this.state.address,
            this.state.zipCode,
            this.state.state,
            this.state.city
        )

        if (!this.state.fethingVinInfo && !this.state.vinfetchError) {
            this.nextVehicleCarousel(vehicle, vehicles)
        }
    }

    fetchVinInformation = async (vinNumber, vehicleId) => {
        try {
            this.setState({ fetchingVinInfo: true })
            const auth = this.props.getters.general.Auth()
            const vinInformation = await vin(vinNumber, auth)
            if (!vinInformation) {
                this.setState({ vinErrorMsg: 'VIN number not found' })
            } else {
                this.props.setters.vehicle.VehIdentificationNumber(vehicleId, vinNumber)
                this.props.setters.vehicle.PurchaseDt(vehicleId, new Date(vinInformation.year, '00', '01'))
                this.props.setters.vehicle.ModelYear(vehicleId, vinInformation.year);
                this.props.setters.vehicle.Manufacturer(vehicleId, vinInformation.make);
                this.props.setters.vehicle.Model(vehicleId, vinInformation.model);
            }
        } catch (error) {
            this.setState({ fetchingVinInfo: false, vinfetchError: 'Something went wrong' })
            return false
        }

    }

    getRenderButtonsRouter = (vehicle, vehicles) => {
        const { getters } = this.props
        const comesFromBridge = getters.general.ComesFromQuotingBridge()
        const finishVehicle = vehicles[vehicles.length - 1]
        return (
            <div className="container-buttons-router" style={{ marginBottom: '35px' }}>
                <div className="container-buttons-router-left">
                    {(!comesFromBridge || vehicles.length > 1) && <Button
                        width="170px"
                        onClick={() => {
                            this.forwardVehicleCarousel(vehicle, vehicles)
                        }}
                        buttonText={vehicle['@id'] === vehicles[0]['@id'] ? 'Back' : 'Previous  Vehicle'}></Button>}
                </div>
                <div className="container-buttons-router-right">
                    <Button
                        width="170px"
                        onClick={() => {
                            this.saveDataFromVehicle(vehicle, vehicles)
                        }}
                        buttonText={vehicle['@id'] === finishVehicle['@id'] ? 'Continue' : 'Next Vehicle'}></Button>
                </div>
            </div>
        )
    }

    selectZip = (zip) => {
        this.setState({
            zipcode: zip,
        })
    }

    selectAddress = (address) => {
        this.setState({
            address: address,
        })
    }

    selectState = (state) => {
        this.setState({
            state: state,
        })
    }

    selectCity = (city) => {
        this.setState({
            city: city,
        })
    }

    getRenderVehiclesLeasing = () => {
        const vehicles = this.state.vehiclesList
        const { updateError } = this.state

        let result = vehicles.map((vehicle, index) => (
            <div className={'vehicles-leasing-section'} key={`${index}`}>
                <div className="vehicles-leasing-title ">
                    Please enter information about your: <br></br>
                    {vehicle.ModelYear} {vehicle.Manufacturer} {vehicle.Model}
                </div>
                <div className={'leasing-form-block'}>
                    <RadioInput
                        valueFront={this.state.type}
                        onChange={(val) => {
                            this.setState({
                                type: val,
                            })
                        }}
                        inLine={true}
                        label="Do you make payments on this vehicle?"
                        defaultValue={this.state.type}
                        isError={this.state.isError[0]}
                        errorMessage={'Please enter if you make payments on this vehicle'}>
                        <InfoButton
                            title="Leasing Information"
                            text={
                                'If you finance or lease your vehicle, you make continued payments on your vehicle.'
                            }></InfoButton>
                    </RadioInput>
                    <div className="vin-number-input">
                        <BasicInput
                            valueFront={this.state.vinNumber}
                            label={'Vin Number'}
                            defaultValue={this.state.vinNumber}
                            onChange={(val) => {
                                this.setState({
                                    vinNumber: val,
                                })
                            }}
                            isError={this.state.isError[3]}
                            errorMessage={this.state.vinErrorMsg} />
                    </div>
                    <React.Fragment>
                        <div
                            className={
                                this.state.type === 'No' || this.state.type === null
                                    ? 'leasing-input--hidden'
                                    : 'leasing-input'
                            }>
                            <BasicInput
                                valueFront={this.state.company}
                                label={'Leasing/Financing Company'}
                                defaultValue={this.state.company}
                                onChange={(val) => {
                                    this.setState({
                                        company: val,
                                    })
                                }}
                                isError={this.state.isError[1]}
                                errorMessage={'Please enter a valid company'}></BasicInput>

                            <LocationInput
                                valueFront={this.state.address}
                                selectCity={this.selectCity}
                                selectZip={this.selectZip}
                                selectState={this.selectState}
                                selectAddress={this.selectAddress}
                                label={'Company Address'}
                                errorMessage={
                                    this.state.isError[2] ? 'Please enter a valid address' : ''
                                }></LocationInput>
                        </div>
                    </React.Fragment>
                    <div className="loding-vininfo">
                        {this.state.fetchingVinInfo && <CircularProgress />}
                    </div>
                    {this.getRenderButtonsRouter(vehicle, vehicles)}
                    <LoadingModal isOpen={this.state.isLoading} headline={"Updating Your Quote"} ></LoadingModal>
                    <ErrorModal errorText={`An error has occurred with updating your quote. Please call us at: `} isOpen={updateError !== undefined} setter={() => { this.setState({ updateError: undefined }) }} headline="An Error Occurred">
                        <span className='error-child'>
                            <a className='error-link' href={`tel:${this.context.agencyInfo.phone_number}`}>{this.context.agencyInfo.phone_number}</a>
                            <div className='error-modal-text'> and use {this.props.getters.policy.PolicyNumber()} as your reference number. <br></br></div>
                        </span>
                        {this.state.updateError !== 'Internal Server Error' && <div className='error-modal-status'>{this.state.updateError}</div>}
                    </ErrorModal>
                </div>
            </div>
        ))
        return result
    }

    render() {
        return (
            <Carousel
                selectedItem={this.state.selectedItem}
                swipeable={false}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}>
                {this.getRenderVehiclesLeasing()}
                {this.state.vehiclesList.length === 1 && this.getRenderVehiclesLeasing()}
            </Carousel>
        )
    }
}

export default withRouter(Leasing)
