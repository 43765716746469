import React from 'react'
import { withRouter } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import PersonIcon from '@material-ui/icons/Person'
import AgencyContext from '../../../context/agency-context'
import Button from '../../../components/button/button'
import AddButton from '../../../components/addButton/addButton'
import { getAge } from '../../../helpers/dateHelpers'
import { submitNew, updateNew, save } from '../../../helpers/endpoints'
import './displayDriver.scss'
import LoadingModal from '../../../components/modal/modal'
import ErrorModal from '../../../components/modal/error/errorModal'
import { registerDriversPage } from '../../../helpers/analytics'

class DisplayDriver extends React.Component {
    
    state = {
        drivers: undefined,
        fetchingQuotes: false,
    }
    static contextType = AgencyContext

    componentDidMount = async () => {

        const { values } = this.props

        if (!this.state.drivers) {
            this.setDrivers()
        }

        const auth = this.props.getters.general.Auth()

        save(values.quoteInfo, this.context.agencyName, auth, this.context.uuid, this.props.nextStep({
            stepId: 'Driver Info',
            stepNum: 4
        }), this.props.getters.general.CustomerID())
    }

    setDrivers = async () => {
        let drivers = await this.props.values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq
            .PersAutoLineBusiness.PersDriver

        for (var i in drivers) {
            //check for invalid drivers
            if (
                (drivers[i].GeneralPartyInfo.NameInfo.PersonName.GivenName === '' &&
                    drivers[i].GeneralPartyInfo.NameInfo.PersonName.Surname === '') ||
                (drivers[i].DriverInfo.License.LicensePermitNumber === '' &&
                    drivers[i].DriverInfo.License.StateProvCd === '' &&
                    drivers[i].DriverInfo.License.LicenseStatusCd === '')
            ) {
                await this.props.removeDriver(drivers[i]['@id'])
            }
        }

        this.setState({
            drivers: this.props.values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq
                .PersAutoLineBusiness.PersDriver,
        })
    }

    editDriver = (id) => {
        this.props.setters.general.SelectedDriver(id)
        this.props.history.push(`/${this.context.agencyName}/drivers/name`)
    }

    removeDriver = async (id) => {
        await this.props.removeDriver(id)
        this.setDrivers()
    }

    addAnotherDriver = async () => {
        const { addNewDriver, setters, history } = this.props
        const { drivers } = this.state

        await addNewDriver()
        const driversLength = drivers.length
        setters.general.SelectedDriver(`DRV${driversLength}`)
        history.push(`/${this.context.agencyName}/drivers/name`)
    }

    proceed = async () => {
        const { history, setters, getters } = this.props
        const { agencyName } = this.context


        if (getters.general.ComesFromQuotingBridge()) {
            registerDriversPage()
            history.push(`/${agencyName}/coverages`)
        }
        else {
            const quotes = await this.getCoveragesOptions()
            if (quotes) {
                setters.general.setQuoteCoverage(quotes)
                setters.general.EditFromReviewScreen(false)
                registerDriversPage()
                this.setState({
                    fetchingQuotes: false
                })
                history.push(`/${agencyName}/coverages`)
            }
        }
    }

    getCoveragesOptions = async () => {
        const { getters, values } = this.props
        try {
            this.setState({ fetchingQuotes: true })
            let quoteReq = values.quoteInfo.request
            const token = this.props.getters.general.Auth()
            const comesFromReview = getters.general.editFromReviewScreen()

            let quoteArray;

            if (comesFromReview) {
                const minimumReq = this.updateAcordObject('minimum')
                const recommendedReq = this.updateAcordObject('recommended')
                const premiumReq = this.updateAcordObject('premium')
                quoteArray = await Promise.all([
                    updateNew(minimumReq, token),
                    updateNew(recommendedReq, token),
                    updateNew(premiumReq, token),
                ])
            } else {
                quoteArray = await Promise.all([
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['minimum']),
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['recommended']),
                    submitNew(quoteReq, token, this.props.getters.general.Coverages()['premium']),
                ])
            }

            return quoteArray
        } catch (error) {
            this.setState({
                fetchingQuotes: false,
                submitError: error.message
            })
        }
    }

    updateAcordObject = (type) => {
        const { getters } = this.props
        const quoteInfo = getters.general.quoteInfo()
        const quotesCoverages = getters.general.quoteCoverage()
        const coverage = getters.general.Coverages()[type]
        switch (type) {
            case 'minimum': {
                const minimumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const minimumPolicyNumber =
                    quotesCoverages[0].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = minimumPolicyNumber
                const vehicles = minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })
                minimumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles

                return minimumRq
            }
            case 'recommended': {
                const recommendedRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const recommendedPolicyNumber =
                    quotesCoverages[1].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = recommendedPolicyNumber
                const vehicles =
                    recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })

                recommendedRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return recommendedRq
            }
            case 'premium': {
                const premiumRq = JSON.parse(JSON.stringify(quoteInfo.request))
                const premiumPolicyNumber =
                    quotesCoverages[2].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = premiumPolicyNumber
                const vehicles = premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh
                vehicles.forEach((vehicle) => {
                    vehicle.Coverage = coverage
                })

                premiumRq.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
                return premiumRq
            }
            default:
                break
        }
    }

    componentWillUnmount = () => {
        this.setState({ fetchingQuotes: false })
    }

    goBack = (evt) => {
        const { history, getters } = this.props
        const { agencyName } = this.context
        const comesFromReview = getters.general.editFromReviewScreen()
        const path = comesFromReview ? 'review' : 'policy/name'
        history.push(`/${agencyName}/${path}`)
    }

    render = () => {
        const { fetchingQuotes, submitError } = this.state

        return (
            <div className="display-driver">
                <div className="quote-headline-two" style={{ fontSize: '36px' }}>
                    Drivers
                </div>
                {this.state.drivers && (
                    <div className="display-driver-section">
                        {this.state.drivers.map(
                            (driver, id) =>
                                driver.DriverInfo && (
                                    <div className="driver-display-tab" key={id}>
                                        <div className="driver-title">
                                            <div className="driver-title-section">
                                                <div className="driver-image">
                                                    <PersonIcon primary="#a79e9e" />
                                                </div>
                                                <div className="driver-text-section">
                                                    <div className="driver-title-text">
                                                        {driver.GeneralPartyInfo.NameInfo.PersonName.GivenName}{' '}
                                                        {driver.GeneralPartyInfo.NameInfo.PersonName.Surname}
                                                    </div>
                                                    <div
                                                        className="driver-age"
                                                        style={{
                                                            color:
                                                                driver.DriverInfo.License.LicensePermitNumber ===
                                                                'EXCLUDED' && '#c4c4c4',
                                                        }}>
                                                        {driver.DriverInfo.License.LicensePermitNumber === 'EXCLUDED'
                                                            ? 'Excluded'
                                                            : `${getAge(
                                                                driver.DriverInfo.PersonInfo.BirthDt,
                                                                String(new Date())
                                                            )
                                                            } Years Old`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="driver-buttons">
                                            <div
                                                className="driver-button"
                                                onClick={() => {
                                                    this.editDriver(driver['@id'])
                                                }}
                                                style={{ color: '#008EDE', borderRight: '1px solid #eeeeee' }}>
                                                <EditIcon /> <span style={{ marginLeft: '10px' }}>Edit</span>
                                            </div>
                                            <div
                                                className="driver-button"
                                                onClick={() => {
                                                    driver['@id'] !== 'DRV1' && this.removeDriver(driver['@id'])
                                                }}
                                                style={{
                                                    color: driver['@id'] === 'DRV1' ? '#eeeeee' : '#008EDE',
                                                    cursor: driver['@id'] === 'DRV1' && 'unset',
                                                }}>
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                        <AddButton label="Add Another Driver" onClick={this.addAnotherDriver} />
                        <div className="buttons-row">
                            <Button width="120px" align="center" onClick={this.goBack} buttonText={'Back'} />
                            <Button
                                buttonText={fetchingQuotes ? 'Loading Your Quote' : 'Save and Continue'}
                                onClick={this.proceed}
                                align="center"
                                width="250px"
                                disabled={fetchingQuotes}
                            />
                        </div>
                        <LoadingModal isOpen={fetchingQuotes} headline="Loading your quote"></LoadingModal>
                        <ErrorModal errorText={`An error has occurred with submitting your quote. Please call us at: `} isOpen={submitError !== undefined} setter={() => { this.setState({ submitError: undefined }) }} headline="An Error Occurred">
                            <span className='error-child'>
                                <a className='error-link' href={`tel:${this.context.agencyInfo.phone_number}`}>{this.context.agencyInfo.phone_number}</a>
                            </span>
                            {this.state.submitError !== 'Internal Server Error' && <div className='error-modal-status'>{this.state.submitError}</div>}
                        </ErrorModal>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(DisplayDriver)
