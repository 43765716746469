import React, { Component } from 'react'
import { save } from '../../helpers/endpoints'
import { ReactComponent as SuccessImg } from '../../assets/general/success.svg';
//import BasicInput from '../../components/basicInput/basicInput'
import AgencyContext from '../../context/agency-context'
import Button from '../../components/button/button'
import './success.scss'
import { registerIssuance } from '../../helpers/analytics';
import  { veloxWebhook, signedNBDocs } from '../../helpers/endpoints';
import { saveAs } from 'file-saver';
import LoadingModal from '../../components/modal/modal'
import ErrorModal from '../../components/modal/error/errorModal'


class Success extends Component {
  state = {
    isError: false,
    password: '',
    documentPrinted: false,
    isLoading: false,
    downloadError: false
  }

  static contextType = AgencyContext

  handleChangePassword = (val) => {
    this.setState({ password: val })
  }

  handleCreateAccount = evt => {
    const { password } = this.state
    if (!password) {
      this.setState({ isError: true })
    }
    // TODO: Add call to endpoint to create account (endpoint not created yet)
  }

  handleDownloadDocuments = async () => {
    const { getters } = this.props

    this.setState({
      isLoading: true
    })
    const auth = this.props.getters.general.Auth()

    try {
      const documents = await signedNBDocs( auth, getters.policy.PolicyNumber())

      const byteCharacters = atob(documents)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      var blob = new Blob([byteArray], {type: "application/pdf"})
      saveAs(blob, 'PolicyDocuments.pdf')
    } catch (err) {
      console.log(err)
      this.setState({ downloadError: true })
    }

    this.setState({
      isLoading: false
    })
  }


  componentDidMount() {
    const { values, getters } = this.props

    registerIssuance(getters.policy.PolicyNumber())

    const auth = this.props.getters.general.Auth()

    veloxWebhook( auth, values.quoteInfo,this.context.agencyName)

    save(values.quoteInfo, this.context.agencyName, auth, this.context.uuid, this.props.nextStep({
        stepId: 'Issued Policy',
        stepNum: 6
    }), this.props.getters.general.CustomerID())
  }

  render() {
    //const { password, isError } = this.state
    const { getters } = this.props
    const { agencyInfo: { agency_name, phone_number } } = this.context
    const policyNumber = getters.policy.PolicyNumber()
    const payment = getters.policy.Payment()
    return (
      <div className="success-section">
        <LoadingModal
          fontSize="18px"
          isOpen={ this.state.isLoading }
          headline={'Downloading your documents'}>
        </LoadingModal>
        <ErrorModal
          errorText={`An error has occurred when downloading your policy documents. Please try again.`}
          isOpen={ this.state.downloadError}
          setter={ () => { this.setState({ downloadError: false })} }
          headline="An Error Occurred">
        </ErrorModal>
        <div className="header-section">
          <div className="header">
            <SuccessImg className="icon" fill="#56b24d" />
            <div className="header-label">Success</div>
          </div>
        </div>
        <div className="success-content">
          <p className="lorem">
            {`Thank you for choosing ${agency_name}.`}
          </p>
          <p className='lorem-text'>
            We received your payment of <b>{`$${payment.DepositAmt.Amt}`}</b>.
            If you have any questions in regards to your policy,
            please call us at <a href={`tel:${phone_number}`}>{`${phone_number}.`}</a> Please
            check your email for your receipt and your policy documents.
          </p>
          <div className="payment-info">
            <div className="payment-info-section">
              <div className="label-title">Policy Number</div>
              <div className="content-label">{policyNumber}</div>
            </div>
            <div className="payment-info-section">
              <div className="label-title">PAYMENT AMOUNT</div>
              <div className="content-label">{`$${payment.DepositAmt.Amt}`}</div>
            </div>
          </div>
          {/* <div className="account">
            <div>
              <div className="content-label" onClick={this.handleCreateAccount}>Create an Account</div>
              <div className='password-label'>Enter a password</div>
            </div>
          </div>
          <div className='account-section'>
            <BasicInput
              type="password"
              label="Password"
              defaultValue={password}
              onChange={this.handleChangePassword}
              isError={isError}
              errorMessage={'Please enter a valid password'}
            />
          </div>
          */<div className="buttons-row">
              <Button
                onClick={this.handleDownloadDocuments}
                buttonText={'Download Your Policy Documents'}
                width="320px"
              />
            </div>
          }
        </div>
      </div >
    )
  }
}

export default Success