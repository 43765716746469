
export const convertShortDate = date => { // date will always remain in this format, due to react component

    if (!date) {
        return null;
    }

    var dateArr = date.split('/');
    if (dateArr.length === 1) {

        dateArr = date.split('-')

        if (dateArr.length < 3) {
            return null;
        }

        const month = dateArr[1] - 1;
        const day = dateArr[2];
        const year = dateArr[0];

        return new Date(year, month, day, 0, 0, 0, 0);

    }
    else {
        if (dateArr.length < 3) {
            return null;
        }


        const month = dateArr[0] - 1;
        const day = dateArr[1];
        const year = dateArr[2];

        return new Date(year, month, day, 0, 0, 0, 0);
    }
}

export const formatDate = (date) => {
    if (!date) {
        return ''
    }

    let month = ('0' + (date.getUTCMonth() + 1)).slice(-2)
    let day = ('0' + date.getUTCDate()).slice(-2)
    let year = date.getFullYear()

    return month + '/' + day + '/' + year
}

export const formatDateString = (dateString) => {
    if (dateString === '' || dateString === undefined) {
        return ''
    }

    let year = dateString.split('-')[0]
    let month = dateString.split('-')[1]
    let day = dateString.split('-')[2]
    return month + '/' + day + '/' + year
}

const yearDifference = (d1, d2) => {
    let date1 = new Date(d1)
    let date2 = new Date(d2)
    let difference = date2.getUTCFullYear() - date1.getUTCFullYear()
    return difference
}

export const getAge = (d1, d2) => {
    let date1 = new Date(d1)
    let date2 = new Date(d2)
    let years = yearDifference(d1, d2)
    const isMonthOlder = (date2.getUTCMonth() + 1 - date1.getUTCMonth() + 1) >= 0
    const isDayOlder = (date2.getUTCDate() - date1.getUTCDate()) >= 0 || (date2.getUTCMonth() + 1 - date1.getUTCMonth() + 1) > 0
    years -= (isMonthOlder && isDayOlder) ? 0 : 1
    return years;
}

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

export const diamondDate = (date) => {
    if (!isValidDate(date)) {
        return ''
    }

    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
}

export const addMonthsToDate = (date, numMonths) => {
    numMonths = Number(numMonths)
    return new Date(date.setMonth(date.getMonth() + numMonths))
}

export const getWeekCount = () => {

    // eslint-disable-next-line
    Date.prototype.getWeek = function () {

        var date = new Date(this.getTime());
        date.setHours(0, 0, 0, 0);

        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        var week1 = new Date(date.getFullYear(), 0, 4);

        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    return new Date().getWeek()
}

export const addDaysTodate = (date, numDays) => {
    numDays = Number(numDays)
    return new Date(date.setDate(date.getDate() + numDays))
}
