import mixpanel from 'mixpanel-browser'
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel'
import { getAge } from '../helpers/dateHelpers'

export const registerName = (context, firstName, lastName, email, birthDate, isBridge = false) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            age: `${Math.round(getAge(birthDate, String(new Date())))}`,
            USER_ID: context.uuid,
            agencyCode: context.agencyId
        }

        mixpanel.track(`${isBridge ? 'Bridge ' : ''}Lead Created`, dataLayer)

        mixpanel.people.set(dataLayer)
        ReactPixel.track(`${isBridge ? 'Bridge ' : ''}Lead Created`, dataLayer)

        dataLayer.event = `${isBridge ? 'Bridge ' : ''}Lead Created`

        const tagManagerArgs = {
            gtmId: "GTM-5RRCBPR",
            dataLayer: dataLayer
        }

        TagManager.initialize(tagManagerArgs)

    }
}

export const registerAddress = (zipCode) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            zipCode: zipCode
        }

        mixpanel.track("Address Entered", dataLayer)
        mixpanel.people.set(dataLayer)

        ReactPixel.track('Address Entered', dataLayer)

        dataLayer.event = 'Address Entered'
        window.dataLayer.push(dataLayer)
    }
}

export const registerAddVehicle = (year, make, model) => {

    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            year: year,
            make: make,
            model: model
        }

        mixpanel.track("Vehicle Added", dataLayer)

        ReactPixel.track('Vehicle Added', dataLayer)

        dataLayer.event = 'Vehicle Added'
        window.dataLayer.push(dataLayer)

    }
}

export const registerVehiclesPage = () => {
    if (process.env.REACT_APP_ENV === 'production') {

        mixpanel.track("Vehicles Page", {})

        ReactPixel.track('Vehicles Page', {})

        window.dataLayer.push({
            event: 'Vehicles Page'
        })

    }
}

export const registerPolicyInfoPage = (gender, maritalStatus) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const genderLabel = gender === 'M' ? 'Male' : 'Female'

        const maritalLabel = () => {
            if (maritalStatus === 'S') {
                return 'Single'
            }
            else if (maritalStatus === 'W') {
                return 'Widowed'
            }
            else if (maritalStatus === 'D') {
                return 'Divorced'
            }
            else {
                return 'Married'
            }
        }

        const dataLayer = {
            gender: genderLabel,
            maritalStatus: maritalLabel(),
        }

        mixpanel.track("Policy Info Added", dataLayer)
        mixpanel.people.set(dataLayer)

        ReactPixel.track('Policy Info Added', dataLayer)
        dataLayer.event = 'Policy Info Added'

        window.dataLayer.push(dataLayer)


    }
}

export const registerPolicyHome = (homeInfo) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            homeInfo: homeInfo
        }

        mixpanel.track("Home Info Added", dataLayer)
        mixpanel.people.set(dataLayer)

        ReactPixel.track('Home Info Added', dataLayer)
        dataLayer.event = 'Home Info Added'

        window.dataLayer.push(dataLayer)


    }
}

export const registerDriversPage = () => {
    if (process.env.REACT_APP_ENV === 'production') { 
        mixpanel.track("Drivers Page Reached", {})

        window.dataLayer.push({
            event: 'Drivers Page Reached'
        })

        ReactPixel.track('Drivers Page Reached', {})
    }
}

export const registerRateCallOne= (quoteNumber) => {

    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            policyNumber: quoteNumber
        }

        mixpanel.track("Rate Call One", dataLayer);
        mixpanel.people.set(dataLayer)

        ReactPixel.track('Rate Call One', dataLayer)
        dataLayer.event = 'Rate Call One'
        
        window.dataLayer.push(dataLayer)

    }
}

export const registerLeasingPage = () => {
    if (process.env.REACT_APP_ENV === 'production') {

        mixpanel.track("Leasing Page Reached", {});

        window.dataLayer.push({
            event: 'Leasing Page Reached',
        })

        ReactPixel.track('Leasing Page Reached', {})

    }
}

export const registerRateCallTwo = (price, phoneNumber) => {

    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            price: price,
            phoneNumber: phoneNumber
        }

        mixpanel.track("Rate Call Two", dataLayer)

        ReactPixel.track('Rate Call Two', dataLayer)
        dataLayer.event = 'Rate Call Two'

        window.dataLayer.push(dataLayer)
    }
}

export const registerDocusign = (bridge = false) => {
    if (process.env.REACT_APP_ENV === 'production') { 
        mixpanel.track(`${bridge ? 'Bridge ' : ''}Docusign Reached`, {})

        window.dataLayer.push({
            event: `${bridge ? 'Bridge ' : ''}Docusign Reached`
        })

        ReactPixel.track(`${bridge ? 'Bridge ' : ''}Docusign Reached`, {})
    }
}

export const registerPayment = (bridge = false) => {
    if (process.env.REACT_APP_ENV === 'production') { 
        mixpanel.track(`${bridge ? 'Bridge ' : ''}Payment Page Reached`, {})

        window.dataLayer.push({
            event: `${bridge ? 'Bridge ' : ''}Payment Page Reached`
        })

        ReactPixel.track(`${bridge ? 'Bridge ' : ''}Payment Page Reached`, {})
    }
}

export const registerIssuance = (finishedPolicy, bridge=false) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            policyNumber: finishedPolicy
        }

        mixpanel.track(`${bridge ? 'Bridge ' : ''}Issued Policy`, dataLayer)
        mixpanel.people.set(dataLayer)

        ReactPixel.track(`${bridge ? 'Bridge ' : ''}Issued Policy`, dataLayer)
        dataLayer.event = 'Issued Policy'

        window.dataLayer.push(dataLayer)

    }

}

export const registerBridgeLoad = (context) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            USER_ID: context.uuid,
            agencyCode: context.agencyId
        }

        mixpanel.track('Bridge Loaded', dataLayer)
        mixpanel.people.set(dataLayer)

        ReactPixel.track('Bridge Loaded', dataLayer)
        dataLayer.event = 'Bridge Loaded'

        window.dataLayer.push(dataLayer)

    }
}

export const registerBridgeRate = (price, quoteNumber) => {
    if (process.env.REACT_APP_ENV === 'production') {

        const dataLayer = {
            price: price,
            policyNumber: quoteNumber
        }

        mixpanel.track("Rated on Bridge", dataLayer)

        mixpanel.people.set({
            policyNumber: dataLayer.policyNumber
        })

        ReactPixel.track('Rated on Bridge', dataLayer)
        dataLayer.event = 'Rated on Bridge'

        window.dataLayer.push(dataLayer)

    }
}