import React from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import MediaQuery from 'react-responsive'
import { CSSTransition } from 'react-transition-group'
import AgencyContext from '../../../context/agency-context'
import CoverageOption from '../../../components/coverageOption/coverageOption'
import Button from '../../../components/button/button'
import themes from '../../../themes'
import './coverages.scss'
import { registerRateCallOne } from '../../../helpers/analytics'
class Coverages extends React.Component {
    state = {
        quotes: [],
        selectedCoverage: 'better',
    }

    static contextType = AgencyContext

    UNSAFE_componentWillMount = async () => {
        this.setState({
            quotes: this.props.getters.general.quoteCoverage(),
        })
    }

    render = () => {
        const { selectedCoverage } = this.state
        const { getters } = this.props

        const fromBridge = getters.general.ComesFromQuotingBridge()

        const quoteBridgeView = (
            <CSSTransition
                in={true}
                key={0}
                appear
                classNames="tab"
                timeout={{ exit: 300, appear: 300 }}
            >
                <div>
                    <CoverageOption
                        coverageTitle=""
                        coveragesList={this.props.getters.general.Coverages()}
                        selected={true}
                        amount={this.getCheapestPaymentPlan('good')()}
                    />
                </div>
            </CSSTransition>
        )

        const view = (
            <MediaQuery maxWidth={800}>
                {(matches) =>
                    matches ? (
                        <>
                            <CSSTransition
                                in={true}
                                key={0}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 300 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Better'}
                                        amount={this.getCheapestPaymentPlan('better')()}
                                        coveragesList={this.props.getters.general.Coverages()['recommended']}
                                        selected={selectedCoverage === 'better'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition
                                in={true}
                                key={1}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 400 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Good'}
                                        amount={this.getCheapestPaymentPlan('good')()}
                                        coveragesList={this.props.getters.general.Coverages()['minimum']}
                                        selected={selectedCoverage === 'good'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition
                                in={true}
                                key={2}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 500 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Best'}
                                        amount={this.getCheapestPaymentPlan('best')()}
                                        coveragesList={this.props.getters.general.Coverages()['premium']}
                                        selected={selectedCoverage === 'best'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                        </>
                    ) : (
                        <>
                            <CSSTransition
                                in={true}
                                key={0}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 300 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Good'}
                                        amount={this.getCheapestPaymentPlan('good')()}
                                        coveragesList={this.props.getters.general.Coverages()['minimum']}
                                        selected={selectedCoverage === 'good'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition
                                in={true}
                                key={1}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 400 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Better'}
                                        amount={this.getCheapestPaymentPlan('better')()}
                                        coveragesList={this.props.getters.general.Coverages()['recommended']}
                                        selected={selectedCoverage === 'better'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition
                                in={true}
                                key={2}
                                appear
                                classNames="tab"
                                timeout={{ exit: 300, appear: 500 }}>
                                <div>
                                    <CoverageOption
                                        coverageTitle={'Best'}
                                        amount={this.getCheapestPaymentPlan('best')()}
                                        coveragesList={this.props.getters.general.Coverages()['premium']}
                                        selected={selectedCoverage === 'best'}
                                        onClick={this.handleSelectCoverage}
                                    />
                                </div>
                            </CSSTransition>
                        </>
                    )
                }
            </MediaQuery>
        )

        return (
            <div className="coverages-section">
                <div className="coverages-title ">We've found these coverage options for you</div>
                <div className={fromBridge ? "coverage-options-single" : "coverage-options"}>
                    {
                        fromBridge ? quoteBridgeView : view
                    }
                </div>
                <div className="button-row">
                    <MediaQuery maxWidth={800}>
                        {(matches) => (
                            <>
                                <Button
                                    buttonText="Back"
                                    textColor={themes.main.mui.palette.primary.main}
                                    color='#ffffff'
                                    width="200px"
                                    align={matches ? 'center' : 'flex-end'}
                                    onClick={this.handleGoBack}
                                />
                                <Button
                                    buttonText="Review your Policy"
                                    textColor={themes.main.mui.palette.primary.main}
                                    color='#ffffff'
                                    width="250px"
                                    align={matches ? 'center' : 'flex-end'}
                                    onClick={this.handleGoToreviewPolicy}
                                />
                            </>
                        )}
                    </MediaQuery>
                </div>
            </div>
        )
    }

    getCheapestPaymentPlan = (planOption) => () => {
        const { quotes } = this.state
        let paymentOptions = []
        switch (planOption) {
            case 'good':
                paymentOptions = get(
                    quotes[0],
                    'response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption',
                    []
                )
                break
            case 'better':
                paymentOptions = get(
                    quotes[1],
                    'response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption',
                    []
                )
                break
            case 'best':
                paymentOptions = get(
                    quotes[2],
                    'response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption',
                    []
                )
                break
            default:
                break
        }

        const filteredPayments = paymentOptions.length > 0 && paymentOptions.filter(({ NumPayments, Description }) => {
            if (NumPayments >= '4') {
                return Description.split(' ').pop() === 'CC'
            } else {
                return NumPayments === '3' || NumPayments === '0'
            }
        })

        const ordered = filteredPayments.length && filteredPayments.sort((a, b) => Number(a.DepositAmt.Amt) - Number(b.DepositAmt.Amt))
        const lowest = get(ordered, '0.DepositAmt.Amt')
        return lowest
    }

    handleGoToreviewPolicy = async (evt) => {
        const { history, getters } = this.props
        const { agencyName } = this.context

        // skip setting a coverage plan in the acord object if comes from quoting bridge url, because the plan will be already added
        const fromBridge = getters.general.ComesFromQuotingBridge()
        if (!fromBridge) {
            await this.setCoveragePlan()
        }


        registerRateCallOne(this.props.getters.policy.PolicyNumber())

        history.push(`/${agencyName}/leasing`)
    }

    setCoveragePlan = async () => {
        const { selectedCoverage, quotes } = this.state
        const { setters } = this.props

        let policyNumber;

        switch (selectedCoverage) {
            case 'good':
                policyNumber = quotes[0].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                quotes[0].request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = policyNumber
                await setters.general.quoteInfo(quotes[0])
                break
            case 'best':
                policyNumber = quotes[2].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                quotes[2].request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = policyNumber
                await setters.general.quoteInfo(quotes[2])
                break
            default:
                policyNumber = quotes[1].response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber
                quotes[1].request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuoteInfo.CompanysQuoteNumber = policyNumber
                await setters.general.quoteInfo(quotes[1])
                break
        }
    }

    handleSelectCoverage = (title) => {
        this.setState({ selectedCoverage: title })
    }

    handleGoBack = (evt) => {
        const { history, getters } = this.props
        const { agencyName } = this.context
        const comesFromReview = getters.general.editFromReviewScreen()
        const path = comesFromReview ? 'review' : 'drivers'
        history.push(`/${agencyName}/${path}`)
    }
}

export default withRouter(Coverages)
