import React from 'react'

const UnSelected = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 497.4 417.1"
            xmlSpace="preserve">
            <g>
                <path
                    className="di-st0"
                    style={{ fill: props.primary }}
                    d="M471.7,0h-324C133.5,0,122,11.5,122,25.7v8.2H25.7C11.5,33.9,0,45.5,0,59.6v257.3c0,14.2,11.5,25.7,25.7,25.7
		h21.6v7.1c0,20.5,16.7,37.2,37.2,37.2h2.4v4.5c0,14.2,11.5,25.7,25.7,25.7l324,0c14.2,0,25.7-11.5,25.7-25.7v-82.7h9.3
		c14.2,0,25.7-11.5,25.7-25.7V25.7C497.4,11.5,485.9,0,471.7,0z M127,38.9h222.7c9.9,0,18.2,7,20.2,16.3l-243,0V38.9z M47.3,92.4
		v245.2H25.7c-11.4,0-20.7-9.3-20.7-20.7V59.6c0-11.4,9.3-20.7,20.7-20.7H122v16.3l-37.4,0C64,55.2,47.3,71.9,47.3,92.4z
		 M61.3,349.7V92.4c0-12.8,10.4-23.2,23.2-23.2l324,0c12.8,0,23.2,10.4,23.2,23.2v257.3c0,12.8-10.4,23.2-23.2,23.2h-324
		C71.8,372.9,61.3,362.5,61.3,349.7z M457.4,391.4c0,11.4-9.3,20.7-20.7,20.7l-324,0c-11.4,0-20.7-9.3-20.7-20.7v-4.5h316.6
		c20.5,0,37.2-16.7,37.2-37.2v-41h11.6V391.4z M457.4,303.7h-11.6V115.6c6.9,3.4,11.6,10.4,11.6,18.6L457.4,303.7z M492.4,283
		c0,11.4-9.3,20.7-20.7,20.7h-9.3l0-169.6c0-11-6.9-20.3-16.6-24V92.4c0-20.5-16.7-37.2-37.2-37.2l-33.5,0
		c-2.1-12.1-12.7-21.3-25.3-21.3H127v-8.2C127,14.3,136.3,5,147.7,5h324c11.4,0,20.7,9.3,20.7,20.7V283z"
                />
                <path
                    className="di-st0"
                    style={{ fill: props.primary }}
                    d="M394.5,176.4H260c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3h134.4c1.7,0,3-1.3,3-3
		C397.5,177.8,396.1,176.4,394.5,176.4z"
                />
                <path
                    className="di-st0"
                    style={{ fill: props.primary }}
                    d="M394.5,227.9H260c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3h134.4c1.7,0,3-1.3,3-3
		C397.5,229.2,396.1,227.9,394.5,227.9z"
                />
                <path
                    className="di-st0"
                    style={{ fill: props.primary }}
                    d="M394.5,275.7H260c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3h134.4c1.7,0,3-1.3,3-3C397.5,277,396.1,275.7,394.5,275.7z
		"
                />
                <circle className="di-st0 " style={{ fill: props.primary }} cx="160.1" cy="191.6" r="39.3" />
                <path
                    className="di-st0"
                    style={{ fill: props.primary }}
                    d="M160.8,236.7c-30.3,0-56.1,19.5-65.4,46.7c16.7,15.7,39.1,25.3,63.8,25.3c25.9,0,49.3-10.5,66.2-27.6
		C215.5,255.2,190.3,236.7,160.8,236.7z"
                />
            </g>
        </svg>
    )
}
export default UnSelected
