import axios from 'axios'

const headers = { 'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY } 
const instance = axios.create({
    headers: {
        common: headers,
    },
})

instance.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default instance
