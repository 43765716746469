import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CollisionCoverageIcon } from '../../../../assets/coverages/collision-coverage.svg'

const CollisionCoverage = ({ color = '#7F1819' }) => {
  return (
    <CollisionCoverageIcon fill={color} />
  )
}

CollisionCoverage.propTypes = {
  color: PropTypes.string
}

export default CollisionCoverage