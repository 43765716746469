import React from 'react';
import MaskedInput from 'react-text-mask'

function maskedInputCustom(props) {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={props.mask}
            guide={false}
        />
    )
}

export default maskedInputCustom;
