import axios from '../axios.js'

export const docusign = async (body, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }
    try {
        const response = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/esign`, body, { headers })
        return response.data
    } catch (error) {
        return {
            error,
        }
    }
}

export const getAuth = async (agencyId) => {
    const { data } = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/agency/${agencyId}/authorize`, null, {
        headers: {
            'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        },
    })
    return data.token
}

export const notify = async (token, policyNumber) => {
    const body = process.env.REACT_APP_ENV === 'production' ? undefined : { to: `${process.env.REACT_APP_TO_EMAIL}` }

    const response = axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/agency/notify/${policyNumber}`, body, {
        headers: {
            'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
            Authorization: token,
        },
    })

    return response.data
}

/* NEW ENDPOINTS FOR V6 QUOTING*/

export const years = async (token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }
    const { data } = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNAPI}/vehicle/years`,
    { headers }
    )
    return data.years
}

export const makes = async (year, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }
    const { data } = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNAPI}/vehicle/makes?year=${year}`,
    { headers }
    )

    return data.makes
}

export const models = async (year, make, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }
    const { data } = await axios.get(
        `${process.env.REACT_APP_APIGATEWAY_PNAPI}/vehicle/makes/${make}/models?year=${year}`,
        { headers }
    )

    return data.models
}

export const vin = async (vinNumber, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const { data } = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNAPI}/vehicle/${vinNumber}`, { headers })
        return data
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const validateZipCode = async (shortCode, zipCode, agencyId) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
    }

    const response = await axios
        .get(
            `${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/zip-code/${zipCode}/validate?agency_code=${agencyId}`,
            { headers }
        )
        .catch((error) => {
            if (error.response) {
                return error.response
            }
        })

    return response.data
}

export const getQuoteByZipCode = async (shortCode, zipCode, agencyId) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
    }

    const response = await axios.get(
        `${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/zip-code/${zipCode}?agency_code=${agencyId}`,
        { headers }
    )
    const licenseList = response.data['license_statuses']
    const newList =  licenseList.map((value, index)=>{
        return (value === 'Matricula ID'? 'Matricula': value)
    })
    response.data['license_statuses'] = newList
    return response.data
}

export const submitNew = async (acord, token, coverage) => {
    let vehicles = acord.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh

    for (var i in vehicles) {
        vehicles[i].Coverage = coverage
    }

    acord.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles
    const body = JSON.parse(JSON.stringify(acord))

    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const response = await axios.put(`${process.env.REACT_APP_APIGATEWAY_PNQ}/quote/qp`, body, { headers })
        if (response.data.async_id) {
            let response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
            while (response_async_body.status === 202){
                response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
                if (response_async_body.status === 200){
                    if (response_async_body.data.statusCode !== 200){
                        throw new Error(response_async_body.data.error)
                    }
                    else{
                        return {
                            request: body,
                            response: response_async_body.data
                        }
                    }
                    
                }
                
            }  
        }
       
    }
    catch (error) {
        throw new Error(error.message)
    }
}

export const updateNew = async (acord, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const response = await axios.patch(`${process.env.REACT_APP_APIGATEWAY_PNQ}/quote/qp`, acord, { headers })
        if (response.data.async_id) {
            let response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
            while (response_async_body.status === 202){
                response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
                if (response_async_body.status === 200){
                    if (response_async_body.data.statusCode !== 200){
                        throw new Error(response_async_body.data.error)
                    }
                    else{
                        return {
                            request: acord,
                            response: response_async_body.data
                        }
                    }
                    
                }
            }  
        }
    }
    catch (error) {
        throw new Error(error.message)
    }

}

export const shortCodeV2 = async (shortCode, agencyCode) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
    }

    const baseUrl = `${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/v2`

    const url = agencyCode ? `${baseUrl}?agency_code=${agencyCode}` : `${baseUrl}`

    const response = await axios.get(url, { headers })

    return response.data
}

export const issueNew = async (acord, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/quote/qp`, acord, { headers })
        if (response.data.async_id) {
            let response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
            while (response_async_body.status === 202){
                response_async_body = await axios.get(`${process.env.REACT_APP_APIGATEWAY_PNQ}/async/${response.data.async_id}`,{ headers })
                if (response_async_body.status === 200){
                    if (response_async_body.data.statusCode !== 200){
                        throw new Error(response_async_body.data.error)
                    }
                    else{
                        return {
                            request: acord,
                            response: response_async_body.data
                        }
                    }
                    
                }
            }  
        }
    }
    catch (error) {
        throw new Error(error.message)
    }


}

export const save = async (quoteInfo, shortCode, token, uuid, stepId, customerId) => {
    const request = quoteInfo.request
    const response = quoteInfo.response ? quoteInfo.response : undefined

    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }
    const body = {
        uuid: uuid,
        acord_req: request,
        acord_res: response,
        step_id: stepId,
        zoho_customer_id: null,
    }
    const { data } = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/quote`, body, {
        headers,
    })

    return data
}

export const lookup = async (data,token, shortCode) => {
    try {
        const headers = {
            'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
            Authorization: token,
        }

        const url = `${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/quote/lookup`
        const request = await axios.post(url, data, { headers })
        const response = await request.data
        return response
    } catch (error) {
        return error.response
    }
}

export const receipt = async (token, context, policyNumber) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const body = {
            "to": `${process.env.REACT_APP_TO_EMAIL}`
        }

        const response = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${context.agencyName}/policy-number/${policyNumber}/receipt?agency_code=${context.agencyId}`, body, { headers })

        return response.data

    }
    catch (error) {
        return error
    }
}

export const quotingBridgeV4 = async (quoteNumber, pid, date, token) => {
    const headers = {
        'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token
    }

    const body = {
        'quote_number': quoteNumber,
        pid,
        date
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/quote/bridge`, body, { headers })
        return response
    } catch (error) {
        throw error
    }

}

export const veloxWebhook = async(token, quoteInfo, shortCode) => {
    const request = quoteInfo.request
    const response_acord = quoteInfo.response
    if (shortCode === 'velox'){
        const body = {
            "request": request,
            "response": response_acord
        }
        const response = axios.post(`${process.env.REACT_APP_APIGATEWAY_PNQ}/short-code/${shortCode}/webhook`, body, {
            headers: {
                'x-api-key': process.env.REACT_APP_APIGATEWAY_KEY,
                Authorization: token,
            },
        })
        return response.data
    }
    

    
}

export const signedNBDocs = async(token, policyNumber) => {

    const headers = {
        "x-api-key": process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_APIGATEWAY_PNQ}/policy/${policyNumber}/print?include_attachments=true`,
            {headers}
        )

        return response.data.pdf
    } catch (error) {
        return error
    }
}

export const quoteDocs = async (token, policy_number) => {

    const headers = {
        "x-api-key": process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_APIGATEWAY_PNQ}/policy/${policy_number}/print/dec`,
            {headers}
        )

        return response.data.pdf
    } catch (error) {
        return error
    }
}

export const uwQuestionLookup = async (token, agency_code, state) => {

    const headers = {
        "x-api-key": process.env.REACT_APP_APIGATEWAY_KEY,
        Authorization: token,
    }

    const body = {
        "agency_code": agency_code,
        state: state,
        include: ["UW_QUESTIONS"]
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_APIGATEWAY_PNQ}/static`,
            body,
            {headers},
        )
        return response.data
    } catch (error) {
        return error
    }
}