import React from 'react'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import states from '../../../data/states.json'
import Button from '../../../components/button/button'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import BasicInput from '../../../components/basicInput/basicInput'
import AgencyContext from '../../../context/agency-context'
import './license.scss'
class DriverLicense extends React.Component {

    state = {
        licenseStatus: '',
        licenseState: '',
        licenseNumber: '',
        licenseNumberError: '',
        isError: [false, false, false],
        showLincenseInput: true
    }
    static contextType = AgencyContext

    componentDidMount = () => {
        const { getters } = this.props
        if (this.props.values.quoteInfo) {
            const id = getters.general.SelectedDriver()
            const licenseStatus = this.props.getters.driver.LicenseStatusCd(id)
            this.setState({
                licenseStatus,
                licenseState: getters.driver.StateProvCd(id),
                licenseNumber:
                    getters.driver.LicensePermitNumber(id) === 'EXCLUDED' ? '' : getters.driver.LicensePermitNumber(id),
                showLincenseInput: licenseStatus !== 'Matricula'
            })
        }
    }

    valueCheck = (value) => {
        // this might change for checking status
        if (value !== '') {
            return false
        }
        return true
    }

    checkLicenseNumber = () => {
        const { values, setters, getters } = this.props
        const { licenseNumber, licenseStatus } = this.state
        let invalid = false
        if (licenseNumber === '' && licenseStatus !== 'Matricula') {
            invalid = true
            this.setState({ licenseNumberError: 'Please enter a license number' })
        } else {
            const id = getters.general.SelectedDriver()
            setters.driver.LicensePermitNumber(id, '')
            const driversList = get(
                values,
                'quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver',
                []
            )
            const found = driversList.find((driver) => {
                const license = get(driver, 'DriverInfo.License.LicensePermitNumber')
                return license === licenseNumber
            })
            if (!!found && found.DriverInfo.License.LicensePermitNumber !== '') {
                invalid = true
                this.setState({ licenseNumberError: 'A driver with that license number is already added.' })
            }
        }

        return invalid
    }

    checkAll = () => {
        const { setters } = this.props

        this.setState(
            {
                isError: [
                    this.valueCheck(this.state.licenseStatus),
                    this.valueCheck(this.state.licenseState),
                    this.checkLicenseNumber(),
                ],
            },
            () => {
                var errors = this.state.isError

                if (!errors.includes(true)) {
                    const id = this.props.getters.general.SelectedDriver()
                    setters.driver.LicenseStatusCd(id, this.state.licenseStatus)
                    setters.driver.StateProvCd(id, this.state.licenseState)
                    setters.driver.LicensePermitNumber(id, this.state.licenseNumber)

                    this.props.history.push(`/${this.context.agencyName}/drivers`)
                }
            }
        )
    }

    handleGoBack = () => {
        this.props.history.push(`/${this.context.agencyName}/drivers/name`)
    }

    handleLicenseStatusChange = (val) => {
        if (val === 'Matricula') {
            this.setState({ showLincenseInput: false, licenseStatus: val, licenseNumber: '' })
        } else {
            this.setState({ licenseStatus: val, showLincenseInput: true })
        }
    }

    render = () => {
        const { licenseNumberError, licenseNumber, licenseStatus, licenseState, isError, showLincenseInput } = this.state

        return (
            <div className="policy-name">
                <div className="quote-headline-two">Enter your driver information</div>
                <div className="driver-license-block">
                    <DropdownInput
                        label="License Status"
                        options={this.props.getters.general.licenseStatuses()}
                        defaultValue={licenseStatus}
                        onChange={this.handleLicenseStatusChange}
                        isError={isError[0]}
                        errorMessage={'Please enter a license status'}></DropdownInput>
                    <DropdownInput
                        label="License State"
                        options={states}
                        defaultValue={licenseState}
                        onChange={(val) => {
                            this.setState({
                                licenseState: val,
                            })
                        }}
                        isError={isError[1]}
                        errorMessage={'Please enter a license state'}></DropdownInput>
                    {showLincenseInput && <BasicInput
                        label="License Number"
                        defaultValue={licenseNumber}
                        onChange={(val) => {
                            this.setState({
                                licenseNumber: val.toUpperCase(),
                            })
                        }}
                        isError={isError[2]}
                        mask={[
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                        ]}
                        errorMessage={licenseNumberError}></BasicInput>}
                    <div className="buttons-row">
                        <Button buttonText="Back" align="center" onClick={this.handleGoBack} />
                        <Button buttonText="Continue" align="center" onClick={this.checkAll} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DriverLicense)
