import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import './addButton.scss'

const AddButton = ({ onClick, label }) => {

  const [loaded, setLoad] = useState(false)

  useEffect(() => {
    setLoad(true)
    let timer = setTimeout(() => setLoad(false), 1500);

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={`driver-add-button ${loaded ? 'float' : ''}`} onClick={onClick}>
      <div className="icon-container">
        <AddCircleRoundedIcon className="icon" />
        <div>{label}</div>
      </div>
    </div>
  )
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string
}

export default AddButton