import React, { Component } from 'react'
import get from 'lodash/get'
import { withRouter, matchPath } from 'react-router'
import { Switch, Route } from "react-router-dom"
import { diamondDate, addMonthsToDate, convertShortDate } from '../../helpers/dateHelpers'
import BridgeV4 from '../bridge/bridgeV4/bridgeV4'
import DriverMissingInfoScreen from '../bridge/driverMissingInfo/driverMissingInfo'
import Start from '../../screens/basic/start/start.jsx'
import Name from '../../screens/basic/name/name.jsx'
import Address from '../../screens/basic/address/address.jsx'
import VinLookup from '../../screens/vehicle/vinLookup/vinLookup.jsx';
import DisplayVehicle from '../../screens/vehicle/displayVehicle/displayVehicle';
import PolicyName from '../../screens/policy/name/name';
import PolicyLicense from '../../screens/policy/license/license'
import PolicyHome from '../../screens/policy/home/home'
import DisplayDriver from '../../screens/driver/displayDriver/displayDriver'
import DriverName from '../../screens/driver/name/name'
import DriverLicense from '../../screens/driver/license/license'
import Coverages from '../../screens/review/coverages/coverages';
import QuoteReview from '../../screens/quoteReview/review'
import VehiclesLeasing from '../../screens/review/coverages/vehicles/buyInfo'
import Success from '../../screens/success/success'
import PurchaseForm from '../../screens/review/purchase/purchaseForm'
import Esign from '../../screens/review/esign/esign';
import Resume from '../../screens/resume/index';
import { v1 as uuidv1 } from 'uuid'
import newDriverTemplate from '../../data/new-driver-template.json'
import newVehicleTemplate from '../../data/new-vehicle-template.json'
import validate from './validate'
import QuoteContext from '../../context/quote-context'
import AgencyContext from '../../context/agency-context'
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import DocuSign from '../docuSign/docuSign';
import { keysStorage } from '../../helpers/constants'
import localStorageService from '../../helpers/localStorage'
class QuotingForm extends Component {
    static contextType = AgencyContext;

    state = {
        // Form Control:
        step: 1,
        // Agency Info:
        agency: null,
        auth: null,
        // Quick Quote Info:
        PostalCode: null,
        BirthDt: null,
        GenderCd: null,
        MaritalStatusCd: null,
        ExpirationDt: null,
        stepObj: undefined,
        customerId: undefined,

        // Other:
        selectedCoverage: [],
        selectedVehicle: null,
        selectedZip: null,
        selectedDriver: 'DRV1',
        quoteLoaded: false,
        vehicleLookupMemo: {},
        editFromReviewScreen: false,
        comesFromQuotingBridge: false,

        quoteId: null,
        quoteInfo: null,    // ACORD Object 
        availableQuotes: [], // quoteOptions
        isQuoteFinished: false,
        finishedPolicy: null,
        cardUsed: null,
        docuSignURL: undefined,

        // Standard Error Handling:
        generalErrors: {},
        driverErrors: { "DRV1": {} },
        vehicleErrors: { "VEH1": {} },

        // Guided Error Handling:
        runGuidedNavigation: false,
        guidedErrors: {
            policyInformationForm: [],
            driverInformationForm: [],
            vehicleInformationForm: [],
        },
        quoteCoverage: null,
        vehiclesYears: [],
        allowedPaths: ['bridgev4', 'lookup'],
        isEditable: true
    }

    /**
     * Pushes an IntroJS step object onto the specified field of the guided errors object
     * @param {String} field - the name of the field. policyInformationForm, driverInformationForm, or vehicleInformationForm
     * @param {String} stepObject - a step object which IntroJS supports to be used as a step 
     */
    pushGuidedError = (field, stepObject) => {
        const { guidedErrors } = this.state;

        guidedErrors[field].push(stepObject)

        this.setState({ guidedErrors })
    }

    returnNextStep = (currentStep) => {

        if (!this.state.stepObj) {
            this.setState({
                stepObj: {
                    stepId: 'Basic Info',
                    stepNum: 1
                }
            })
            return 'Basic Info'
        }
        else {
            if (currentStep.stepNum < this.state.stepObj.stepNum) {
                return this.state.stepObj.stepId
            }
            else {
                this.setState({
                    stepObj: {
                        stepId: currentStep.stepId,
                        stepNum: currentStep.stepNum
                    }
                })
                return currentStep.stepId
            }

        }

    }

    runMVR = () => {

        const { quoteInfo } = this.state

        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.Program = "FULLQUOTE"

        let drivers = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver

        const vehicleCount = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.length

        for (var i in drivers) {
            if (i < vehicleCount && drivers[i].DriverInfo.License.LicensePermitNumber !== 'EXCLUDED' && drivers[i].DriverInfo.License.LicenseStatusCd !== 'Matricula' && drivers[i].DriverInfo.License.LicenseStatusCd !== 'International License') {
                drivers[i].PersDriverInfo.OrderMvrInd = 1
            }
        }
        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver = drivers

        this.setState({ quoteInfo: quoteInfo })


    }

    /**
     * Starts the guided navigation for error checking and redirects the user to the first page with errors.
     */
    startGuidedNavigation = () => {

        const { guidedErrors } = this.state;

        if (guidedErrors.policyInformationForm.length > 0 || guidedErrors.driverInformationForm.length > 0 || guidedErrors.vehicleInformationForm.length > 0) {
            this.setState({ runGuidedNavigation: true })
            this.redirectToPageWithErrors();
        } else {
            return null;
        }
    }

    /**
     * Redirects to the first page that has errors
     */
    redirectToPageWithErrors = () => {
        const { guidedErrors } = this.state;

        if (guidedErrors.policyInformationForm.length > 0) {
            this.props.history.push(`policyinfo`)
        } else if (guidedErrors.driverInformationForm.length > 0) {
            this.props.history.push(`driverinfo`)
        } else if (guidedErrors.vehicleInformationForm.length > 0) {
            this.props.history.push(`vehicleinfo`)
        } else {
            this.setState({ runGuidedNavigation: false })
        }
    }

    /**
     * Redirects to the next page that has errors down the line regardless of whether the current one has its errors resolved
     */
    redirectToNextPageWithErrors = () => {
        const page = this.getPage();

        const { driverInformationForm, vehicleInformationForm } = this.state.guidedErrors;

        if (page === 'policyinfo') {
            if (driverInformationForm.length > 0) this.props.history.push(`driverinfo`)
            else if (vehicleInformationForm.length > 0) this.props.history.push(`vehicleinfo`)
            else {
                this.setState({ runGuidedNavigation: false })
                this.props.history.push(`coverages`)
            }
        } else if (page === 'driverinfo') {
            if (vehicleInformationForm.length > 0) this.props.history.push(`vehicleinfo`)
            else {
                this.setState({ runGuidedNavigation: false })
                this.props.history.push(`coverages`)
            }
        } else if (page === 'vehicleinfo') {
            this.setState({ runGuidedNavigation: false })
            this.props.history.push(`coverages`)
        }

    }

    getGuidedErrors = () => {
        const page = this.getPage();

        let steps = null;

        if (page === 'policyinfo') {
            steps = this.state.guidedErrors.policyInformationForm
        } else if (page === 'driverinfo') {
            steps = this.state.guidedErrors.driverInformationForm
        } else if (page === 'vehicleinfo') {
            steps = this.state.guidedErrors.vehicleInformationForm
        } else {
            steps = []
        }


        const numSteps = steps.length;

        if (numSteps > 0) {
            if (steps[numSteps - 1].element !== '.done') {
                steps.push({
                    element: '.done',
                    intro: "Please press Next Page to continue"
                })
            }
        }

        return steps;
    }

    getPage = () => {
        let pathname = window.location.pathname;
        let pathArray = pathname.split('/')
        return pathArray[pathArray.length - 1]
    }

    onExit = async () => {
        // this.setState({runGuidedNavigation: false})
    }

    onComplete = async () => {
        await this.redirectToNextPageWithErrors();
        this.delay(250)
        await this.setState({ runGuidedNavigation: true })
    }

    delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    render() {
        const values = this.state
        const { runGuidedNavigation } = this.state;
        let steps = this.getGuidedErrors()

        return (

            // Render the correct component based on the path 
            <QuoteContext.Provider value={{ values: this.state, getters: this.getters, setters: this.setters, actions: this.actions }}>
                <Steps
                    enabled={runGuidedNavigation}
                    steps={steps}
                    initialStep={0}
                    onBeforeChange={(nextStepIndex) => {
                        if (steps.length === 1) {
                            return nextStepIndex !== 0
                        }
                    }}
                    onComplete={this.onComplete}
                    onExit={this.onExit}
                    options={{
                        showBullets: false,
                        showProgress: false,
                        showStepNumbers: false,
                        exitOnEsc: false,
                        exitOnOverlayClick: false,
                        nextLabel: "<strong>Next</strong>",
                        prevLabel: "<strong>Previous</strong>",
                        doneLabel: "<strong>Next Page</strong>"
                    }}
                />
                <Switch>
                    <Route exact path={`/:agencyName/start`} render={() =>
                        <Start values={values} getters={this.getters} setters={this.setters}
                            handlerClearState={() => {
                                this.handlerClearState()
                            }}></Start>
                    } />
                    <Route exact path={`/:agencyName/start`} render={() =>
                        <Start values={values} getters={this.getters} setters={this.setters}></Start>
                    } />

                    <Route exact path={`/:agencyName/name`} render={() =>
                        <Name values={values} nextStep={this.returnNextStep} getters={this.getters} setters={this.setters}></Name>
                    } />

                    <Route exact path={`/:agencyName/address`} render={() =>
                        <Address values={values} getters={this.getters} setters={this.setters}></Address>
                    } />

                    <Route exact path={`/:agencyName/vin`} render={() =>
                        <VinLookup values={values} getters={this.getters} setters={this.setters}></VinLookup>
                    } />

                    <Route exact path={`/:agencyName/vehicles`} render={() =>
                        <DisplayVehicle
                            nextStep={this.returnNextStep}
                            values={values}
                            getters={this.getters}
                            setters={this.setters}
                            addNewVehicle={this.addNewVehicle}
                            removeVehicle={this.removeVehicle}
                        ></DisplayVehicle>
                    } />

                    <Route exact path={`/:agencyName/policy/name`} render={() =>
                        <PolicyName values={values} getters={this.getters} setters={this.setters}></PolicyName>
                    } />

                    <Route exact path={`/:agencyName/policy/license`} render={() =>
                        <PolicyLicense values={values} getters={this.getters} setters={this.setters}></PolicyLicense>
                    } />

                    <Route exact path={`/:agencyName/policy/home`} render={() =>
                        <PolicyHome values={values} nextStep={this.returnNextStep} getters={this.getters} setters={this.setters}></PolicyHome>
                    } />

                    <Route exact path={`/:agencyName/drivers`} render={() =>
                        <DisplayDriver
                            values={values}
                            nextStep={this.returnNextStep}
                            getters={this.getters}
                            setters={this.setters}
                            addNewDriver={this.addNewDriver}
                            removeDriver={this.removeDriver}
                        ></DisplayDriver>
                    } />

                    <Route exact path={`/:agencyName/drivers/name`} render={() =>
                        <DriverName values={values} getters={this.getters} setters={this.setters} />
                    } />

                    <Route exact path={`/:agencyName/drivers/license`} render={() =>
                        <DriverLicense values={values} getters={this.getters} setters={this.setters} />
                    } />

                    <Route exact path={`/:agencyName/coverages`} render={() =>
                        <Coverages values={values} getters={this.getters} setters={this.setters} />
                    } />

                    <Route exact path={`/:agencyName/leasing`} render={() =>
                        <VehiclesLeasing runMVR={this.runMVR} values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path={'/:agencyName/review'} render={() =>
                        <QuoteReview values={values} nextStep={this.returnNextStep} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path={'/:agencyName/success'} render={() =>
                        <Success values={values} nextStep={this.returnNextStep} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path={'/:agencyName/pay'} render={() =>
                        <PurchaseForm values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path='/:agencyName/esign' render={() =>
                        <Esign values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path='/:agencyName/lookup' render={() =>
                        <Resume values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path='/:agencyName/:agency_code/bridgeV4/:quote_number/:added_date/:policy_id' render={() =>
                        <BridgeV4 values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path='/:agencyName/driver-missing-info' render={() =>
                        <DriverMissingInfoScreen values={values} getters={this.getters} setters={this.setters} />
                    } />
                    <Route exact path='/:agencyId/:quoteId/docusign' render={() =>
                        <DocuSign values={values} resetQuote={this.resetQuote} nextStep={this.nextStep} prevStep={this.prevStep}></DocuSign>
                    }></Route>

                </Switch>
            </QuoteContext.Provider>
        )
    }



    componentDidUpdate = (nextProps, nextState) => {
        localStorageService.saveInStorage(keysStorage.STATE, nextState)
        //VALIDATE TIME IN SESSION
        const string_date_last_register = localStorageService.getFromStorage(keysStorage.TIME_IN_SCREEN)
        if (string_date_last_register === null) {
            localStorageService.saveInStorage(keysStorage.TIME_IN_SCREEN, (new Date()))
        } else {
            const today = new Date()
            const last_register = new Date(string_date_last_register)
            const diffMs = (today - last_register);
            const time_in_screen = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            if (time_in_screen >= parseInt(`${process.env.REACT_APP_NAME_STATE_MINUTES_IN_SCREEN}`)) {
                this.handlerClearState()
                this.props.history.push(`/${this.context.agencyName}/start`)
            }
        }

        const { step } = this.state
        const { pathname } = this.props.location

        if (step !== 1 && matchPath(pathname, { path: '/:agencyName/quickquote', exact: true, strict: false })) {
            window.location.reload()
        }
    }

    UNSAFE_componentWillMount = () => {
        this.handlerUpdateState()
    }

    handlerUpdateState = () => {
        const { location: { pathname } } = this.props
        const allowed = this.state.allowedPaths.some(x => pathname.includes(x))
        let state_data = localStorageService.getFromStorage(keysStorage.STATE)

        if (!allowed && (state_data === null || state_data === undefined)) {
            this.props.history.push(`/${this.context.agencyName}/start`)
            return
        }
        if (state_data !== null && state_data !== undefined) {
            const docuSignURL = localStorageService.getFromStorage(keysStorage.DOCUSIGN_URL)
            this.setState({
                docuSignURL,
                customerId: state_data.customerId,
                auth: state_data.auth,
                stepId: state_data.stepId,
                step: state_data.step,
                agency: state_data.agency,
                PostalCode: state_data.PostalCode,
                BirthDt: state_data.BirthDt,
                GenderCd: state_data.GenderCd,
                MaritalStatusCd: state_data.MaritalStatusCd,
                ExpirationDt: state_data.ExpirationDt,
                selectedCoverage: state_data.selectedCoverage,
                selectedZip: state_data.selectedZip,
                selectedVehicle: state_data.selectedVehicle,
                selectedDriver: state_data.selectedDriver,
                vehicleLookupMemo: state_data.vehicleLookupMemo,
                quoteId: state_data.quoteId,
                quoteInfo: state_data.quoteInfo,
                quoteLoaded: state_data.quoteLoaded,
                isQuoteFinished: state_data.isQuoteFinished,
                finishedPolicy: state_data.finishedPolicy,
                cardUsed: state_data.cardUsed,
                generalErrors: state_data.generalErrors,
                driverErrors: state_data.driverErrors,
                vehicleErrors: state_data.vehicleErrors,
                runGuidedNavigation: state_data.runGuidedNavigation,
                guidedErrors: state_data.guidedErrors,
                licenseStatuses: state_data.licenseStatuses,
                quoteCoverage: state_data.quoteCoverage,
                coverages: state_data.coverages,
                vehiclesYears: state_data.vehiclesYears,
                editFromReviewScreen: state_data.editFromReviewScreen,
                comesFromQuotingBridge: state_data.comesFromQuotingBridge,
                isEditable: state_data.isEditable
            })
        }
    }
    handlerClearState = () => {
        localStorageService.clearAllStorage();
        this.setState({
            step: 1,
            stepId: undefined,
            customerId: undefined,
            agency: null,
            PostalCode: null,
            BirthDt: null,
            GenderCd: null,
            MaritalStatusCd: null,
            ExpirationDt: null,
            selectedCoverage: [],
            selectedVehicle: null,
            selectedDriver: 'DRV1',
            selectedZip: null,
            vehicleLookupMemo: {},
            quoteId: null,
            quoteInfo: null,
            quoteLoaded: false,
            isQuoteFinished: false,
            finishedPolicy: null,
            cardUsed: null,
            generalErrors: {},
            driverErrors: { "DRV1": {} },
            vehicleErrors: { "VEH1": {} },
            runGuidedNavigation: false,
            guidedErrors: {
                policyInformationForm: [],
                driverInformationForm: [],
                vehicleInformationForm: [],
            },
            quoteCoverage: null,
            vehiclesYears: [],
            editFromReviewScreen: false,
            comesFromQuotingBridge: false,
            docuSignURL: ''
        })
    }

    UNSAFE_componentWillMount = () => {
        this.handlerUpdateState()
    }

    handlerUpdateState = () => {
        const { location: { pathname } } = this.props
        const allowed = this.state.allowedPaths.some(x => pathname.includes(x))
        let state_data = localStorageService.getFromStorage(keysStorage.STATE)

        if (!allowed && (state_data === null || state_data === undefined)) {
            this.props.history.push(`/${this.context.agencyName}/start`)
            return
        }
        if (state_data !== null && state_data !== undefined) {
            const docuSignURL = localStorageService.getFromStorage(keysStorage.DOCUSIGN_URL)
            this.setState({
                docuSignURL,
                auth: state_data.auth,
                customerId: state_data.customerId,
                stepId: state_data.stepId,
                step: state_data.step,
                agency: state_data.agency,
                PostalCode: state_data.PostalCode,
                BirthDt: state_data.BirthDt,
                GenderCd: state_data.GenderCd,
                MaritalStatusCd: state_data.MaritalStatusCd,
                ExpirationDt: state_data.ExpirationDt,
                selectedCoverage: state_data.selectedCoverage,
                selectedZip: state_data.selectedZip,
                selectedVehicle: state_data.selectedVehicle,
                selectedDriver: state_data.selectedDriver,
                vehicleLookupMemo: state_data.vehicleLookupMemo,
                quoteId: state_data.quoteId,
                quoteInfo: state_data.quoteInfo,
                quoteLoaded: state_data.quoteLoaded,
                isQuoteFinished: state_data.isQuoteFinished,
                finishedPolicy: state_data.finishedPolicy,
                cardUsed: state_data.cardUsed,
                generalErrors: state_data.generalErrors,
                driverErrors: state_data.driverErrors,
                vehicleErrors: state_data.vehicleErrors,
                runGuidedNavigation: state_data.runGuidedNavigation,
                guidedErrors: state_data.guidedErrors,
                licenseStatuses: state_data.licenseStatuses,
                quoteCoverage: state_data.quoteCoverage,
                coverages: state_data.coverages,
                vehiclesYears: state_data.vehiclesYears,
                editFromReviewScreen: state_data.editFromReviewScreen,
                comesFromQuotingBridge: state_data.comesFromQuotingBridge,
                isEditable: state_data.isEditable
            })
        }
    }
    handlerClearState = () => {
        localStorageService.clearAllStorage();
        this.setState({
            step: 1,
            stepId: undefined,
            auth: null,
            customerId: undefined,
            agency: null,
            PostalCode: null,
            BirthDt: null,
            GenderCd: null,
            MaritalStatusCd: null,
            ExpirationDt: null,
            selectedCoverage: [],
            selectedVehicle: null,
            selectedDriver: 'DRV1',
            selectedZip: null,
            vehicleLookupMemo: {},
            quoteId: null,
            quoteInfo: null,
            quoteLoaded: false,
            isQuoteFinished: false,
            finishedPolicy: null,
            cardUsed: null,
            generalErrors: {},
            driverErrors: { "DRV1": {} },
            vehicleErrors: { "VEH1": {} },
            runGuidedNavigation: false,
            guidedErrors: {
                policyInformationForm: [],
                driverInformationForm: [],
                vehicleInformationForm: [],
            },
            quoteCoverage: null,
            vehiclesYears: [],
            editFromReviewScreen: false,
            comesFromQuotingBridge: false,
            docuSignURL: '',
            isEditable: false
        })
    }

    resetQuote = () => {
        this.props.history.push(`/${this.props.match.params.agencyName}/quickquote`)
    }


    printState = () => {
        console.log(this.state)
    }

    nextStep = () => {
        this.setState(prevState => {
            return {
                step: prevState.step + 1,
            }
        })
    }

    prevStep = (route) => {
        this.props.history.push(route)

        this.setState(prevState => {
            return {
                step: prevState.step - 1,
            }
        })
    }

    /**
     * Adds a new driver to the PersDriver array inside the ACORD object
     * @param {string} DriverTypeCd - 'DD' or 'E'
    */
    addNewDriver = (DriverTypeCd) => {
        let { quoteInfo, driverErrors } = this.state;

        let newDriver = JSON.parse(JSON.stringify(newDriverTemplate))
        newDriver.PersDriverInfo.DriverTypeCd = DriverTypeCd
        let newDriverId = "DRV" + (quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.length + 1)
        newDriver["@id"] = newDriverId
        newDriver.uuid = uuidv1();

        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.push(newDriver)
        driverErrors[newDriverId] = {}

        this.setState({ quoteInfo: quoteInfo, driverErrors: driverErrors })
    }

    /**
     * Removes a driver with a specific id from the PersDriver array inside the ACORD object and updates the ids to be in order
     * @param {string} id - The id of the driver in the ACORD object
     */
    removeDriver = id => {
        let { quoteInfo } = this.state;

        let drivers = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver;
        drivers = drivers.filter(d => d['@id'] !== id);

        // drivers[0] can never be removed, so we don't need to update that
        for (let i = 1; i < drivers.length; i++) {
            drivers[i]['@id'] = `DRV${i + 1}`
        }

        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver = drivers;

        this.setState({ quoteInfo })
    }

    /**
     * Adds a new vehicle to the PersVeh array inside the ACORD object
    */
    addNewVehicle = async () => {
        let { quoteInfo, vehicleErrors } = this.state;

        let newVehicle = JSON.parse(JSON.stringify(newVehicleTemplate))
        let newVehicleId = "VEH" + (quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.length + 1)
        newVehicle["@id"] = newVehicleId;
        newVehicle.uuid = uuidv1();

        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.push(newVehicle)
        vehicleErrors[newVehicleId] = {}

        this.setState({ quoteInfo: quoteInfo, vehicleErrors: vehicleErrors })
    }

    /**
     * Removes a vehicle with a specific id from the PersVeh array inside the ACORD object and updates the ids to be in order
     * @param {string} id - The id of the vehicle in the ACORD object
     */
    removeVehicle = async id => {
        let { quoteInfo } = this.state;

        let vehicles = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh;
        vehicles = vehicles.filter(d => d['@id'] !== id);

        // vehicles[0] can never be removed, so we don't need to update that
        for (let i = 0; i < vehicles.length; i++) {
            vehicles[i]['@id'] = `VEH${i + 1}`
        }

        quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh = vehicles;

        this.setState({ quoteInfo: quoteInfo })
    }

    /**
         * Performs a validation on the currently displayed form and sets appropriate error messages for any invalid data
         */
    validate = (stepToValidate) => {
        // Reset Errors:
        this.setState({ generalErrors: {} })

        const driverErrors = {}

        if (this.state.quoteInfo) {

            const numberOfDrivers = this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver

            for (var i in numberOfDrivers) {
                driverErrors[numberOfDrivers[i]['@id']] = {}
            }

            this.setState({ driverErrors: driverErrors })
        }

        else {
            this.setState({ vehicleErrors: { "DRV1": {} } })
        }

        this.setState({ vehicleErrors: { "VEH1": {} } })

        // Validate Input:
        let validation = validate(this.state, stepToValidate)

        // Update Errors:
        this.setState({ generalErrors: validation.generalErrors })
        this.setState({ driverErrors: validation.driverErrors })
        this.setState({ vehicleErrors: validation.vehicleErrors })
        this.setState({ guidedErrors: validation.guidedErrors })

        return validation.isValid;
        // return true;
    }

    actions = {
        /**
         * Makes a request to pnq to look up a quote by id
         * @param {String} quoteId - the id of the quote to look up
         * @returns {Object} - returns the ACORD object if the lookup was successful, or null otherwise
         */
        lookupQuote: async (quoteId) => {
            try {
                const lookupResponse = await fetch(
                    `${process.env.REACT_APP_APIGATEWAY_PNQ}/agency/${this.context.agencyId}/quote/${quoteId}`,
                    {
                        method: 'GET',
                        headers: {
                            "x-api-key": process.env.REACT_APP_APIGATEWAY_KEY
                        }
                    }
                )

                if (lookupResponse.status !== 200 || lookupResponse.status !== 201) {
                    return null;
                } else {
                    const lookupJson = await lookupResponse.json()
                    return lookupJson
                }
            } catch (err) {
                throw new Error('Failed to lookup quote:', quoteId)
            }
        },

        /**
         * Makes a request to pnq to save the quote object to the mongo database.
         * @param {quoteId} - the id of the quote we want to save. If the id isn't provided, it will defalt to the current quoteId field
         * @param {quoteObject} - the request ACORD object we want to save. If the object isn't provided, it will defalt to the current quoteInfo object field
         * @returns {Number} - returns the status code of the request
         */
        saveQuote: async (quoteId, quoteObject) => {
            if (quoteObject === undefined) {
                quoteObject = this.state.quoteInfo;
            }
            if (quoteId === undefined) {
                quoteId = this.state.quoteId;
            }

            try {
                await fetch(`${process.env.REACT_APP_APIGATEWAY_PNQ}/agency/${this.context.agencyId}/quote/${quoteId}`, {
                    method: "PUT",
                    body: JSON.stringify(quoteObject.request),
                    headers: {
                        "x-api-key": process.env.REACT_APP_APIGATEWAY_KEY
                    }
                })

            } catch (err) {
                console.log("failed saving quote")
            }
        }
    }

    getValue = (input) => {
        return this.state[input];
    }

    /**
     * All necessary getter functions bundled
     */
    getters = {
        general: {
            shownPaymentPlans: () => {

                if (!Array.isArray(this.state.quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption)) {
                    let { quoteInfo } = this.state;
                    let paymentArr = []
                    paymentArr.push(this.state.quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption)
                    quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption = paymentArr

                    this.setState({
                        quoteInfo: quoteInfo
                    })
                }

                return this.state.quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption.filter(e => [
                    '5 Pay 16.67% Down', '4 Pay - 25% Down', '3 Pay 25% Down', 'Paid in Full',
                ].includes(e.Description)
                )
            },

            cheapestDownPaymentPlan: () => {
                const shownPaymentPlans = this.getters.general.shownPaymentPlans();

                return shownPaymentPlans.reduce((min, e) => Number(e.DepositAmt.Amt) < Number(min.DepositAmt.Amt) ? e : min, shownPaymentPlans[0])
            },
            quoteInfo: () => this.state.quoteInfo,
            SelectedVehicle: () => this.state.selectedVehicle,
            SelectedZip: () => this.state.selectedZip,
            SelectedDriver: () => this.state.selectedDriver,
            selectedCoverage: (i) => this.state.selectedCoverage[i],
            licenseStatuses: () => this.state.licenseStatuses,
            Coverages: () => this.state.coverages,
            quoteCoverage: () => this.state.quoteCoverage,
            getDrivers: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver,
            getVehicles: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh,
            getPayments: () => this.state.quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption,
            getVehiclesYears: () => this.state.vehiclesYears,
            editFromReviewScreen: () => this.state.editFromReviewScreen,
            docuSignURL: () => this.state.docuSignURL,
            quoteLoaded: () => this.state.quoteLoaded,
            CustomerID: () => this.state.customerId,
            Auth: () => this.state.auth,
            ComesFromQuotingBridge: () => this.state.comesFromQuotingBridge,
            isEditable: () => this.state.isEditable
        },

        policy: {
            GivenName: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.GivenName,
            OtherGivenName: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.OtherGivenName,
            Surname: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.Surname,
            PhoneNumber: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications && this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.PhoneInfo[2].PhoneNumber,
            EmailAddr: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications && this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.EmailInfo.EmailAddr,
            Addr1: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.Addr1,
            City: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.City,
            StateProvCd: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.StateProvCd,
            PostalCode: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.PostalCode,
            EffectiveDt: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt,
            ResidenceCd: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.ResidenceOwnedRentedCd,
            ResidenceTypeCd: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.ResidenceTypeCd,
            ContractTerm: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.DurationPeriod.NumUnits,
            PaymentPlan: () => this.state.quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption,
            Payment: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption,
            PolicyNumber: () => get(this.state, 'quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyNumber', ''),
            OtherOrPriorPolicy: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd,
            OtherOrPriorPolicy_InsurerName: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName,
            OtherOrPriorPolicy_PolicyNumber: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.PolicyNumber,
            OtherOrPriorPolicy_ExpirationDt: () => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.ContractTerm.ExpirationDt,
            CompleteAddress: () => {
                if (this.getters.policy.Addr1() !== "") {
                    return this.getters.policy.Addr1() + ", " + this.getters.policy.City() + ", " + this.getters.policy.StateProvCd() + " " + this.getters.policy.PostalCode()
                }
                return ""
            }
        },
        driver: {
            GivenName: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].GeneralPartyInfo.NameInfo.PersonName.GivenName,
            OtherGivenName: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].GeneralPartyInfo.NameInfo.PersonName.OtherGivenName,
            Surname: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].GeneralPartyInfo.NameInfo.PersonName.Surname,
            GenderCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.PersonInfo.GenderCd,
            MaritalStatusCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.PersonInfo.MaritalStatusCd,
            OccupationDesc: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.PersonInfo.OccupationDesc,
            BirthDt: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.PersonInfo.BirthDt,
            LicensePermitNumber: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.License.LicensePermitNumber,
            StateProvCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.License.StateProvCd,
            LicenseStatusCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.License.LicenseStatusCd,
            EmployerZipCode: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].DriverInfo.PersonInfo.MiscParty.GeneralPartyInfo.Addr.PostalCode,
            DriverRelationshipToApplicantCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].PersDriverInfo.DriverRelationshipToApplicantCd,
            SR22: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.filter(e => e['@id'] === id)[0].PersDriverInfo.sr22,
        },
        vehicle: {
            VehIdentificationNumber: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].VehIdentificationNumber,
            ModelYear: (id) => {
                if (this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.some(e => e['@id'] === id)) {
                    return this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].ModelYear
                } else {
                    return ''
                }

            },
            Manufacturer: (id) => {
                if (this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.some(e => e['@id'] === id)) {
                    return this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].Manufacturer
                } else {
                    return ''
                }
            },
            Model: (id) => {
                if (this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.some(e => e['@id'] === id)) {
                    return this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].Model
                } else {
                    return ''
                }
            },
            Ownership: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].ownership,
            Payments: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].payments,
            AdditionalInterest_CommercialName: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].GeneralPartyInfo.NameInfo.CommlName.CommercialName,
            AdditionalInterest_Addr1: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].GeneralPartyInfo.Addr.Addr1,
            AdditionalInterest_City: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].GeneralPartyInfo.Addr.City,
            AdditionalInterest_StateProvCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].GeneralPartyInfo.Addr.StateProvCd,
            AdditionalInterest_PostalCode: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].GeneralPartyInfo.Addr.PostalCode,
            AdditionalInterest_AccountNumberId: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].AdditionalInterest[0].AdditionalInterestInfo.AccountNumberId,
            GaragingCd: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].GaragingCd,
            Damage: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].damage,
            Coverage: (i) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].Coverage,
            PurchaseDt: (id) => this.state.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.filter(e => e['@id'] === id)[0].PurchaseDt,
        }

    }

    /**
     * All necessary setter functions bundled
     */
    setters = {
        general: {
            quoteId: (val) => this.setState({ quoteId: val }),
            setQuoteCoverage: (obj) => this.setState({ quoteCoverage: obj }),
            quoteInfo: (obj) => this.setState({ quoteInfo: obj }),
            isQuoteFinished: (val) => this.setState({ isQuoteFinished: val }),
            vehicleLookupMemo: (obj) => this.setState({ vehicleLookupMemo: obj }),
            selectedCoverage: (i, obj) => {
                let list = this.state.selectedCoverage
                list[i] = obj
                this.setState({
                    selectedCoverage: list
                })
            },
            PostalCode: (val) => this.setState({ PostalCode: val }),
            BirthDt: (val) => this.setState({ BirthDt: diamondDate(val) }),
            GenderCd: (val) => this.setState({ GenderCd: val }),
            MaritalStatusCd: (val) => this.setState({ MaritalStatusCd: val }),
            FinishedPolicy: (val) => this.setState({ finishedPolicy: val }),
            CardUsed: (val) => this.setState({ cardUsed: val }),
            LicenseStatuses: (val) => this.setState({ licenseStatuses: val }),
            Coverages: (val) => this.setState({ coverages: val }),
            SelectedVehicle: (id) => this.setState({ selectedVehicle: id }),
            SelectedDriver: (id) => this.setState({ selectedDriver: id }),
            SelectedZip: (val) => this.setState({ selectedZip: val }),
            VehiclesYear: (val) => this.setState({ vehiclesYears: val }),
            EditFromReviewScreen: (val) => this.setState({ editFromReviewScreen: val }),
            docuSignURL: (val) => this.setState({ docuSignURL: val }),
            quoteLoaded: (val) => this.setState({ quoteLoaded: val }),
            CustomerID: (val) => this.setState({ customerId: val }),
            setAccord: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request = val;
                this.setState({ quoteInfo })
            },
            Auth: (val) => this.setState({ auth: val }),
            ComesFromQuotingBridge: (val) => this.setState({ comesFromQuotingBridge: val }),
            isEditable: (val) => this.setState({ isEditable: val })
        },

        policy: {
            GivenName: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.GivenName = val;
                this.setState({ quoteInfo })
            },
            OtherGivenName: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.OtherGivenName = val;
                this.setState({ quoteInfo })
            },
            Surname: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.NameInfo.PersonName.Surname = val;
                this.setState({ quoteInfo })
            },
            PhoneNumber: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.PhoneInfo[2].PhoneNumber = val;
                this.setState({ PhoneNumber: val })
                this.setState({ quoteInfo })
            },
            EmailAddr: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Communications.EmailInfo.EmailAddr = val;
                this.setState({ quoteInfo })
            },
            Addr1: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.Addr1 = val;
                this.setState({ Addr1: val })
                this.setState({ quoteInfo })
            },
            City: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.City = val;
                this.setState({ City: val })
                this.setState({ quoteInfo })
            },
            StateProvCd: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.StateProvCd = val;
                this.setState({ quoteInfo })
            },
            PostalCode: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.PostalCode = val;
                this.setState({ quoteInfo })
            },
            EffectiveDt: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt = diamondDate(val);
                this.setState({ quoteInfo })
            },
            ExpirationDt: (val) => {
                const { quoteInfo } = this.state
                const myDate = convertShortDate(val)
                const aheadDate = addMonthsToDate(myDate, 6);
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.ExpirationDt = diamondDate(aheadDate)
                this.setState({ quoteInfo })
            },
            ResidenceCd: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.ResidenceOwnedRentedCd = val
                this.setState({ quoteInfo })
            },
            ResidenceTypeCd: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.ResidenceTypeCd = val
                this.setState({ quoteInfo })
            },
            ContractTerm: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.DurationPeriod.NumUnits = val;
                this.setState({ quoteInfo })
            },
            PaymentPlan: (val) => {
                const { quoteInfo } = this.state
                if (quoteInfo.request.ACORD.InsuranceSvcRq.purchase) {
                    quoteInfo.request.ACORD.InsuranceSvcRq.purchase.paymentplan = val;
                }
                let paymentOption = quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption.filter(option => option.Description === val)[0];
                if (paymentOption) {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption = {
                        MethodPaymentCd: paymentOption.Description.includes('CC') ? 'RecurringCreditCard' : 'CreditCard',
                        DepositAmt: {
                            Amt: paymentOption.DepositAmt.Amt
                        },
                        DownPaymentPct: paymentOption.DownPaymentPct,
                        InstallmentFeeAmt: {
                            Amt: paymentOption.InstallmentFeeAmt.Amt
                        },
                        InstallmentInfo: {
                            InstallmentAmt: {
                                Amt: paymentOption.InstallmentInfo.InstallmentAmt.Amt
                            }
                        },
                        NumPayments: quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PolicyBridgingURL.includes("stonegate")
                                        ? String(parseInt(paymentOption.NumPayments) + 1)
                                        : paymentOption.NumPayments,
                        Description: paymentOption.Description,
                        ElectronicFundsTransfer: {
                            FromAcct: {}
                        }
                    }
                }

                this.setState({ quoteInfo })
            },
            OtherOrPriorPolicy: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd = val;

                if (val === "No") {
                    delete quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy
                } else {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy = {}
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.ContractTerm = {}
                }

                this.setState({ quoteInfo })
            },
            OtherOrPriorPolicy_InsurerName: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName = val;
                this.setState({ quoteInfo })
            },
            OtherOrPriorPolicy_PolicyNumber: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.PolicyNumber = val;
                this.setState({ quoteInfo })
            },
            OtherOrPriorPolicy_ExpirationDt: (val) => {
                const { quoteInfo } = this.state
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.ContractTerm.ExpirationDt = diamondDate(val);
                this.setState({ quoteInfo })
            },
            Coverage: (i, obj) => {
                const { quoteInfo } = this.state;
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].Coverage = obj;
                this.setState({ quoteInfo })
            },
            Coverage_OptionCd: (val) => {
                const { quoteInfo } = this.state;
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.Coverage[0].Option.OptionCd = val;
                this.setState({ quoteInfo })
            },
            /**
             * @param {String} id    - Question ID of the form: "UNQAUTOP##" - e.g. "UNQAUTOP01"
             * @param {String} field - The field name in the ACORD object to populate"YesNoCd" or "..."
             * @param {String} val   - The value to set the above field to
             */
            PolicyUnderwriting: (id, field, val) => {
                const { quoteInfo } = this.state;

                const questionNumber = id
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer[questionNumber][field] = val;

                this.setState({ quoteInfo })
            }
        },

        driver: {
            GivenName: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].GeneralPartyInfo.NameInfo.PersonName.GivenName = val;
                this.setState({ quoteInfo })
            },
            OtherGivenName: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].GeneralPartyInfo.NameInfo.PersonName.OtherGivenName = val;
                this.setState({ quoteInfo })
            },
            Surname: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].GeneralPartyInfo.NameInfo.PersonName.Surname = val;
                this.setState({ quoteInfo })
            },
            GenderCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)

                if (i === 0) {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.InsuredOrPrincipalInfo.PersonInfo.GenderCd = val
                }

                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.PersonInfo.GenderCd = val;
                this.setState({ quoteInfo })
            },
            MaritalStatusCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)

                if (i === 0) {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.InsuredOrPrincipalInfo.PersonInfo.MaritalStatusCd = val
                }

                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.PersonInfo.MaritalStatusCd = val;
                this.setState({ quoteInfo })
            },
            OccupationDesc: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.PersonInfo.OccupationDesc = val;
                this.setState({ quoteInfo })
            },
            BirthDt: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                const toDiamondDate = diamondDate(val)
                if (i === 0) {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.InsuredOrPrincipalInfo.PersonInfo.BirthDt = toDiamondDate;
                }

                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.PersonInfo.BirthDt = toDiamondDate;
                this.setState({ quoteInfo })
            },
            LicensePermitNumber: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.License.LicensePermitNumber = val;
                this.setState({ quoteInfo })
            },
            StateProvCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.License.StateProvCd = val;
                this.setState({ quoteInfo })
            },
            LicenseStatusCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.License.LicenseStatusCd = val;
                this.setState({ quoteInfo })
            },
            EmployerZipCode: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].DriverInfo.PersonInfo.MiscParty.GeneralPartyInfo.Addr.PostalCode = val;
                this.setState({ quoteInfo })
            },
            DriverRelationshipToApplicantCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].PersDriverInfo.DriverRelationshipToApplicantCd = val;
                this.setState({ quoteInfo })
            },
            SR22: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver[i].PersDriverInfo.sr22 = val;
                this.setState({ quoteInfo })
            }
        },

        vehicle: {
            infoBuy: (id, payments, company, address, zipcode, state, city) => {
                const { quoteInfo } = this.state
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(
                    (e) => e['@id']
                ).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[
                    i
                ].payments = payments ? 'Yes' : 'No'
                if (payments) {

                    const address_obj = {
                        Addr1: address,
                        City: city,
                        StateProvCd: state,
                        PostalCode: zipcode,
                    }

                    const additionalStructure = []

                    additionalStructure.push({
                        GeneralPartyInfo: {
                            NameInfo: {
                                CommlName: {
                                    CommercialName: company
                                }
                            },
                            Addr: address_obj
                        }
                    })

                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[
                        i
                    ].AdditionalInterest = additionalStructure

                }
                else {
                    quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[
                        i
                    ].AdditionalInterest = undefined
                }
                this.setState({ quoteInfo })
            },
            VehIdentificationNumber: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].VehIdentificationNumber = val;
                this.setState({ quoteInfo })
            },
            ModelYear: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].ModelYear = val;
                this.setState({ quoteInfo })
            },
            Manufacturer: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].Manufacturer = val;
                this.setState({ quoteInfo })
            },
            Model: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].Model = val;
                this.setState({ quoteInfo })
            },
            Ownership: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].ownership = val;
                this.setState({ quoteInfo })
            },
            Payments: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].payments = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_CommercialName: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].GeneralPartyInfo.NameInfo.CommlName.CommercialName = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_Addr1: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].GeneralPartyInfo.Addr.Addr1 = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_City: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].GeneralPartyInfo.Addr.City = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_StateProvCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].GeneralPartyInfo.Addr.StateProvCd = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_PostalCode: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].GeneralPartyInfo.Addr.PostalCode = val;
                this.setState({ quoteInfo })
            },
            AdditionalInterest_AccountNumberId: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].AdditionalInterest[0].AdditionalInterestInfo.AccountNumberId = val;
                this.setState({ quoteInfo })
            },
            GaragingCd: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].GaragingCd = val;
                this.setState({ quoteInfo })
            },
            Damage: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].damage = val;
                this.setState({ quoteInfo })
            },
            PurchaseDt: (id, val) => {
                const { quoteInfo } = this.state;
                let i = quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh.map(e => e['@id']).indexOf(id)
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh[i].PurchaseDt = diamondDate(val);
                this.setState({ quoteInfo })
            }
        },

        CardPayment: {
            Setup: () => {
                const { quoteInfo } = this.state;
                let val = quoteInfo.request.ACORD.InsuranceSvcRq.purchase.paymentplan;
                let paymentOption = quoteInfo.response.ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PersPolicy.PaymentOption.filter(option => option.Description === val)[0];
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.ElectronicFundsTransfer = {}
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.ElectronicFundsTransfer.FromAcct = {}
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.MethodPaymentCd = 'CreditCard'
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.DepositAmt = {}
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.DepositAmt.Amt = paymentOption.DepositAmt.Amt
                this.setState({ quoteInfo })
            },
            AccountNumberId: (val) => {
                const { quoteInfo } = this.state;
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.ElectronicFundsTransfer.FromAcct.AccountNumberId = val;

                this.setState({ quoteInfo })
            },
            CreditCardExpirationDt: (val) => {
                const { quoteInfo } = this.state;
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.ElectronicFundsTransfer.FromAcct.CreditCardExpirationDt = val;
                this.setState({ quoteInfo })
            },
            CVV: (val) => {
                const { quoteInfo } = this.state;
                quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PaymentOption.ElectronicFundsTransfer.FromAcct.CVV = val;
                this.setState({ quoteInfo })
            },
        }
    }

}

export default withRouter(QuotingForm);
