import React from 'react';
import './addVehicle.scss';
import { FormControl, Select, withStyles, InputBase } from '@material-ui/core';
import { makes, models } from '../../../helpers/endpoints';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 15,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        height: 22,
        borderWidth: 2,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 15,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const AddVehicle = ({
    setVehicleInformation,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    makeList,
    modelList,
    yearList,
}) => {
    const selectedYear = (event) => {
        const { name, value } = event.target;
        setVehicleInformation(name, value);
        setVehicleInformation('makeList', []);
        setVehicleInformation('modelList', []);
        setVehicleInformation('vehicleMake', '');
        setVehicleInformation('vehicleModel', '');
        generateMakes(value);
    }

    const selectedMake = (event) => {
        setVehicleInformation('modelList', []);
        setVehicleInformation('vehicleModel', '');
        const { name, value } = event.target;
        setVehicleInformation(name, value);
        generateModels(vehicleYear, value);
    }

    const generateMakes = async (year) => {
        if (year) {
            const makeList = await makes(year);
            setVehicleInformation('makeList', makeList);
        }
    }

    const generateModels = async (year, make) => {
        try {
            if (year && make) {
                const modelList = await models(year, make);
                setVehicleInformation('modelList', modelList);
            }
        } catch (error) {
            setVehicleInformation('modelList', [])
        }
    }

    return (
        <div className='add-vehicle-input'>
            <div className='add-vehicle-option'>
                <div className='add-vehicle-option-label'>
                    Year
                </div>
                <FormControl className='add-vehicle-form' variant='outlined'>
                    <Select
                        native
                        value={vehicleYear}
                        onChange={selectedYear}
                        inputProps={{
                            name: 'vehicleYear',
                            id: 'vehicleYearId',
                        }}
                        input={<BootstrapInput />}
                    >
                        <option key='Empty Year' value=''></option>
                        {yearList.map((year) => <option key={year} value={year}>{year}</option>)}
                    </Select>
                </FormControl>
            </div>
            <div className='add-vehicle-option add-vehicle-option-middle' >
                <div className='add-vehicle-option-label'>
                    Make
                </div>
                <FormControl className='add-vehicle-form' variant='outlined'>
                    <Select
                        native
                        disabled={makeList.length === 0}
                        value={vehicleMake}
                        onChange={selectedMake}
                        inputProps={{
                            name: 'vehicleMake',
                            id: 'vehicleMakeId',
                        }}
                        input={<BootstrapInput />}
                    >
                        <option key='Empty Make' value=''></option>
                        {makeList.map((make) => <option key={make} value={make}>{make}</option>)}
                    </Select>
                </FormControl>
            </div>
            <div className='add-vehicle-option'>
                <div className='add-vehicle-option-label'>
                    Model
                </div>
                <FormControl className='add-vehicle-form' variant='outlined'>
                    <Select
                        native
                        disabled={modelList.length === 0}
                        value={vehicleModel}
                        onChange={(event) => { const { name, value } = event.target; setVehicleInformation(name, value); }}
                        inputProps={{
                            name: 'vehicleModel',
                            id: 'vehicleModelId',
                        }}
                        input={<BootstrapInput />}
                    >
                        <option key='Empty Model' value=''></option>
                        {modelList.map((model) => <option key={model} value={model}>{model}</option>)}
                    </Select>
                </FormControl>
            </div>
        </div>
    )


}

export default AddVehicle;