import React, { PureComponent } from 'react';
import './SVGfooter.scss';
import { ReactComponent as Green } from '../../assets/SVGfooter/green.svg';
import { ReactComponent as Orange } from '../../assets/SVGfooter/orange.svg';
import { ReactComponent as Red } from '../../assets/SVGfooter/red.svg';
import themes from '../../themes';

class SVGFooter extends PureComponent {
    render() {
        const primaryColor = themes?.main.mui.palette.primary.main || '#F18804';
        const secondaryColor = themes?.main.mui.palette.secondary.main || '#E41E21';
        const tertiaryColor = themes?.main.mui.palette.tertiary?.main || '#59B24D';

        return (
            <div className='body'>
                <div className='orange'>
                    <Orange fill={primaryColor} />
                </div>
                <div className='red'>
                    <Red fill={secondaryColor} />
                </div>
                <div className='green'>
                    <Green fill={tertiaryColor} />
                </div>
            </div>
        )
    }
}

export default SVGFooter;
