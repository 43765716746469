import React, { useState } from 'react'
import themes from '../../themes'
import { ReactComponent as Close } from '../../assets/close.svg'
import './infoButton.scss'

const InfoButton = (props) => {

    const { text, title } = props

    const [isOpen, setModal] = useState(false)

    return (
        <React.Fragment>
            <div className='info-button' style={{ color: themes.main.mui.palette.primary.main }} onClick={() => { setModal(true) }}>
                ?
            </div>
            <div className='info-modal' style={{ opacity: isOpen ? '1' : '0', visibility: isOpen ? 'visible' : 'hidden', left: !isOpen && '50px' }}>
                <div className='info-modal-close' onClick={() => { setModal(false) }}>
                    <Close></Close>
                </div>
                <div className='info-modal-title'>
                    {title}
                </div>
                <div className='info-modal-text'>
                    {text}
                </div>
            </div>
        </React.Fragment>
    )

}

export default InfoButton