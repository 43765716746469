import React from 'react'
import { withRouter } from 'react-router-dom'
import states from '../../../data/states.json'
import Button from '../../../components/button/button'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import BasicInput from '../../../components/basicInput/basicInput'
import get from 'lodash/get'
import AgencyContext from '../../../context/agency-context'
import './license.scss'
class PolicyLicense extends React.Component {

    static contextType = AgencyContext

    constructor(props) {
        super(props);

        this.state = {
            isError: [false, false, false],
            id: 'DRV1', // defaulted to driver 1,
            licenseNumberError: '',
            showLincenseInput: true
        }

    }

    componentDidMount = () => {
        if (this.props.values.quoteInfo) {

            const { id } = this.state
            const licenseStatus = this.props.getters.driver.LicenseStatusCd(id)
            this.setState({
                licenseStatus,
                licenseState: this.props.getters.driver.StateProvCd(id),
                licenseNumber: this.props.getters.driver.LicensePermitNumber(id),
                showLincenseInput: licenseStatus !== 'Matricula'
            })
        }
    }

    valueCheck = (value) => {
        return value === ''
    }

    licenseNumberCheck = (licenseNumber) => { // this might change for checking status
        const { licenseStatus } = this.state
        const { setters, values } = this.props

        let invalid = false
        if (licenseNumber === 'null' && licenseStatus !== 'Matricula') {
            invalid = true
            this.setState({ licenseNumberError: 'Please enter a license number' })
        } else {
            setters.driver.LicensePermitNumber(this.state.id, '')
            const driversList = get(values, 'quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver', [])
            const found = driversList.find(driver => {
                const license = get(driver, 'DriverInfo.License.LicensePermitNumber')
                return (license === licenseNumber)
            })
            if (!!found && found.DriverInfo.License.LicensePermitNumber !== '') {
                invalid = true
                this.setState({ licenseNumberError: 'A driver with that license number is already added.' })
            }
        }
        return invalid
    }

    checkAll = () => {

        const { setters } = this.props

        this.setState({
            isError: [this.valueCheck(this.state.licenseStatus), this.valueCheck(this.state.licenseState), this.licenseNumberCheck(this.state.licenseNumber)]
        }, () => {
            var errors = this.state.isError

            if (!errors.includes(true)) {
                const { id } = this.state
                setters.driver.LicenseStatusCd(id, this.state.licenseStatus)
                setters.driver.StateProvCd(id, this.state.licenseState)
                setters.driver.LicensePermitNumber(id, this.state.licenseNumber)

                this.props.history.push(`/${this.context.agencyName}/policy/home`)
            }
        })
    }

    handleGoBack = () => {
        this.props.history.push(`/${this.context.agencyName}/policy/name`)
    }

    handleLicenseStatusChange = (val) => {
        if (val === 'Matricula') {
            this.setState({ showLincenseInput: false, licenseStatus: val, licenseNumber: '' })
        } else {
            this.setState({ licenseStatus: val, showLincenseInput: true })
        }
    }

    render = () => {
        const { getters } = this.props
        const { licenseStatus, licenseState, licenseNumber, licenseNumberError, isError, showLincenseInput } = this.state
        return (
            <div className='policy-name'>
                <div className='quote-headline-two'>Enter your driver information</div>
                <div className='policy-license-block'>
                    <DropdownInput
                        label="License Status"
                        options={getters.general.licenseStatuses()}
                        defaultValue={licenseStatus}
                        onChange={this.handleLicenseStatusChange}
                        isError={isError[0]}
                        errorMessage={"Please enter your license status"}
                    >
                    </DropdownInput>
                    <DropdownInput
                        label="License State"
                        options={states}
                        defaultValue={licenseState}
                        onChange={(val) => {
                            this.setState({
                                licenseState: val
                            })
                        }}
                        isError={isError[1]}
                        errorMessage={"Please enter your license state"}>
                    </DropdownInput>
                    {showLincenseInput && <BasicInput
                        label="License Number"
                        mask={[
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                            /[A-Za-z0-9]/,
                        ]}
                        defaultValue={licenseNumber}
                        onChange={(val) => {
                            this.setState({
                                licenseNumber: val.toUpperCase(),
                            })
                        }}
                        isError={isError[2]}
                        errorMessage={licenseNumberError}>
                    </BasicInput>}
                    <div className="buttons-row">
                        <Button
                            buttonText="Back"
                            align="center"
                            onClick={this.handleGoBack} />
                        <Button buttonText="Continue" align="center" onClick={this.checkAll} />
                    </div>
                </div>
            </div>
        )

    }

}

export default withRouter(PolicyLicense)