import React from 'react'
import { Modal } from '@material-ui/core'
import Button from '../../../components/button/button'
import './errorModal.css'

const ErrorModal = (props) => {

    const { headline, isOpen, fontSize, errorText, setter } = props

    return (
        <Modal open={isOpen} hideBackdrop style={{ backgroundColor: 'rgba(0,0,0,.2)', display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div className='error-modal'>
                <div className='modal-headline' style={{ fontSize: fontSize && fontSize }}>
                    {headline}
                </div>
                <div className='error-modal-body'>
                    {errorText}
                    {props.children}
                </div>
                <Button buttonText="Close" noMargin onClick={setter} align={"Center"}></Button>
            </div>
        </Modal>
    )

}

export default ErrorModal