import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import './esign.scss'
import AgencyContext from '../../../context/agency-context'
import { registerDocusign } from '../../../helpers/analytics'
class DocuSign extends Component {

    static contextType = AgencyContext

    state = {
        docuSignURL: '',
        loaded: false,
    }

    componentDidMount() {
        this.setState({ docuSignURL: this.props.getters.general.docuSignURL() })
    }

    onLoad = () => {
        const { agencyName } = this.context
        if (this.state.loaded) {
            this.setState({
                loaded: false
            })
            this.props.history.push(`/${agencyName}/pay`)
        }
        this.setState({ loaded: true }, () => {
            registerDocusign()
        })
    }    

    render() {
        return (
                <div className='esign-parent'>
                <iframe
                    title="Esign"
                    className={`esign-iframe ${this.state.loaded ? '' : 'not-loaded'}`}
                    onLoad={this.onLoad}
                    src={this.state.docuSignURL}
                />
                {!this.state.loaded && 
                <div className="loading-progress"
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '-100px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    opacity: this.state.loaded ? '0' : '1',
                    visibility: this.state.loaded ? 'hidden' : 'visible',
                }}>
                <CircularProgress style={{height: '75px', width: '75px'}} color="primary" />
            </div>}
            </div>
        )    
    }
}
 
export default withRouter(DocuSign);
