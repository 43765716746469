import { diamondDate, getAge, isValidDate } from "../../helpers/dateHelpers"
import vinValidator from 'vin-validator'

export const validateBirthDate = (dateStr) => {
    let error;
    const strToDate = new Date(dateStr)
    if (strToDate.toString() === 'Invalid Date' || dateStr.length < 10) {
        error = "Please enter a valid birthdate"
    }
    else {

        const birthYear = strToDate.getUTCFullYear()

        if (birthYear > new Date().getUTCFullYear()) {
            error = 'Please enter a valid birthdate'
        }

        const age = getAge(strToDate, new Date())

        if (age < 16 && age >= 0) {
            error = 'You must be at least 16 years old to get a quote online'
        }
        else if (age < 0) {
            error = 'Please enter a valid birthdate'
        }
        else if (age > 150) {
            error = 'You must be no older than 150 years to get a quote online'
        }
    }

    return error

}

const validate = (values, stepToValidate) => {
    let isValid = true

    let generalErrors = {}
    let driverErrors = values.driverErrors
    let vehicleErrors = values.vehicleErrors

    let guidedErrors = values.guidedErrors;

    if (stepToValidate === 1) {

        if (!/[0-9][0-9][0-9][0-9][0-9]/.test(values.PostalCode)) {
            isValid = false
            generalErrors.PostalCode =
                'Please match the requested zipcode format'
        }

        generalErrors.BirthDt = validateBirthDate(values.BirthDt)

        isValid = generalErrors.BirthDt ? false : true

        // Gender:
        if (!values.GenderCd) {
            isValid = false
            generalErrors.GenderCd = 'Please select a gender'
        }

        // Marital Status:
        if (!values.MaritalStatusCd) {
            isValid = false
            generalErrors.MaritalStatusCd = 'Please select a marital status'
        }

    }

    if (stepToValidate === 2) {
        const request = values.quoteInfo.request
        guidedErrors.policyInformationForm = [];
        // First Name:
        if (
            request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo
                .NameInfo.PersonName.GivenName.length <= 0
        ) {
            isValid = false
            generalErrors.firstName = 'Please enter a first name'
        }
        // Last Name:
        if (
            request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo
                .NameInfo.PersonName.Surname.length <= 0
        ) {
            isValid = false
            generalErrors.lastName = 'Please enter a last name'
        }

        // Phone: Reg Ex: (###)###-####
        if (
            !/\([0-9][0-9][0-9]\)[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/.test(
                request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                    .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo
                    .Communications.PhoneInfo[2].PhoneNumber
            )
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.phone-input',
                intro: "Please match the requested phone format"
            })
        }

        // Email: Reg Ex:
        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                    .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo
                    .Communications.EmailInfo.EmailAddr
            )
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.email-input',
                intro: "Please enter a valid email"
            })
        }

        //  Address:
        if (!request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.Addr1 || request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr.Addr1.length <= 0
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.address-input',
                intro: "Please enter a valid address"
            })
        }

        // City:
        if (
            request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr
                .City.length <= 0
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.city-input',
                intro: "Please enter a valid city"
            })
        }

        // State:
        if (
            request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo.Addr
                .StateProvCd.length <= 0
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.state-input',
                intro: "Please enter a valid state"
            })
        }

        // Zipcode: Reg Ex: ######
        if (
            !/[0-9][0-9][0-9][0-9][0-9]/.test(
                request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy
                    .PersApplicationInfo.InsuredOrPrincipal.GeneralPartyInfo
                    .Addr.PostalCode
            )
        ) {
            guidedErrors.policyInformationForm.push({
                element: '.zipcode-input',
                intro: "Please enter a valid zipcode"
            })
        }

        if (!request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd || request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherInsuranceWithCompanyCd.length <= 0
        ) {
            isValid = false
            generalErrors.hasCarBeenInsured = 'Has your car been insured?'
        }
        else {

            if (request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy) {

                if (!request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName || request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.InsurerName.length <= 0) {
                    isValid = false
                    generalErrors.previousInsurer = 'Please enter your previous insurer'
                }

                if (!request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.PolicyNumber || request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.PolicyNumber.length <= 0) {
                    isValid = false
                    generalErrors.previousPolicyNumber = 'Please enter your previous policy number'
                }

                if (request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.ContractTerm && !request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.OtherOrPriorPolicy.ContractTerm.ExpirationDt) {
                    isValid = false
                    generalErrors.previousPolicyExpirationDt = 'Please enter a valid expiration date'
                }
                else {

                    if (
                        !/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/.test(request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt) &&
                        !/[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt)
                    ) {
                        isValid = false
                        generalErrors.previousPolicyExpirationDt = 'Please match the correct date format'
                    }
                }
            }

        }

        const date = new Date();

        const stringDate = diamondDate(date);

        if (
            !/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/.test(request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt) &&
            !/[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt)
        ) {
            isValid = false
            generalErrors.effectiveDate = 'Please match the requested date format'
        }
        else if (new Date(stringDate) > new Date(request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.EffectiveDt)) {

            isValid = false;
            generalErrors.effectiveDate = 'Please select a date in the future.'
        }

    }

    if (stepToValidate === 3) {
        guidedErrors.driverInformationForm = [];
        const drivers =
            values.quoteInfo.request.ACORD.InsuranceSvcRq
                .PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver

        for (let i = 0; i < drivers.length; i++) {
            let id = drivers[i]['@id']

            driverErrors[id] = {}

            // First Name:
            if (!drivers[i].GeneralPartyInfo.NameInfo.PersonName.GivenName || drivers[i].GeneralPartyInfo.NameInfo.PersonName.GivenName.length <= 0
            ) {
                guidedErrors.driverInformationForm.push({
                    element: `.${drivers[i]['@id']}-first-name-input`,
                    intro: "Please enter a first name"
                })
            }

            // Last Name:
            if (!drivers[i].GeneralPartyInfo.NameInfo.PersonName.Surname || drivers[i].GeneralPartyInfo.NameInfo.PersonName.Surname.length <= 0) {
                guidedErrors.driverInformationForm.push({
                    element: `.${id}-last-name-input`,
                    intro: "Please enter a last name"
                })
            }

            // Date of Birth:
            let isErrors = validateBirthDate(drivers[i].DriverInfo.PersonInfo.BirthDt)

            if (isErrors) {
                driverErrors[id].BirthDt = isErrors
            }

            isValid = driverErrors[id].BirthDt ? false : true

            // Gender:
            if (!drivers[i].DriverInfo.PersonInfo.GenderCd || drivers[i].DriverInfo.PersonInfo.GenderCd.length <= 0) {
                isValid = false
                driverErrors[id].gender = 'Please select a gender'
            }

            // Marital Status:
            if (!drivers[i].DriverInfo.PersonInfo.MaritalStatusCd || drivers[i].DriverInfo.PersonInfo.MaritalStatusCd.length <= 0) {
                isValid = false
                driverErrors[id].maritalStatus =
                    'Please select a marital status'
            }

            // Occupation:
            if (!drivers[i].DriverInfo.PersonInfo.OccupationDesc || drivers[i].DriverInfo.PersonInfo.OccupationDesc.length <= 0) {
                guidedErrors.driverInformationForm.push({
                    element: `.${id}-occupation-input`,
                    intro: "Please select an occupation"
                })
            }

            // Driver License Number:
            if (!drivers[i].DriverInfo.License.LicensePermitNumber || drivers[i].DriverInfo.License.LicensePermitNumber.length <= 0) {
                guidedErrors.driverInformationForm.push({
                    element: `.${id}-driver-license-number-input`,
                    intro: "Please enter a driver license number"
                })
            }

            // License Status:
            if (!drivers[i].DriverInfo.License.LicenseStatusCd || drivers[i].DriverInfo.License.LicenseStatusCd.length <= 0) {
                isValid = false
                driverErrors[id].licenseStatus = 'Please select a driver license status'
            }

            // License State:
            if (!drivers[i].DriverInfo.License.StateProvCd || drivers[i].DriverInfo.License.StateProvCd.length <= 0) {
                guidedErrors.driverInformationForm.push({
                    element: `.${id}-driver-license-state-input`,
                    intro: "Please select a driver license state"
                })
            }

            // Relationship to Policyholder:
            if (!drivers[i].PersDriverInfo.DriverRelationshipToApplicantCd || drivers[i].PersDriverInfo.DriverRelationshipToApplicantCd.length <= 0
            ) {
                guidedErrors.driverInformationForm.push({
                    element: `.${id}-relationship-to-policyholder-input`,
                    intro: "Please selct the relationship to the policyholder"
                })
            }

            // // Needs SR-22:
            if (!drivers[i].PersDriverInfo.sr22 || drivers[i].PersDriverInfo.sr22 === '') {
                isValid = false
                driverErrors[id].needSR22 = 'Please select an SR22 option'
            }

            if (drivers[i].DriverInfo.PersonInfo.MiscParty.GeneralPartyInfo.Addr.PostalCode === '') {
                isValid = false
                driverErrors[id].employerZip = "Please enter your employer's zip code"
            }

            let insuredOrPrincipal = values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.InsuredOrPrincipal

            if (Array.isArray(insuredOrPrincipal)) {
                insuredOrPrincipal = insuredOrPrincipal[0]
            }

            const checkStateIfIL = insuredOrPrincipal.GeneralPartyInfo.Addr.StateProvCd === 'IL'

            // SR-22 and Policy Term:
            if (!checkStateIfIL && drivers[i].PersDriverInfo.sr22 && drivers[i].PersDriverInfo.sr22 === "Yes" && Number(values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.ContractTerm.DurationPeriod.NumUnits) !== 12) {
                isValid = false;
                driverErrors[id].needSR22 = "Your term must be 12 MONTHS if you've selected YES for needing SR-22"
            }
        }
    }

    if (stepToValidate === 4) {
        guidedErrors.vehicleInformationForm = [];
        const vehicles =
            values.quoteInfo.request.ACORD.InsuranceSvcRq
                .PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersVeh

        for (let i = 0; i < vehicles.length; i++) {
            let id = vehicles[i]['@id']
            vehicleErrors[id] = {}

            // VIN:
            if (!vinValidator.validate(vehicles[i].VehIdentificationNumber)) {
                guidedErrors.vehicleInformationForm.push({
                    element: `.${vehicles[i]['@id']}-vin-number-input`,
                    intro: "Please enter a valid VIN number"
                })
            }

            // Year:
            if (!vehicles[i].ModelYear || vehicles[i].ModelYear.length <= 0) {
                isValid = false
                vehicleErrors[id].year = 'Please select a year'
            }

            // Make:
            if (!vehicles[i].Manufacturer || vehicles[i].Manufacturer.length <= 0) {
                isValid = false
                vehicleErrors[id].make = 'Please select a make'
            }

            // Model:
            if (!vehicles[i].Model || vehicles[i].Model.length <= 0) {
                isValid = false
                vehicleErrors[id].model = 'Please select a model'
            }

            if (!vehicles[i].PurchaseDt || vehicles[i].PurchaseDt <= 0) {
                isValid = false
                vehicleErrors[id].PurchaseDt = 'Please enter your vehicle purchase date'
            }

            // Ownership:
            if (!vehicles[i].ownership) {
                isValid = false
                vehicleErrors[id].isOwner = 'Please select an ownership option'
            }

            // Car Payments:
            if (!vehicles[i].payments) {
                isValid = false
                vehicleErrors[id].payments = 'Do you make payments on this vehicle?'
            }

            // Garaged:
            if (!vehicles[i].GaragingCd || vehicles[i].GaragingCd.length <= 0) {
                isValid = false
                vehicleErrors[id].isGaraged = 'Is this vehicle garaged at another address?'
            }
        }
    }

    if (stepToValidate === 5) {

        if (!isValidDate(new Date(values.ExpirationDt))) {
            isValid = false;
            generalErrors.ExpirationDt = 'Please enter a valid expiration date.'
        }
        else {
            if (new Date(values.ExpirationDt) < new Date()) {
                isValid = false;
                generalErrors.ExpirationDt = 'Please enter an expiration date in the future.'
            }
        }

    }

    if (stepToValidate === 6) {
        if (!values.PostalCode) {
            isValid = false
            generalErrors.PostalCode =
                'Please enter a zip code';
        }
        else if (!/[0-9][0-9][0-9][0-9][0-9]/.test(values.PostalCode)) {
            isValid = false
            generalErrors.PostalCode =
                'Please match the requested zipcode format'
        }
    }
    else if (stepToValidate === 7) {
        generalErrors.BirthDt = validateBirthDate(values.BirthDt)
        isValid = generalErrors.BirthDt ? false : true
    }
    else if (stepToValidate === 8) {
        if (!values.GenderCd) {
            isValid = false
            generalErrors.GenderCd = 'Please select a gender'
        }
    }
    else if (stepToValidate === 9) {
        if (!values.MaritalStatusCd) {
            isValid = false
            generalErrors.MaritalStatusCd = 'Please select a marital status'
        }
    }
    else if (stepToValidate === 10) { // Review Questions Status
        generalErrors.questionErrors = []

        let questions = values.quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersPolicy.QuestionAnswer
        for (var i in questions) {
            let length = questions[i].QuestionCd["@CodeListRef"].length
            let questionString = questions[i].QuestionCd["@CodeListRef"].substring(length - 23, length).trim()

            if (questionString === 'If yes, please explain.') {
                if (questions[i].Explanation === "" && questions[i].YesNoCd === 'Yes') {
                    generalErrors.questionErrors[i] = "Please enter in an explanation."
                    isValid = false;
                }
                else if (questions[i].YesNoCd === "") {
                    generalErrors.questionErrors[i] = "Please select yes or no."
                    isValid = false;
                }
            }
            else if (questionString === 'If no, please explain.') {
                if (questions[i].Explanation === "" && questions[i].YesNoCd === 'No') {
                    generalErrors.questionErrors[i] = "Please enter in an explanation."
                    isValid = false;
                }
                else if (questions[i].YesNoCd === "") {
                    generalErrors.questionErrors[i] = "Please select yes or no."
                    isValid = false;
                }
            }

        }

    }

    return ({ isValid, generalErrors, driverErrors, vehicleErrors, guidedErrors })
}

export default validate