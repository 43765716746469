import React from 'react'

import './selected.scss'
const Selected = (props) => {
    return (
        <svg
            className={props.className}
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 435.8 492.4"
            xmlSpace="preserve">
            <path
                className="pi-st0"
                style={{ fill: props.secondary }}
                d="M388.9,492.4H131.6c-12.8,0-23.2-10.4-23.2-23.2v-324c0-12.8,10.4-23.2,23.2-23.2h257.3
       c12.8,0,23.2,10.4,23.2,23.2v324C412.1,482,401.7,492.4,388.9,492.4"
            />
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="74.5" width="303.7" height="370.5" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="pi-st1"
                        style={{ fill: props.primary }}
                        d="M355,370.5H97.7c-12.8,0-23.2-10.4-23.2-23.2v-324C74.5,10.4,84.9,0,97.7,0H355c12.8,0,23.2,10.4,23.2,23.2
               v324C378.2,360,367.8,370.5,355,370.5"
                    />
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" y="86.9" width="303.7" height="370.5" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <path
                        className="pi-st2"
                        style={{ fill: props.tertiary }}
                        d="M280.5,457.4H23.2C10.4,457.4,0,446.9,0,434.2v-324c0-12.8,10.4-23.2,23.2-23.2h257.3
               c12.8,0,23.2,10.4,23.2,23.2v324C303.7,446.9,293.3,457.4,280.5,457.4"
                    />
                </g>
            </g>
            <path
                className="pi-st3"
                style={{ fill: props.combined }}
                d="M303.7,434.2V122H131.6c-12.8,0-23.2,10.4-23.2,23.2v312.2h172.1C293.3,457.4,303.7,446.9,303.7,434.2z"
            />
            <path
                className="pi-st4"
                d="M322.2,429.3H64.9c-12.8,0-23.2-10.4-23.2-23.2v-324c0-12.8,10.4-23.2,23.2-23.2h257.3
       c12.8,0,23.2,10.4,23.2,23.2v324C345.4,418.9,334.9,429.3,322.2,429.3"
            />
            <path
                className="pi-st5"
                style={{ fill: props.combined }}
                d="M432,67.6c6.4-8.4,4.7-20.6-3.7-27c-8.4-6.4-20.6-4.7-27,3.7l-3.1,4.1l30.7,23.3L432,67.6z"
            />
            <rect
                x="151.4"
                y="174.5"
                transform="matrix(0.6045 -0.7966 0.7966 0.6045 -30.9081 325.2289)"
                className="pi-st5"
                style={{ fill: props.combined }}
                width="321.3"
                height="38.5"
            />
            <polygon
                className="pi-st5"
                style={{ fill: props.combined }}
                points="208.8,330.3 193.2,318.5 188,341.6 182.7,364.7 203.5,353.4 224.3,342.1 "
            />
            <line
                className="pi-st6"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                x1="81"
                y1="116"
                x2="310.3"
                y2="116"
            />
            <line
                className="pi-st6"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                x1="81"
                y1="180.9"
                x2="310.3"
                y2="180.9"
            />
            <line
                className="pi-st6"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                x1="81"
                y1="244.1"
                x2="310.3"
                y2="244.1"
            />
            <line
                className="pi-st6"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                x1="81"
                y1="307.2"
                x2="310.3"
                y2="307.2"
            />
            <line
                className="pi-st6"
                style={{
                    fill: 'none',
                    stroke: props.combined,
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                }}
                x1="81"
                y1="370.4"
                x2="310.3"
                y2="370.4"
            />
            <rect className="pi-st7" width="435.8" height="492.4" />
            <rect className="pi-st7" width="435.8" height="492.4" />
        </svg>
    )
}
export default Selected
