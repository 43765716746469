import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PhysicalDamageIcon } from '../../../../assets/coverages/physical-damage.svg'

const PhysicalDamage = ({ color = '#7F1819' }) => {
  return (
    <PhysicalDamageIcon fill={color} />
  )
}

PhysicalDamage.propTypes = {
  color: PropTypes.string
}

export default PhysicalDamage