import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as BodilyInjuryIcon } from '../../../../assets/coverages/bodily-injury.svg'

const BodilyInjury = ({ color = '#7F1819' }) => {
  return (
    <BodilyInjuryIcon fill={color} />
  )
}

BodilyInjury.propTypes = {
  color: PropTypes.string
}

export default BodilyInjury