import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as RoadsideAssistanceIcon } from '../../../../assets/coverages/roadside-assistance.svg'

const RoadsideAssistance = ({ color = '#7F1819' }) => {
  return (
    <RoadsideAssistanceIcon fill={color} />
  )
}

RoadsideAssistance.propTypes = {
  color: PropTypes.string
}

export default RoadsideAssistance