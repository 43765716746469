import React, { useEffect, useState } from 'react'
import './basicInput.scss'
import maskedInputCustom from '../maskedInputCustom/maskedInputCustom'
import { FormLabel, Input, FormHelperText } from '@material-ui/core'
import PropTypes from 'prop-types'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertShortDate } from "../../helpers/dateHelpers"

// we pass in validation and isError to detect errors before quoting

const BasicInput = (props) => {
    const { isError, label, mask, errorMessage, defaultValue, type, onChange, valueFront, placeholder } = props

    const [value, setValue] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        }
        if (defaultValue === '') {
            setValue(defaultValue)
        }
    }, [defaultValue])

    useEffect(() => {
        if (valueFront) {
            setValue(valueFront)
        }
        if (valueFront === '') {
            setValue(valueFront)
        }
    }, [valueFront])

    const validateValue = (val) => {
        if (type !== 'date') {
            onChange(val)
            setValue(val)
            return
        }
        const date = convertShortDate(val)
        if (!!date && date.getFullYear() < 10000) {
            onChange(val)
            setValue(val)
        }
    }

    return (
        <div className="basic-input">
            <div className="input-label">
                <FormLabel>{label}</FormLabel>
                {props.children && props.children}
            </div>
            <Input
                disableUnderline
                spellCheck={false}
                name={label}
                type={type ? type : 'text'}
                value={valueFront ? valueFront : value}
                onChange={(e) => {
                    validateValue(e.target.value)
                }}
                placeholder={placeholder ? placeholder : ''}
                classes={{ input: type === 'date' ? 'custom-input-date' : 'custom-input' }}
                inputComponent={mask && maskedInputCustom}
                inputProps={{ mask: mask && mask }}></Input>
            {props.showImageInputRigth && (
                <img className={'custom-input-image-rigth'} src={props.pathImageInputRigth} alt={''} />
            )}
            {props.showIconCorrect && props.isCorrect && (
                <FontAwesomeIcon icon={faCheckCircle} size={'1x'} className={'custom-input-icon-success'} />
            )}
            {props.showIconCorrect && !props.isCorrect && (
                <FontAwesomeIcon icon={faTimesCircle} size={'1x'} className={'custom-input-icon-error'} />
            )}
            <div className="helper">
                <div className="sub-helper" style={{ opacity: isError ? '1' : '0' }}>
                    <FormHelperText error>{errorMessage}</FormHelperText>
                </div>
            </div>
        </div>
    )
}

BasicInput.displayName = 'BasicInput'
BasicInput.propTypes = {
    isError: PropTypes.bool,
    label: PropTypes.string,
    mask: PropTypes.any,
    errorMessage: PropTypes.string,
    defaultValue: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.string,
    valueFront: PropTypes.string,
}

export default BasicInput
