import React from 'react'
import { withRouter } from 'react-router-dom'
import AgencyContext from '../../../context/agency-context'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import BasicInput from '../../../components/basicInput/basicInput'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import RadioInput from '../../../components/radioInput/radioInput'
import InfoButton from '../../../components/infoButton/infoButton'
import Button from '../../../components/button/button'
import './driverMissingInfo.scss'
import states from '../../../data/states.json'

class DriverMissingInfo extends React.Component {
  static contextType = AgencyContext

  state = {
    drivers: [],
    residenceOwnedCD: '',
    residenceOwnedCDError: false,
  }

  componentDidMount() {
    const { getters } = this.props
    const drivers = getters.general.getDrivers()
    const residenceOwnedCD = getters.policy.ResidenceCd()

    const driversInfo = drivers.map((driver, i) => {
      const name = `${get(driver, 'GeneralPartyInfo.NameInfo.PersonName.GivenName')} ${get(driver, 'GeneralPartyInfo.NameInfo.PersonName.Surname')}`
      const StateProvCd = get(driver, 'DriverInfo.License.StateProvCd', '')
      const LicenseStatus = get(driver, 'DriverInfo.License.LicenseStatusCd', '')
      const LicensePermitNumber = get(driver, 'DriverInfo.License.LicensePermitNumber', '')
      const sr22 = get(driver, 'PersDriverInfo.sr22', '')
      const showLicenseNumberInput = LicenseStatus !== 'Matricula'

      return {
        id: driver['@id'],
        name,
        LicensePermitNumber,
        StateProvCd,
        LicenseStatusCd: LicenseStatus === 'Active' ? 'Valid' : LicenseStatus,
        sr22,
        errors: [false, false, false, false],
        showLicenseNumberInput
      }
    })

    this.setState({ drivers: driversInfo, residenceOwnedCD })
  }

  render() {
    const { getters } = this.props
    const { drivers, residenceOwnedCD, residenceOwnedCDError } = this.state
    const licenseStatuses = getters.general.licenseStatuses()

    const driversList = drivers.length > 0 && drivers.map((driver, i) => {
      return (
        <div key={`driver-${i}`} className="driver-item">
          <div className="driver-name-header">{`Driver: ${driver.name}`}</div>
          {driver.showLicenseNumberInput && <BasicInput
            label="License Number"
            defaultValue={driver.LicensePermitNumber}
            onChange={(value) => this.debouncedInpuChange({ driverIndex: i, id: driver.id, value, name: 'LicensePermitNumber' })}
            isError={driver.errors[0]}
            errorMessage="Please enter a license number"
          />}
          <DropdownInput
            label="License State"
            options={states}
            defaultValue={driver.StateProvCd == "CW" || driver.StateProvCd == "NA" ? "" : driver.StateProvCd}
            onChange={(value) => this.debouncedInpuChange({ driverIndex: i, id: driver.id, value, name: 'StateProvCd' })}
            valueFront={driver.StateProvCd == "CW" || driver.StateProvCd == "NA" ? "" : driver.StateProvCd}
            isError={driver.errors[1]}
            errorMessage={'Please enter a license state'}>
          </DropdownInput>
          <DropdownInput
            label="License Status"
            options={[""].concat(licenseStatuses)}
            defaultValue={driver.LicenseStatusCd == null ? "" : driver.LicenseStatusCd}
            valueFront={driver.LicenseStatusCd == null ? "" : driver.LicenseStatusCd}
            onChange={(value) => this.handleLicenseStatus({ driverIndex: i, id: driver.id, value })}
            isError={driver.errors[2]}
            errorMessage={'Please enter your license status'}
          />
          <div className="policy-input-radio-block">
            <RadioInput
              inLine={true}
              label="Do you need SR22?"
              defaultValue={driver.sr22}
              valueFront={driver.sr22}
              onChange={(value) => this.debouncedInpuChange({ driverIndex: i, id: driver.id, value, name: 'sr22' })}
              isError={driver.errors[3]}
              errorMessage={'Please enter if you need SR22 Coverage'}>
              <InfoButton
                title="What is SR22?"
                text="An SR22 form is an official document that proves that you've purchased the minimum liability insurance available in your state."
              />
            </RadioInput>
          </div>
          <hr />
        </div>
      )
    })

    return (
      <div className="driver-missing-info">
        <h2>Required Information</h2>
        <DropdownInput
          defaultValue={residenceOwnedCD}
          label="Do you own or rent your home?"
          options={[
            { value: '', label: '' },
            { value: 'OWNED', label: 'Own' },
            { value: 'RENTD', label: 'Rent' },
          ]}
          onChange={(val) => {
            this.setState({
              residenceOwnedCD: val,
            })
          }}
          isError={residenceOwnedCDError}
          errorMessage={'Please enter in your home ownership status'}
        />
        <hr />
        {driversList}
        <div>
          <Button
            buttonText="Continue"
            onClick={this.handleContinue}
          />
        </div>
      </div>
    )
  }

  handleInputChange = driverData => {
    const { setters } = this.props
    const { drivers } = this.state
    const { id, name, value, driverIndex } = driverData

    switch (name) {
      case 'LicensePermitNumber':
        setters.driver.LicensePermitNumber(id, value)
        break;
      case 'StateProvCd':
        setters.driver.StateProvCd(id, value)
        break;
      case 'sr22':
        setters.driver.SR22(id, value)
        break;
      default:
        break;
    }

    let driversArrUpdate = [...drivers]
    driversArrUpdate[driverIndex][name] = value

    this.setState({ drivers: driversArrUpdate })
  }

  debouncedInpuChange = debounce((e) => {
    this.handleInputChange(e)
  }, 300)

  handleLicenseStatus = ({ driverIndex, id, value }) => {
    const { setters } = this.props
    const { drivers, } = this.state

    const showLicenseNumberInput = value !== 'Matricula'
    let driversArrUpdate = [...drivers]
    const updateLicenseNumber = showLicenseNumberInput ? driversArrUpdate[driverIndex]['LicensePermitNumber'] : 'matricula'

    driversArrUpdate[driverIndex]['LicenseStatusCd'] = value
    driversArrUpdate[driverIndex]['LicensePermitNumber'] = updateLicenseNumber
    driversArrUpdate[driverIndex]['showLicenseNumberInput'] = showLicenseNumberInput
    driversArrUpdate[driverIndex].errors[0] = false
    setters.driver.LicenseStatusCd(id, value)
    setters.driver.LicensePermitNumber(id, value)
    this.setState({ drivers: driversArrUpdate })
  }

  handleContinue = (evt) => {
    const { history, setters, getters } = this.props
    const { residenceOwnedCD } = this.state
    const { agencyName } = this.context

    const canRedirect = this.validateFields()
    if (canRedirect) {
      setters.policy.ResidenceCd(residenceOwnedCD)
      getters.policy.ResidenceCd() === "OWNED" ? setters.policy.ResidenceTypeCd("DW") : setters.policy.ResidenceTypeCd()
      history.push(`/${agencyName}/leasing`)
    }
  }

  validateFields = () => {
    const { drivers, residenceOwnedCD } = this.state
    let valid = true
    let updateDrivers = [...drivers]
    const errors = updateDrivers.reduce((hasErrors, driver) => {
      if (driver.LicenseStatusCd !== 'Matricula' && !driver.LicensePermitNumber) {
        driver.errors[0] = true
        hasErrors.push(true)
      } else {
        driver.errors[0] = false
      }
      if (!driver.StateProvCd || driver.StateProvCd == "NA" || driver.StateProvCd == "CW") {
        driver.errors[1] = true
        hasErrors.push(true)
      } else {
        driver.errors[1] = false
      }
      if (!driver.LicenseStatusCd) {
        driver.errors[2] = true
        hasErrors.push(true)
      } else {
        driver.errors[2] = false
      }
      if (!driver.sr22) {
        driver.errors[3] = true
        hasErrors.push(true)
      } else {
        driver.errors[3] = false
      }
      return hasErrors
    }, [])

    if (!residenceOwnedCD || errors.includes(true)) {
      this.setState({ residenceOwnedCDError: !residenceOwnedCD, drivers: updateDrivers })
      valid = false
    }

    return valid
  }
}

export default withRouter(DriverMissingInfo)