import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import './locationInput.scss'
import { FormHelperText, FormLabel, Input } from '@material-ui/core'
import themes from '../../themes'
class LocationInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            zipCode: '',
            state: '',
            city: '',
            hovered: [false, false, false, false, false],
        }
    }

    handleChange = (address) => {
        const { clearAddressAcord = () => { } } = this.props
        if (address.length === 0 || address === '' || address === undefined) {
            this.setState(
                {
                    address: '',
                    zipCode: '',
                    state: '',
                    city: '',
                },
                () => {
                    this.props.selectAddress('')
                    this.props.selectCity('')
                    this.props.selectState('')
                    this.props.selectZip('')
                }
            )
            clearAddressAcord()
        }

        this.setState({
            address: address,
        })
    }

    handleSelect = (address) => {
        geocodeByAddress(address).then((results) => {
            this.parseAddress(results[0])
        })
    }

    parseAddress = (results) => {
        const address = results.formatted_address

        this.setState(
            {
                address: address,
            },
            () => {
                this.props.selectAddress(this.state.address)
            }
        )

        for (var i = 0; i < results.address_components.length; i++) {
            if (results.address_components[i].types['0'] === 'locality') {
                try {
                    this.setState(
                        {
                            city: results.address_components[i].long_name,
                        },
                        () => {
                            this.props.selectCity(this.state.city)
                        }
                    )
                } catch {
                    this.setState(
                        {
                            city: results.address_components[i].short_name,
                        },
                        () => {
                            this.props.selectCity(this.state.city)
                        }
                    )
                }
            }
            if (results.address_components[i].types['0'] === 'postal_code') {
                try {
                    this.setState(
                        {
                            zipCode: results.address_components[i].long_name,
                        },
                        () => {
                            this.props.selectZip(this.state.zipCode)
                        }
                    )
                } catch {
                    this.setState(
                        {
                            zipCode: results.address_components[i].short_name,
                        },
                        () => {
                            this.props.selectZip(this.state.zipCode)
                        }
                    )
                }
            }
            if (results.address_components[i].types['0'] === 'administrative_area_level_1') {
                this.setState(
                    {
                        state: results.address_components[i].short_name,
                    },
                    () => {
                        this.props.selectState(this.state.state)
                    }
                )
            }
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <PlacesAutocomplete
                    value={this.state.address || this.props.valueFront}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    googleCallbackName="googleMaps">
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div className="address-input">
                            <div className="input-label">
                                <FormLabel>{this.props.label ? this.props.label : 'Company Address'}</FormLabel>
                                {this.props.children && this.props.children}
                            </div>
                            <Input
                                spellCheck={false}
                                disableUnderline
                                {...getInputProps({
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, key) => {
                                    const className = 'suggestion-item'
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                            onMouseOver={() => {
                                                let hoveredStates = [false, false, false, false, false]
                                                hoveredStates[key] = true
                                                this.setState({
                                                    hovered: hoveredStates,
                                                })
                                            }}
                                            onMouseOut={() => {
                                                const hoveredStates = this.state.hovered
                                                hoveredStates[key] = false
                                                this.setState({
                                                    hovered: hoveredStates,
                                                })
                                            }}
                                            key={suggestion.placeId}
                                            style={{
                                                backgroundColor:
                                                    (key === 0 || this.state.hovered[key]) &&
                                                    themes.main.mui.palette.primary.main,
                                                color: (key === 0 || this.state.hovered[key]) && '#ffffff',
                                            }}>
                                            <div className="suggestion-name">{suggestion.description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <div className="helper">
                    <div className="sub-helper" style={{ opacity: this.props.errorMessage ? '1' : '0' }}>
                        <FormHelperText error>{this.props.errorMessage}</FormHelperText>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LocationInput
