const pubkeyPGP = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFyeRzgBEADEfEahj9rF3JWD3BcAtrm2HX/JZue3yIjUFugvRWPJOUSkRtDP
IHCxUgjkO1R1mAwgSwvJCKnfljiGAn0/h0Eik7mAdvF0DyB1//+jFCAy+COpX34k
KqW3m3GfCli8QNyPGwHY5V8oPKOMh189lK3w8g7KpKnpOUqOfwMfCVmXUU7DtrIx
I4AVpXCOl2tDvK21J2k5bzbhwnn5K+HRtqOXU4w4DE7VVdWY4ccMbO923Lw8aDta
DEDvXqfMt39s3tX8a8nAB47GL0rBiGrigm+eYvpmjQIoAWgDS2YgxmgLz+Tv2jap
hJyW75ix1fLY/RmP0SSBqvlOHHqYlNyCS3JE1C+u7lY5yeKFyFEQdMtikllQK7re
wUgQ1u/4/6c8j54Mt/ywR+Z42dRn7tHEZABbHxmE6jYrRkE0QpF0tSBJx+EBZOq9
r1gCb/tXKWQe0/vajQJn2Aq11ZTdgXeRH/cSizfezoSLl23mjsxkEgzLB3Ombs36
UbnbGEEBle7AgCfPBKNS3Ebr2oZhNujS3zC2B65vZK9UtDfIVqibpQ5DZxup2mBa
pBwJvoKdb1Psqa28fKQUW7UVAw3q/0xifvukeVG0DwWe8i7iXmRRJMfC1uKRRZJr
DHcrpuqpl7HKic8ryNxgVDTyd2QFnphlEs6OXbMxz1+RhD/vB4SROztRuwARAQAB
tEF1bmlxdWVpbnN1cmFuY2UgKFF1b3RpbmcgUG9ydGFsKSA8ZGtpcmlsb3ZAdW5p
cXVlaW5zdXJhbmNlY28uY29tPokCTgQTAQgAOBYhBDK4zHhW9kllNG42tQxm0vdm
tcduBQJcnkc4AhsDBQsJCAcCBhUICQoLAgQWAgMBAh4BAheAAAoJEAxm0vdmtcdu
+OwP/jNfh3Bi3xGvxoKGI1zbeRbC92SX7xY9vVjihcHcY1Whw8FD1JtHaOjKwI1P
ZKhW/ceDnYrA7gBqjB7fcezeGAjN4SlzoCT+cvioNoQ2EWrAGsfhHQWs3icE7uKT
VRYYo8iengM3j0d5HOK9A2g9iYQdhGfVkeVrnWgEsASjmgK7t7SanBrToNn187t6
lx4vUcje0A+z314Iu0eghIhX7MpVRzAmByMQXAXdLvsFyXSEss9ZHd3+bvPKikcD
rFjnRqN+xnSHgKYg8LHYj3sml2x1pHbk3Y4kU3Ld3Leg3Wh7kXwxYmNWcSLmZFkL
sSSITdnniteaaeSPJw+7tpOrtsdi5sTX9Jbc2U46isZoi6AOpowqtncQ6sUd42Dj
DnL3DCYsmqoZK9h9cLaV9zZvVhAF6NnvHj32KpNLKrjgFGlm4YmExvo48WlDebTM
t5ohXiEZPOzMgXCw/1QB6SsJ3k23sl5LgBqUArsyq57htxuyrzNFinaPBrb5WMMx
0yBhHeistGn/chlLVCsWsJVtMfEUYEbLSxgHiB54ahfrvN8xV32RShPlGvFPsiw8
f//VUKySQ8G3Vpg7lmiKBkfujIOlFV1d9mOMArSlMJJDQ7SYSnZEq8V7ONneZrAQ
KlE0NI/TS+NwHh3UWd9Rmu5Bdw1jboQ0XaYmFyk6Yl7b0iONuQINBFyeRzgBEACU
185IJSmQEeOkxq1VPwuZtliKr8I4GBRH/9l9V7KwovoenatBHfH/qtAzi0wyfV5a
mXLrRyOwaF1S2PK/gr98FRtofNZAUgAVA7YxXB/TFQ5hq67ZNAdEWVusFGnAqerS
e1RJAxSXnN17+7dlm3mUK/TfwgeyGj+pZfA6U/zzDpToeVX9+tD0RXwUXfQ8RyOn
2IDGjaqMakNU3UXz1eYWpBtpjNDRbi+0Db2cEbbKkr3i/yj2tPu4K2TsQDEkvPHx
uqyQ3nW13vmY/DIKB0YMmBd68EwSxH4ocQAluXGOUw4hFYwExQIhuc77V7J7g+Ku
WkUWhktLcWTI6ATd6jfkUVk28lQjs1zayeIAL76FrCLqUn7A8pVRQiz7ywstbgAx
Px7MR0fb40/BHtgeuJ1SNj8oWnKOEEruBuYWEQJyWY2Rm0jr9ns5IuKEzOR5rmlx
T35W0/ZJVJuHzlgBNLjEZpPVuVeEmtJho5GsgMUGhyLsP+y2ESbKRG0WPOKb6mvs
xMjuigCmMWmdCOdM93XRdClG+kIAc3DIWNZWsV2BW2fhGP5VvVnBgG8jlkKzHuN5
qw9gwsLFV5Rkt6fvnMFWdBp8B/DxquIxD+1/c/nQyGtYBlSbv7XmmRDjSDqD6KYM
Ec0xEFbrIaKkBndprEGfNZtjGOXNln5oKo3ZsOZFIwARAQABiQI2BBgBCAAgFiEE
MrjMeFb2SWU0bja1DGbS92a1x24FAlyeRzgCGwwACgkQDGbS92a1x24KOhAAsU0O
ZL2BFDf9jAtr8XWn6xncL+oj8BvlvEvn2RstJSit637WqwBASDwhaui6kma/NC5Y
o4vw32+hBcg2UsitL1VUfNNc2ij8aLIG8Uo5xe1tVjihcYs1/fPFvNgHOIQbCdcn
BdUa72WAglNQQQuq9SU2Xklm61KeKhUp6Y1d+UGk3ILwpIXe6YotobBvGwFqep97
/neg5WwLFIHkuXEiObgo+RvLfHdkZ3isxerX0yJjCVkc3O4/tgQEyESHlzCqj1Rw
r6dHF7bnGtYeLQCZi3MwWgHH3xJVSqRAXXWLMP80rELrkJNxxpnCZs2kn+CIWnwf
r+40L48+SmV3UBMbT9unGooTfUM1yuGRc12GBITVR5IaUDb8eiC5NQpPSfEhb07W
48IGaRRc5/jTxt/eYqQZtoCfiYBdGHQe8cWmU8ItPocJn/wlDnFNlIAhAnXlQLBb
RTguk0Ju/TTwWegf11kdwX0P3m+og90rqBCWe+WDFEEC/bC6eCeWzPcM5P8PDXGG
caHS8KnB01gL/MIaKugFx03pxBKdKYGlqD8db541dPa+yCDAaQI4P0OcfBvHJRNu
VWLDK8Vxw2L7AexsYitD1/Fyz//Km8zDuKgUJJwEvPrwfF9w4xksHoXi5QLLrOsU
UdP6uJwWUCdFFzCLXB0Y6yUiCeJxMbNJqycx5N4=
=lyvV
-----END PGP PUBLIC KEY BLOCK-----`

export default pubkeyPGP