import React from 'react'
import { withRouter } from 'react-router-dom'
import CombinedInput from '../../../components/combinedInput/combinedInput'
import './start.css'
import { CircularProgress } from '@material-ui/core'
import AgencyContext from '../../../context/agency-context'
import { validateZipCode, getAuth } from '../../../helpers/endpoints'
import localStorageService from '../../../helpers/localStorage'
import { keysStorage } from '../../../helpers/constants'

class Start extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            errorMessage: 'Please enter a valid zip code',
            isError: false,
        }
    }

    validation = (zip) => {
        if (!/[0-9][0-9][0-9][0-9][0-9]/.test(zip)) {
            return false
        } else {
            return true
        }
    }

    submit = async (zip) => {
        this.setState({
            isLoading: true,
        })

        const response = await validateZipCode(this.context.agencyName, zip, this.context.agencyId)

        this.context.agencyId = response.agency_code

        if (response.message) {
            this.setState({
                isError: true,
                isLoading: false,
                errorMessage: response.message,
            })

            return true
        } else {
            this.props.handlerClearState()

            this.setState({
                isLoading: false,
                isError: false,
            })

            this.context.agencyId = response.agency_code

            this.props.setters.general.SelectedZip(zip)

            getAuth(this.context.agencyId).then((response) => {
                this.props.setters.general.Auth(response)
            })

            localStorageService.removeItem(keysStorage.TIME_IN_SCREEN)
            localStorageService.saveInStorage(keysStorage.TIME_IN_SCREEN, new Date())
            this.props.history.push(`/${this.context.agencyName}/name`)
        }
    }

    render = () => {
        return (
            <div className="start">
                {this.context.isEditable ?
                    <React.Fragment>
                        <div className="quote-headline">Let's get started on your quote</div>
                        <div className='start-block'>
                            <CombinedInput
                                label="Zip Code"
                                buttonText="Start"
                                validateOnChange
                                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                validation={this.validation}
                                submit={this.submit}
                                errorWidth="291px"
                                errorMessage={this.state.errorMessage}></CombinedInput>
                            <div
                                className="loading-progress"
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    marginBottom: '25px',
                                    opacity: this.state.isLoading ? '1' : '0',
                                    visibility: this.state.isLoading ? 'visible' : 'hidden',
                                }}>
                                <CircularProgress color="primary" />
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <div className="quote-headline">Please contact your agent to start a quote</div>
                }
            </div>
        )
    }
}

export default withRouter(Start)
