import React, { useEffect, useRef, useState } from 'react';
import '../dynamicheader/dynamic-header.scss';
import './formheader.css';

const FormHeader = (props) => {

    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);


    return (
        <React.Fragment>
            <div className={`form-header ${isSticky ? 'sticky' : ''}`} ref={ref}>
                <div className='entrance-header' style={{ backgroundColor: '#ffffff' }}>
                    <div className='header-full'>
                        <div className='entrance-padding'>
                            <div className='centered-logo-s'>
                                <div className='agency-logo'>
                                    <a href={props.agencyInfo.website}>
                                        <img src={props.agencyInfo.logo} id="logo2" alt="agency logo"></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FormHeader