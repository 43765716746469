import React from 'react';
import vinValidator from 'vin-validator';
import { withRouter } from 'react-router-dom';
import AgencyContext from '../../../context/agency-context';
import Button from '../../../components/button/button';
import { vin, years, makes, models } from '../../../helpers/endpoints';
import AddVehicle from '../addVehicle/addVehicle';
import { RadioGroup } from '@material-ui/core';
import { ReactComponent as VehicleUnselected } from '../../../assets/steps/VehicleUnselected.svg';
import { registerAddVehicle } from '../../../helpers/analytics';
import './vinLookup.scss';

class VinLookup extends React.Component {
    static contextType = AgencyContext

    constructor(props) {
        super(props);

        this.state = {
            id: undefined,
            vehicleYear: 0,
            vehicleMake: '',
            vehicleModel: '',
            vinErrorMessage: '',
            showVin: false,
            vinInformation: false,
            vinFetch: undefined,
            yearList: [],
            modelList: [],
            makeList: []
        };
    }

    generateLists = async () => {
        const vehicleYear = this.state.vehicleYear || '';
        const vehicleMake = this.state.vehicleMake || '';
        const yearList = await years();
        const makeList = (vehicleYear && await makes(vehicleYear)) || [];
        const modelList = (vehicleMake && await models(vehicleYear, vehicleMake)) || [];
        this.setState({
            yearList,
            modelList,
            makeList,
        });
    }

    componentDidMount = async () => {
        if (this.props.getters.general.SelectedVehicle()) {
            await this.setState({
                id: this.props.getters.general.SelectedVehicle(),
            }, () => {
                const year = this.props.getters.vehicle.ModelYear(this.state.id);
                const make = this.props.getters.vehicle.Manufacturer(this.state.id);
                const model = this.props.getters.vehicle.Model(this.state.id);

                this.setState({
                    vehicleYear: year,
                    vehicleMake: make,
                    vehicleModel: model,
                })
            });
        } else {
            this.setState({ id: 'VEH1' });
            this.props.setters.general.SelectedVehicle('VEH1');
        }
        this.generateLists();
    }

    validation = async (vinNumber) => {
        if (!vinValidator.validate(vinNumber)) {
            this.setState({
                vinErrorMessage: 'Please enter a valid VIN number',
                vinInformation: false,
            });
            return false;
        }
        const vehicles = this.props.getters.general.getVehicles()
        if (vehicles.some(x => x.VehIdentificationNumber === vinNumber)) {
            this.setState({
                vinErrorMessage: 'The VIN number already exists',
                vinInformation: false,
            });
            return false;
        }
        const auth = this.props.getters.general.Auth()
        const vinInformation = await vin(vinNumber, auth);
        const vinErrorMessage = vinInformation ? '' : 'Vin number not found';
        this.setState({
            vinErrorMessage,
            vinInformation,
        });
        return vinInformation;
    }

    submit = (value) => {
        this.setState({
            vinFetch: value,
        });
    }

    setVinInformation = () => {
        const { id, vehicleYear, vehicleMake, vehicleModel } = this.state
        if (this.state.id) {
            this.props.setters.vehicle.ModelYear(id, vehicleYear);
            this.props.setters.vehicle.Manufacturer(id, vehicleMake);
            this.props.setters.vehicle.Model(id, vehicleModel);
            registerAddVehicle(vehicleYear, vehicleMake, vehicleModel)
            this.props.history.push(`/${this.context.agencyName}/vehicles`);
        }
    }

    goBack = () => {
        this.props.history.push(`/${this.context.agencyName}/address`)
    }

    proceed = () => {
        return this.setVinInformation();
    }

    renderSection = () => ((
        <AddVehicle
            setVehicleInformation={this.setVehicleInformation}
            vehicleMake={this.state.vehicleMake}
            vehicleModel={this.state.vehicleModel}
            vehicleYear={this.state.vehicleYear}
            makeList={this.state.makeList}
            modelList={this.state.modelList}
            yearList={this.state.yearList.reverse()}
        />
    )
    )

    setVehicleInformation = (name, value) => {
        this.setState({ [name]: value });
    }

    isContinueButtonDisabled = () => {
        const {
            showVin,
            vehicleModel,
            vinInformation,
        } = this.state;
        if (!showVin) {
            return !vehicleModel;
        }
        return !vinInformation;
    }

    render = () => {
        return (
            <div className='vin-lookup'>
                <div className='quote-headline-two' style={{ fontSize: '32px' }}>Add a Vehicle</div>
                <RadioGroup className='choose-option'>
                    <div
                        className={`option-tab vehicle-option option-tab-selected`}
                        onClick={() => this.setState({ showVin: false })}
                    >
                        <VehicleUnselected fill='#CCCCCC' className='icon-image vehicle-icon' />
                        <div className='vin-label'>
                            <div>Enter your vehicle</div>
                            <div>information</div>
                        </div>
                        <div className='vin-option-triangle' />
                    </div>
                </RadioGroup>
                {this.renderSection()}
                <div className='buttons-row' style={{ marginBottom: '20px' }}>
                    <Button
                        align='center'
                        buttonText='Back'
                        onClick={this.goBack}
                        width='120px'
                    />
                    <Button
                        align='center'
                        buttonText='Save and Continue'
                        onClick={this.proceed}
                        width='250px'
                        disabled={!this.state.vehicleModel}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(VinLookup)