import React from 'react'
import { withRouter } from 'react-router-dom'
import Edit from '@material-ui/icons/EditRounded'
import get from 'lodash/get'
import MediaQuery from 'react-responsive'
import themes from '../../../themes'
import Button from '../../../components/button/button'
import AgencyContext from '../../../context/agency-context'
import { formatDateString } from '../../../helpers/dateHelpers'
import BodilyInjuryIcon from '../../../components/icons/quoteReview/BodilyInjury'
import PhysicalDamage from '../../../components/icons/quoteReview/PhysicalDamage'
import RoadsideAssistance from '../../../components/icons/quoteReview/RoadsideAssistance'
import UninsuredMotorist from '../../../components/icons/quoteReview/UninsuredMotorist'
import TabPanel from '../../../components/TabPanel'
import './reviewPolicyInfo.scss'
import { quoteDocs } from '../../../helpers/endpoints'
import { saveAs } from 'file-saver'
import LoadingModal from '../../../components/modal/modal'
import ErrorModal from '../../../components/modal/error/errorModal'
class ReviewPolicyInfo extends React.Component {
  state = {
    selectedTab: '1',
    isLoading: false,
    downloadError: false
  }

  static contextType = AgencyContext

  componentDidMount = async () => {
    const { setters } = this.props
    setters.general.EditFromReviewScreen(false)
  }

  getMaritalStatus = code => {
    switch (code) {
      case 'M':
        return 'Married'
      case 'D':
        return 'Divorced'
      case 'W':
        return 'Widowed'
      default:
        return 'Single';
    }
  }

  getCoverageIcon = (coverageName, coverage, iconId, index) => {
    const primary = themes.main.mui.palette.primary.main
    const secondary = themes.main.mui.palette.secondary.main
    const tertiary = themes.main.mui.palette.tertiary.main
    const combined = themes.main.mui.palette.combined.main

    switch (iconId) {
      case 'PD':
        return (
          <div className="iconDiv" key={index}>
            <PhysicalDamage key={index} {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {`${coverageName}`}<br />
              {`$${parseInt(!Array.isArray(coverage.Limit) ? coverage.Limit.FormatCurrencyAmt.Amt : coverage.Limit[0].FormatCurrencyAmt.Amt)}`}
            </div>
          </div>)
      case 'BI':
        return (
          <div className="iconDiv" key={index}>
            <BodilyInjuryIcon {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {`${coverageName}`}<br />
              {`$${parseInt(coverage.Limit[1].FormatCurrencyAmt.Amt)}/$${parseInt(coverage.Limit[0].FormatCurrencyAmt.Amt)}`}
            </div>
          </div>)
      case 'UM':
        return (
          <div className="iconDiv" key={index}>
            <UninsuredMotorist {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {`${coverageName}`}<br />
              {`$${parseInt(coverage.Limit[1].FormatCurrencyAmt.Amt)}/$${parseInt(coverage.Limit[0].FormatCurrencyAmt.Amt)}`}
            </div>
          </div>)
      case 'RGP,RSA,NSDRSA':
        return (
          <div className="iconDiv" key={index}>
            <RoadsideAssistance {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {coverageName}
            </div>
          </div>)
      case 'PIP':
        return (
          <div className="iconDiv" key={index}>
            <BodilyInjuryIcon {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {`${coverageName}`}<br />
              {`$${parseInt(coverage.Limit.FormatCurrencyAmt.Amt)}`}
            </div>
          </div>)
      case 'MEDPM':
        return (
          <div className="iconDiv" key={index}>
            <BodilyInjuryIcon key={index} {...{ primary, secondary, tertiary, combined }} />
            <div className="coverage-icon-name">
              {`${coverageName}`}<br />
              {`$${parseInt(!Array.isArray(coverage.Limit) ? coverage.Limit.FormatCurrencyAmt.Amt : coverage.Limit[0].FormatCurrencyAmt.Amt)}`}
            </div>
          </div>)
      default:
        return null;
    }
  }

  formatDriver = (driver, index) => {
    const name = get(driver, 'GeneralPartyInfo.NameInfo.PersonName.GivenName', '')
    const lastName = get(driver, 'GeneralPartyInfo.NameInfo.PersonName.Surname', '')

    return <div key={`excluded-driver-${index}`}>{`${name} ${lastName}`}</div>
  }

  getDriverLists = (drivers) => {
    const ratedDrivers = drivers
      .filter((driver) => get(driver, 'PersDriverInfo.DriverTypeCd', '') !== "E")
      .map(this.formatDriver)

    const excludedDrivers = drivers
      .filter((driver) => get(driver, 'PersDriverInfo.DriverTypeCd', '') === "E")
      .map(this.formatDriver)

    return [ratedDrivers, excludedDrivers]
  }

  render() {
    const { selectedTab } = this.state
    const { getters } = this.props
    const name = `${getters.driver.GivenName('DRV1')} ${getters.driver.Surname('DRV1') || ''}`
    const gender = getters.driver.GenderCd('DRV1')
    const maritalStatus = this.getMaritalStatus(getters.driver.MaritalStatusCd('DRV1'))
    const birthDate = getters.driver.BirthDt('DRV1')
    const address = getters.policy.Addr1()
    const city = getters.policy.City()
    const stateProv = getters.policy.StateProvCd()
    const zipCode = getters.policy.PostalCode('DRV1')
    const drivers = getters.general.getDrivers() || []
    const vehicles = getters.general.getVehicles() || []
    const coverageOptions = get(vehicles, '0.Coverage')
    const primary = themes.main.mui.palette.primary.main
    const [ratedDrivers, excludedDrivers] = this.getDriverLists(drivers)
    const vehiclesList = vehicles.length > 0 && vehicles.map((vehicle, index) => {
      const year = get(vehicle, 'ModelYear', '')
      const manufacturer = get(vehicle, 'Manufacturer', '')
      const model = get(vehicle, 'Model', '')
      const vehicleSpecific = get(vehicle, 'Coverage').filter((x) => [
        'UMPD',
        'RREIM',
        'RGP',
        'RSA',
        'NSDRSA',
        'COLL',
        'COMP'
      ].includes(x.CoverageCd))
      return (
        <div key={`vehicle-${index}`} style={{ paddingLeft: "1px" }}>
          {`${year} ${manufacturer} ${model}`}<br />
          {vehicleSpecific && <ul>
            {vehicleSpecific.map(coverage => {
              return coverage.CoverageCd === "RREIM" ?
                <li key={coverage.CoverageCd + "-limit"}>
                  <span className="vehicle-specific-coverages">
                    {`${coverage.CoverageDesc} - `}
                    {`$${parseInt(coverage.Limit[0].FormatCurrencyAmt.Amt)}/day ${parseInt(coverage.Limit[1].FormatCurrencyAmt.Amt)} days max`}
                  </span>
                </li>
                : <React.Fragment key={coverage.CoverageCd}>
                  {coverage.Limit &&
                    <li key={coverage.CoverageCd + "-limit"}>
                      <span className="vehicle-specific-coverages">
                        {`${coverage.CoverageDesc.replace("Coverage", "")} Limit - `}
                        {`$${Array.isArray(coverage.Limit) ? parseInt(coverage.Limit[0].FormatCurrencyAmt.Amt) : parseInt(coverage.Limit.FormatCurrencyAmt.Amt)}`}
                      </span>
                    </li>
                  }
                  {coverage.Deductible &&
                    <li key={coverage.CoverageCd}>
                      <span className="vehicle-specific-coverages">
                        {`${coverage.CoverageDesc.replace("Coverage", "")} Deductible - `}
                        {`$${Array.isArray(coverage.Deductible) ? parseInt(coverage.Deductible[0].FormatCurrencyAmt.Amt) : parseInt(coverage.Deductible.FormatCurrencyAmt.Amt)}`}
                      </span>
                    </li>
                  }
                  {coverage.Option && coverage.Option.OptionCd === "Yes" &&
                    <li key={coverage.CoverageCd}>
                      <span className="vehicle-specific-coverages">
                        {`${coverage.CoverageDesc}`}
                      </span>
                    </li>
                  }
                </React.Fragment>
            })
            }
          </ul>
          }
        </div>
      )
    })
    const coverageListIcons = coverageOptions.length > 0 && coverageOptions.map((coverage, index) =>
      coverage.ChangeInWrittenPremium !== "0" && this.getCoverageIcon(coverage.CoverageDesc, coverage, coverage.CoverageCd, index)
    )

    const policyInformation = (mobile) => (
      <div className={mobile ? 'policy-information-mobile' : 'policy-information'}>
        <div className="coverage-info-title">
          <div style={{ fontWeight: '700' }}>Policy Information</div>
          {getters.general.isEditable() &&
            <div onClick={this.handleEditSection('policy')}>
              <Edit className="icon" style={{ color: primary }} />
            </div>
          }
        </div>
        <div className="general-info">
          <div>{name}</div>
          <div>{`${maritalStatus} - ${gender === 'M' ? 'Male' : 'Female'}`}</div>
          <div>{formatDateString(birthDate)}</div>
          <div>{address}</div>
          <div>{`${city}, ${stateProv} ${zipCode}`}</div>
        </div>
        <div className="coverage-info-title">
          <div style={{ fontWeight: '700' }}>Drivers</div>
          {getters.general.isEditable() &&
            <div onClick={this.handleEditSection('drivers')}>
              <Edit className="icon" style={{ color: primary }} />
            </div>
          }
        </div>
        <div className="general-info">
          {ratedDrivers}
        </div>
        {!!excludedDrivers.length &&
          <React.Fragment>
            <div className="coverage-info-title">
              <div style={{ fontWeight: '700' }}>Excluded Drivers</div>
              {getters.general.isEditable() &&
                <div onClick={this.handleEditSection('drivers')}>
                  <Edit className="icon" style={{ color: primary }} />
                </div>
              }
            </div>
            <div className="general-info">
              {excludedDrivers}
            </div>
          </React.Fragment>
        }
        <div className="coverage-info-title">
          <div style={{ fontWeight: '700' }}>Vehicles</div>
          {getters.general.isEditable() &&
            <div onClick={this.handleEditSection('vehicles')}>
              <Edit className="icon" style={{ color: primary }} />
            </div>
          }
        </div>
        <div className="general-info">
          {vehiclesList}
        </div>
        <Button
          onClick={this.handleDownloadQuoteDec}
          buttonText={'Download Quote'}
          width="200px"
        />
      </div>
    )

    const selected = selectedTab === '1'
    return (
      <div className="coverage-info">
        <LoadingModal
          fontSize="18px"
          isOpen={this.state.isLoading}
          headline={'Downloading your documents'}>
        </LoadingModal>
        <ErrorModal
          errorText={`An error has occurred when downloading your quote. Please try again.`}
          isOpen={this.state.downloadError}
          setter={() => { this.setState({ downloadError: false }) }}
          headline="An Error Occurred">
        </ErrorModal>
        <MediaQuery maxWidth={725}>
          {(matches) => matches ? (
            <div className="mobile-container">
              <div className="tabs">
                <div
                  id={1}
                  className={selected ? 'tab-selected' : 'tab'}
                  style={selected ? { '--background-color': primary } : {}}
                  onClick={this.handleChangeTab}
                >
                  POLICY INFORMATION
                </div>
                <div
                  className={selected ? 'tab' : 'tab-selected'}
                  id={2}
                  style={!selected ? { backgroundColor: primary } : {}}
                  onClick={this.handleChangeTab}
                >
                  COVERAGE
                </div>
              </div>
              <TabPanel value={selectedTab} index={'1'}>
                {policyInformation(matches)}
              </TabPanel>
              <TabPanel value={selectedTab} index={'2'}>
                <div className="coverage-mobile">
                  <div className="title">
                    <div style={{ fontWeight: '700' }}>Coverages</div>
                    {getters.general.isEditable() &&
                      <div onClick={this.handleEditSection('coverages')}>
                        <Edit className="icon" style={{ color: primary }} />
                      </div>
                    }
                  </div>
                  <div className="coverages-list">
                    {coverageListIcons}
                  </div>
                </div>
              </TabPanel>
            </div>
          ) : (
            <>
              {policyInformation(matches)}
              <div className="coverage">
                <div className="title">
                  <div style={{ fontWeight: '700' }}>Coverages</div>
                  {getters.general.isEditable() &&
                    <div onClick={this.handleEditSection('coverages')}>
                      <Edit className="icon" style={{ color: primary }} />
                    </div>
                  }
                </div>
                <div className="coverages-list">
                  {coverageListIcons}
                </div>
              </div>
            </>
          )}
        </MediaQuery>
      </div>
    )
  }

  handleDownloadQuoteDec = async () => {
    const { getters } = this.props

    this.setState({
      isLoading: true
    })
    const auth = this.props.getters.general.Auth()

    try {
      const documents = await quoteDocs(auth, getters.policy.PolicyNumber())

      const byteCharacters = atob(documents)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      var blob = new Blob([byteArray], { type: "application/pdf" })
      saveAs(blob, 'QuoteDocuments.pdf')
    } catch (err) {
      console.log(err)
      this.setState({ downloadError: true })
    }

    this.setState({ isLoading: false })

  }

  handleEditSection = screenId => evt => {
    const { history, setters } = this.props
    const { agencyName } = this.context
    const url = screenId === 'policy' ? `/${agencyName}/${screenId}/name` : `/${agencyName}/${screenId}`
    setters.general.EditFromReviewScreen(true)
    history.push(url)
  }

  handleChangeTab = (evt) => {
    const { id } = evt.target
    this.setState({ selectedTab: id })
  }
}

export default withRouter(ReviewPolicyInfo)
