const mapSteps = [
    {
        name: 'Basic Information',
        path: ['name', 'address'],
        index: 1,
        nextIndex: 2,
        key: 'basic-info',
    },
    {
        name: 'Vehicle Information',
        path: ['vehicles', 'add-vehicle', 'vin'],
        index: 2,
        nextIndex: 3,
        allPathsVisitedToCompleted: true,
        pathToCompleted: 'vehicles',
        key: 'vehicles-info',
    },
    {
        name: 'Policy Information',
        path: ['policy/name', 'policy/license', 'policy/home'],
        index: 3,
        nextIndex: 4,
        key: 'policy-info',
    },
    {
        name: 'Driver Information',
        path: ['drivers', 'drivers/name', 'drivers/license'],
        index: 4,
        nextIndex: 5,
        key: 'drivers-info',
    },
    {
        name: 'Quote',
        path: ['coverages'],
        index: 5,
        nextIndex: 6,
        key: 'coverages-info',
    },
    {
        name: 'Review',
        path: ['leasing','review'],
        index: 6,
        nextIndex: 7,
        key: 'reviews',
        hiddenInBurger: true,
    },
]
const keysStorage = {
    STATE: 'U1RBVEU=',
    STEPS: 'U1RFUFM=',
    TIME_IN_SCREEN: '8A8SHJ8=',
    DOCUSIGN_URL: 'T43GS4G='
}

export { mapSteps, keysStorage }
