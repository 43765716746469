import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ComprehensiveIcon } from '../../../../assets/coverages/comprehensive.svg'

const Comprehensive = ({ color = '#7F1819' }) => {
  return (
    <ComprehensiveIcon fill={color} />
  )
}

Comprehensive.propTypes = {
  color: PropTypes.string
}

export default Comprehensive