import crypto from './encrypted'

const saveInStorage = (key, object) => {
    localStorage.setItem(
        `${process.env.REACT_APP_NAME_STATE_LOCALSTORAGE}_${key}`,
        crypto.encrypt(JSON.stringify(object))
    )
}

const getFromStorage = (key) => {
    let state_data = localStorage.getItem(`${process.env.REACT_APP_NAME_STATE_LOCALSTORAGE}_${key}`)
    state_data = state_data !== null && state_data !== undefined ? crypto.decrypt(state_data) : state_data
    return state_data === null || state_data === undefined ? state_data : JSON.parse(state_data)
}

const clearAllStorage = () => {
    localStorage.clear()
}

const removeItem = (key) => {
    localStorage.removeItem(`${process.env.REACT_APP_NAME_STATE_LOCALSTORAGE}_${key}`)
}

const exported = { saveInStorage, getFromStorage, clearAllStorage, removeItem }

export default exported
