import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProgressBarPlugin from '@ramonak/react-progress-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { mapSteps, keysStorage } from '../../helpers/constants'
import localStorageService from '../../helpers/localStorage'
import SideBar from './sidebar'
import themes from '../../themes'
import './bar.scss'
class ProgressBar extends Component {
    static propTypes = {
        currentPath: PropTypes.string.isRequired,
    }

    state = {
        currentStep: {
            name: '',
            index: 0,
        },
        nextStep: {
            name: '',
            index: 0,
        },
        progress: '',
        isOpenBurgerMenu: false,
        stepsCompleted: [],
        viewBar: true,
        disableBurger: false,
    }

    componentDidUpdate(prevProps) {
        const path = prevProps.currentPath.split('/')
        if (path[0] === 'bridgev4' && !this.state.disableBurger) {
            this.setState({ disableBurger: true })
            localStorageService.saveInStorage(keysStorage.STEPS, [2, 3, 4, 5, 6])
        } else if (this.props.currentPath === 'review' && this.state.disableBurger) {
            this.setState({ disableBurger: false })
        } else if (this.props.currentPath !== prevProps.currentPath) {
            this.getInformationStep()
        }
    }

    componentDidMount() {
        this.getInformationStep()
    }

    getInformationStep = () => {
        const lsStepsCompleted =
            localStorageService.getFromStorage(keysStorage.STEPS) === null
                ? []
                : localStorageService.getFromStorage(keysStorage.STEPS)
        if (!mapSteps.some((step) => step.path.includes(this.props.currentPath))) {
            this.setState({ viewBar: false })
            return
        }
        if (mapSteps.some((step) => step.path.includes(this.props.currentPath))) {
            let currentStepFilter = mapSteps.filter((step) => step.path.includes(this.props.currentPath))[0]

            let allPathsHasIsVisited = !currentStepFilter.allPathsVisitedToCompleted

            if (
                currentStepFilter.allPathsVisitedToCompleted &&
                currentStepFilter.pathToCompleted === this.props.currentPath
            ) {
                allPathsHasIsVisited = true
            } else if (
                currentStepFilter.allPathsVisitedToCompleted &&
                currentStepFilter.pathToCompleted !== this.props.currentPath
            ) {
                allPathsHasIsVisited = false
                currentStepFilter = mapSteps.filter((step) => step.nextIndex === currentStepFilter.index)[0]
            }

            const nextStepFilter =
                currentStepFilter.index === mapSteps.length ||
                    !mapSteps.some((step) => step.index === currentStepFilter.nextIndex)
                    ? { name: 'Finally', index: mapSteps.length + 1, isFinally: true }
                    : mapSteps.filter((step) => step.index === currentStepFilter.nextIndex)[0]

            const progressCalc = this.calculateProgress(currentStepFilter)

            if (this.state.stepsCompleted.includes(currentStepFilter.index) && lsStepsCompleted === []) {
                this.setState({
                    currentStep: currentStepFilter,
                    nextStep: nextStepFilter,
                    progress: progressCalc,
                    viewBar: true,
                })
            } else if (
                this.state.stepsCompleted.includes(currentStepFilter.index) ||
                lsStepsCompleted.includes(currentStepFilter.index)
            ) {
                this.setState({
                    currentStep: currentStepFilter,
                    nextStep: nextStepFilter,
                    progress: progressCalc,
                    stepsCompleted: lsStepsCompleted,
                    viewBar: true,
                })
            } else {
                let stepsCompletedArray = this.state.stepsCompleted
                if (allPathsHasIsVisited) {
                    stepsCompletedArray.push(currentStepFilter.index)
                }
                this.setState({
                    currentStep: currentStepFilter,
                    nextStep: nextStepFilter,
                    progress: progressCalc,
                    stepsCompleted: stepsCompletedArray,
                    viewBar: true,
                })
                localStorageService.removeItem(keysStorage.STEPS)
                localStorageService.saveInStorage(keysStorage.STEPS, stepsCompletedArray)
            }
        } else {
            this.setState({
                currentStep: {
                    name: '',
                    index: 0,
                },
                nextStep: {
                    name: '',
                    index: 0,
                },
                progress: '',
                isOpenBurgerMenu: false,
                stepsCompleted: [],
                viewBar: true,
            })
            if (lsStepsCompleted === null) {
                localStorageService.removeItem(keysStorage.STEPS)
                localStorageService.saveInStorage(keysStorage.STEPS, [])
            }
        }
        if (lsStepsCompleted === null && (this.state.stepsCompleted === null || this.state.stepsCompleted === [])) {
            this.setState({
                currentStep: {
                    name: '',
                    index: 0,
                },
                nextStep: {
                    name: '',
                    index: 0,
                },
                progress: '',
                isOpenBurgerMenu: false,
                stepsCompleted: [],
                viewBar: true,
            })
            if (lsStepsCompleted === null) {
                localStorageService.removeItem(keysStorage.STEPS)
                localStorageService.saveInStorage(keysStorage.STEPS, [])
            }
        }
    }

    UNSAFE_componentWillMount = () => {
        let state_data = localStorageService.getFromStorage(keysStorage.STEPS)
        if (state_data !== null && state_data !== undefined) {
            this.setState({ stepsCompleted: state_data })
        }
    }

    calculateProgress = (currentStepFilter) => {
        const index =
            currentStepFilter.indexParent === undefined ? currentStepFilter.index : currentStepFilter.indexParent
        return index === mapSteps.filter((x) => x.indexParent === undefined).length
            ? 100
            : (100 / parseInt(mapSteps.filter((x) => x.indexParent === undefined).length)) * index
    }

    handleOnOpen = () => {
        const { disableBurger } = this.state

        if (!disableBurger) {
            this.setState({ isOpenBurgerMenu: !this.state.isOpenBurgerMenu })
        }
    }

    handleStateChange(state) {
        this.setState({ isOpenBurgerMenu: state.isOpen })
    }

    render() {
        const { disableBurger } = this.state
        const primaryMainColor = themes.main.mui.palette.primary.main
        const secondaryMainColor = themes.main.mui.palette.secondary.main
        return (
            <div className="main-progress-bar" style={!this.state.viewBar ? { display: 'none' } : {}}>
                <div className="bar-burger-container">
                    <SideBar
                        setState={(state) => this.setState(state)}
                        isOpen={this.state.isOpenBurgerMenu}
                        handleStateChange={(state) => {
                            this.handleStateChange(state)
                        }}
                        currentStep={this.state.currentStep}
                        stepsVisited={this.state.stepsCompleted}
                    />
                </div>
                <div className="bar-steps">
                    <div className={disableBurger ? 'disabled-menu' : 'bar-current-icon'} onClick={this.handleOnOpen}>
                        <FontAwesomeIcon icon={faBars} size="2x" />
                    </div>
                    <div className="bar-current-step">
                        <h3 className="bar-title-step" style={{ opacity: disableBurger && 0.3 }}>
                            {this.state.currentStep.name}
                        </h3>
                    </div>
                    <div className={this.state.nextStep.isFinally ? 'bar-next-step--hidden' : 'bar-next-step'}>
                        <h3 className="bar-title-step">Next: {this.state.nextStep.name}</h3>
                    </div>
                </div>
                <div
                    className="custom-bar-style"
                    style={{ '--primary-color': primaryMainColor, '--secondary-color': secondaryMainColor }}>
                    <ProgressBarPlugin
                        completed={this.state.progress}
                        bgColor={themes.main.mui.palette.primary.main}
                        isLabelVisible={false}
                        borderRadius={'0px'}
                        height={'25px'}
                        className="custom-bar-style"
                    />
                </div>
            </div>
        )
    }
}
export default ProgressBar
