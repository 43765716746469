import React from 'react'

import './VehicleInfo.scss'
const VehicleInfoUnSelected = (props) => {
    return (
        <svg
            className={props.className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 610.7 355.5"
            xmlSpace="preserve">
            <g>
                <path
                    className="viu-st0"
                    style={{ fill: props.primary }}
                    d="M308.2,57.5c-30.8,0-54.7,9-72.9,27.4c-20.5,20.8-29.7,49.8-33.7,71.8h133.9V57.4c-1.5,0-2.9,0-4.4,0
               C323.5,57.5,315.9,57.5,308.2,57.5z M321.4,142.7H219.2c4.7-16.3,12.6-34.3,26.1-48c15.4-15.6,36-23.2,62.9-23.2
               c4.5,0,8.9,0,13.2,0V142.7z"
                />
                <path
                    className="viu-st0"
                    style={{ fill: props.primary }}
                    d="M595.4,174.8c-7.9-10.7-18.1-19.1-29.9-24.6c-2-21.2-5.9-39.4-12-55.6c-7.1-18.9-16.9-34.5-30.1-47.5
               C494,17.8,453.8,7.9,407.3,4.7c-30.4-4.9-64.9-4.8-102.5-4.6l-8.5,0c-12.3-0.2-25.2-0.1-37.7,0l-2.8,0c-11.5-0.1-23-0.1-35.2,0
               l-0.6,0c-7.4,0-14.9,0.1-22.5,0.1c-41.9,0-76.4,14.5-102.3,43.1C64.9,76.5,53.3,121.4,49,153.3c-28.8,8.2-49,36.3-49,68.5
               c0,26.9,14.5,51.8,37,63.7c2.1,19,10.6,36.6,23.9,49.6c13.5,13.2,31,20.5,49.1,20.5c6.5,0,12.9-0.9,19.2-2.8
               c6.2,1.8,12.7,2.8,19.1,2.8c7.9,0,15.6-1.4,23.1-4.1c7.5,2.7,15.2,4.1,23.1,4.1c7.1,0,14.1-1.1,20.7-3.2c2.8,0.4,5.6,0.6,8.5,0.6
               c32.3,0,59.4-23,65.6-53.6c1.3,0.4,2.6,0.6,4.1,0.6h0.1c4.4,15.1,13.2,28.7,24.9,38.6c12.9,11,29,17,45.1,17c6.5,0,13-0.9,19.2-2.8
               c6.2,1.8,12.6,2.8,19,2.8c7.9,0,15.7-1.4,23.2-4.1c7.5,2.7,15.2,4.1,23,4.1c6.7,0,13.3-1,19.6-2.9c1.6,0.1,3.3,0.2,4.9,0.2
               c32.6,0,59.8-23.4,65.7-54.3c1.3,0.4,2.6,0.6,3.9,0.6c0.7,0,1.4-0.1,2.1-0.2c18.3-2.8,35.2-12.2,47.4-26.3
               c12.4-14.3,19.2-32.5,19.2-51.4C610.7,204.5,605.4,188.4,595.4,174.8z M197.5,1.9c4.8,0,9.5,0,14.2,0
               c-31.2,4.6-57.5,18.5-78.3,41.4c-30.3,33.3-41.8,78.2-46.2,110.1c-28.8,8.2-49,36.3-49,68.5c0,26.9,14.5,51.8,37,63.7
               c2.1,19,10.6,36.6,23.9,49.6c7.8,7.6,16.9,13.3,26.9,16.7c-5.3,1.3-10.7,2-16.1,2c-17.7,0-34.6-7.1-47.9-20
               c-13.1-12.8-21.4-30.2-23.4-49l-0.1-0.5l-0.4-0.2c-22.1-11.5-36.4-35.9-36.4-62.3c0-31.7,19.8-59.2,48.2-67l0.6-0.2l0.1-0.6
               c4.3-31.8,15.7-76.6,45.8-109.7C122.1,16.2,156.1,1.9,197.5,1.9z M76.9,284.4l-0.4-0.2C54.3,272.7,40,248.2,40,221.8
               c0-31.7,19.8-59.2,48.2-67l0.6-0.2l0.1-0.6c4.3-31.8,15.7-76.6,45.8-109.7c25.6-28.2,59.6-42.5,101.1-42.5c6.8,0,13.5,0,20.1-0.1
               c0.7,0,1.3,0,2,0c-31.2,4.6-57.5,18.5-78.3,41.4v0c-30.3,33.3-41.8,78.2-46.2,110.1c-28.8,8.2-49,36.3-49,68.5
               c0,26.9,14.5,51.8,37,63.7c2.1,19,10.6,36.6,23.9,49.6c1.7,1.6,3.5,3.3,5.5,4.8c-6.6,5-13.9,8.7-21.7,11.1
               c-10.6-3.2-20.5-9.1-28.7-17.1c-13.1-12.8-21.4-30.2-23.4-49L76.9,284.4z M152.2,341c5.1,3.9,10.7,7.1,16.6,9.5
               c-11.8,3.9-24.4,4.3-36.6,1.3C139.3,349.3,146,345.7,152.2,341z M194.3,353.8c-6.9,0-13.8-1.1-20.5-3.3c5-2,9.8-4.7,14.3-7.8
               c6.9,4.3,14.6,7.4,22.8,9C205.6,353.1,200,353.8,194.3,353.8z M171.3,349.6c-6.3-2.3-12.2-5.6-17.7-9.7c5.7-4.5,10.7-10,14.9-16
               c4.8,7,11,13,18,17.8C181.8,344.9,176.7,347.6,171.3,349.6z M157.3,295.4c1.4,9.8,4.9,19,10.1,27c-4.3,6.3-9.4,11.8-15.3,16.5
               c-2-1.6-3.9-3.3-5.7-5c-13.1-12.8-21.4-30.2-23.4-49l0-0.5l-0.4-0.2c-22.1-11.5-36.4-35.9-36.4-62.3c0-31.6,19.7-59.1,48-66.9
               c-7.5,4.7-14.2,10.7-19.7,17.7c-10.9,13.8-17,31.1-17,48.7c0,32.6,20.6,62.2,51.2,73.6c1.6,0.6,3.3,0.9,4.9,0.9
               C154.9,295.8,156.1,295.7,157.3,295.4z M135.1,154.1c4.3-31.8,15.7-76.6,45.8-109.7c23.6-26,54.5-40.2,91.6-42.3
               c9.2,0.3,17.7,0.7,25.7,1.4c-41.1,2-75.2,16.6-101.7,43.5c-30,30.5-43,70.3-48.5,100.9c-4.5,1.7-8.8,3.9-13,6.5L135.1,154.1z
                M330.9,3.2h-0.1c-5.5,0-11.6,0.1-17.8,0.1c-4.3-0.5-8.7-1-13.3-1.4c10.9,0.2,21.9,0.5,32.9,1.3C332,3.2,331.5,3.2,330.9,3.2z
                M351,3.2c-0.3,0-0.5,0-0.8,0c-4.4-0.5-8.9-1-13.6-1.4c15.6,0.1,31,0.5,45.7,1.7C372.1,3.2,361.6,3.2,351,3.2z M223.6,338.9
               c-29.1,0-53-23.8-53-53c0-11.7,3.9-22.6,10.4-31.4c10.4-11.8,25.6-19.2,42.2-19.2c19.2,0,36.5,9.9,46.7,25
               c4.2,7.6,6.6,16.3,6.6,25.6C276.5,315,252.7,338.9,223.6,338.9z M406.6,299.1c2,9.8,6.1,18.9,11.8,26.6c-3.8,4.9-8,9.3-12.8,13.1
               c-12.3-9.8-21.4-23.4-26.1-38.9h22.3C403.6,299.9,405.1,299.6,406.6,299.1z M422.3,350.5c-11.8,3.8-24.4,4.4-36.6,1.3
               c7.2-2.5,13.9-6.1,20.1-10.8C410.9,344.9,416.4,348.1,422.3,350.5z M447.8,353.8c-6.9,0-13.8-1.1-20.4-3.3c4.2-1.7,8.2-3.8,12-6.3
               c7,4,14.7,6.7,23,7.9C457.6,353.2,452.7,353.8,447.8,353.8z M424.8,349.6c-6.3-2.3-12.2-5.6-17.7-9.7c4.6-3.7,8.8-8,12.4-12.7
               c5,6.4,11.1,11.9,18.1,16.1C433.6,345.8,429.3,347.9,424.8,349.6z M377.8,299.9c4.7,15.9,13.9,29.9,26.5,40
               c-6.6,5-13.9,8.7-21.7,11.1c-23.3-7.1-41.8-26.4-49.2-51H377.8z M331.6,299.9c7.2,24.4,25.1,44,47.9,51.9c-5.3,1.3-10.8,2-16.2,2
               c-15.7,0-31.4-5.9-44-16.5c-11.4-9.6-19.8-22.7-24.2-37.3H331.6z M472.3,338.9c-29.1,0-53-23.8-53-53c0-11.6,3.8-22.4,10.2-31.1
               c10.4-11.9,25.7-19.5,42.4-19.5c14.1,0,27.5,5.3,38,14.9c3.2,3,6.1,6.3,8.5,9.8c4.3,7.7,6.8,16.5,6.8,25.9
               C525.2,315,501.4,338.9,472.3,338.9z M541.9,285.2c-1.1-11.7-5.1-22.6-11.3-32c-11.5-20.4-33.3-34.2-58.4-34.2
               c-22.1,0-41.7,10.8-53.9,27.3c-9.2,10.9-15.3,24.6-16.5,39.7H293.3c-1-12-5-23.1-11.3-32.7c-11.5-20.4-33.3-34.2-58.4-34.2
               c-22.1,0-41.7,10.7-53.9,27.3c-8.4,9.9-14.2,22.2-16,35.6c-24.5-9.2-42.1-32.9-42.1-60.5c0-30.1,20.9-55.6,48.9-62.6
               c4.1-28.4,15.4-70.7,46.1-101.8c25.8-26.2,60-39.6,101.7-39.6c7.7,0,15.2,0,22.7-0.1c6.8,0,13.5-0.1,20.2-0.1
               c67.7,0,125.6,3,162.6,39.9c23.1,23,35.6,56.1,38.6,102.9c25.7,8.5,44.5,32.8,44.5,61.3C596.7,253.5,572.8,280.4,541.9,285.2z"
                />
                <path
                    className="viu-st0"
                    style={{ fill: props.primary }}
                    d="M375.6,57.6v99.2h136c-2.9-33-11.6-56.5-26.4-71.2C462.9,63.3,424.5,58.5,375.6,57.6z M389.6,142.7V72
               c39.3,1.5,69,6.8,85.7,23.5c10,9.9,16.8,25.8,20.4,47.3H389.6z"
                />
            </g>
        </svg>
    )
}
export default VehicleInfoUnSelected
