import React from 'react'
import { Modal, CircularProgress } from '@material-ui/core'
import './modal.css'
const LoadingModal = (props) => {

    const { headline, isOpen, fontSize } = props

    return (
        <Modal open={isOpen} hideBackdrop style={{backgroundColor: 'rgba(0,0,0,.2)', display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div className='modal'>
                <div className='modal-headline' style={{fontSize: fontSize && fontSize}}>
                    {headline}
                </div>
                <div className='modal-loader'>
                    <div
                        className="loading-progress"
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}>
                            <CircularProgress style={{height: '50px', width: '50px'}} color="primary" />
                    </div>
                </div>
            </div>
        </Modal>
    )

}

export default LoadingModal