import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import './sidebar.scss'
import Diagram from './diagram'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const SideBar = (props) => {
    let history = useHistory()
    return (
        <Menu
            className={'progress-burger'}
            {...props}
            disableAutoFocus
            pageWrapId={'main-bm-pw'}
            customBurgerIcon={false}
            customCrossIcon={false}
            isOpen={props.isOpen}
            onStateChange={(state) => props.handleStateChange(state)}>
            <div id="main-bm-pw" className={props.isOpen ? '' : 'progress-burger-main--hidden'}>
                <div
                    className="progress-burger-menu-close"
                    onClick={() => {
                        document.body.className = ''
                        props.setState({ isOpenBurgerMenu: false })
                    }}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="progress-burger-menu-title">Steps to your quote</div>
                <Diagram
                    closeBurgerMenu={() => {
                        document.body.className = ''
                        props.setState({ isOpenBurgerMenu: false })
                    }}
                    stepsVisited={props.stepsVisited}
                    currentStep={props.currentStep}
                    history={history}
                    {...props}></Diagram>
            </div>
        </Menu>
    )
}
SideBar.displayName = 'SideBar'
SideBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    currentStep: PropTypes.object.isRequired,
    stepsVisited: PropTypes.array.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
}

export default SideBar
