import React, { useEffect, useState } from 'react'
import themes from '../../themes'
import maskedInputCustom from '../maskedInputCustom/maskedInputCustom'
import { FormLabel, Input, FormHelperText } from '@material-ui/core'
import './combinedInput.scss'
import PropTypes from 'prop-types'

const CombinedInput = (props) => {
    const [currentValue, setValue] = useState('')
    const [isError, setError] = useState(false)
    const {
        large,
        label,
        color,
        buttonText,
        mask,
        submit,
        validation,
        errorMessage,
        validateOnChange,
        defaultValue,
        errorWidth
    } = props

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])

    return (
        <div className={`combined-input${large ? '-large' : ''}`}>
            <div className="combined-input-group">
                <div className="input-label">
                    <FormLabel>{label}</FormLabel>
                    {props.children && props.children}
                </div>
                <div className="combined-input-section">
                    <Input
                        disableUnderline
                        className={`combined${large ? '-large' : ''}`}
                        name={label}
                        spellCheck={false}
                        type="text"
                        value={currentValue}
                        onChange={(e) => {
                            if (validateOnChange && isError) {
                                setError(!validation(e.target.value))
                            }
                            setValue(e.target.value)
                        }}
                        inputComponent={mask && maskedInputCustom}
                        inputProps={{ mask: mask && mask }}></Input>
                    <div
                        className={`combined-button${large ? '-large' : ''}`}
                        style={{
                            backgroundColor: isError ? 'red' : color ? color : themes.main.mui.palette.primary.main,
                        }}
                        onClick={async () => {
                            if (!validation(currentValue)) {
                                setError(true)
                            } else {
                                const submitRes = await submit(currentValue)
                                setError(submitRes)
                            }
                        }}>
                        {buttonText}
                    </div>
                </div>
                <div className="helper">
                    <div className="sub-helper" style={{ maxWidth: errorWidth && errorWidth, opacity: isError ? '1' : '0' }}>
                        <FormHelperText error>{errorMessage}</FormHelperText>
                    </div>
                </div>
            </div>
        </div>
    )
}

CombinedInput.displayName = 'CombinedInput'
CombinedInput.propTypes = {
    label: PropTypes.string,
    large: PropTypes.any,
    color: PropTypes.string,
    buttonText: PropTypes.string,
    mask: PropTypes.any,
    submit: PropTypes.func,
    validation: PropTypes.func,
    errorMessage: PropTypes.string,
    validateOnChange: PropTypes.any,
    defaultValue: PropTypes.string,
    children: PropTypes.string,
}
export default CombinedInput
