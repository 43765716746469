import React from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import BasicInput from '../../../components/basicInput/basicInput'
import { formatDateString } from '../../../helpers/dateHelpers'
import Button from '../../../components/button/button'
import AgencyContext from '../../../context/agency-context'
import DropdownInput from '../../../components/dropdownInput/dropdownInput'
import RadioInput from '../../../components/radioInput/radioInput'
import InfoButton from '../../../components/infoButton/infoButton'
import { validateBirthDate } from '../../../views/form/validate'
import './name.scss'
class DriverName extends React.Component {

    state = {
        isError: [false, false, false, false, false, false, false],
        excluded: 'Yes',
        id: undefined,
        birthDateMessage: 'Please enter a valid birthdate',
        driverNameError: ''
    }
    static contextType = AgencyContext

    componentDidMount = () => {
        const { getters } = this.props
        if (this.props.values.quoteInfo) {
            const id = getters.general.SelectedDriver()
            const excludedDriver = id !== 'DRV1' && (getters.driver.LicensePermitNumber(id) === 'EXCLUDED' || (getters.driver.LicensePermitNumber(id) === '' && getters.driver.LicenseStatusCd(id) !== 'Matricula'))
            this.setState({
                excluded: excludedDriver
                    ? 'Yes' : 'No',
                birthDt: getters.driver.BirthDt(id) ? formatDateString(getters.driver.BirthDt(id)) : '',
                fullName: `${getters.driver.GivenName(id)
                    ? getters.driver.Surname(id)
                        ? `${getters.driver.GivenName(id)} ${getters.driver.Surname(id)}`
                        : `${getters.driver.GivenName(id)}`
                    : ''
                    }`,
                gender: getters.driver.GenderCd(id),
                maritalStatus: getters.driver.MaritalStatusCd(id),
                sr22: getters.driver.SR22(id),
            })
        }
    }

    nameCheck = evt => {
        const { values, setters, getters } = this.props;
        const { fullName, birthDt } = this.state
        let invalid = false
        const nameRegEx = /^[a-zA-Z ]+$/
        if (!nameRegEx.test(fullName) || !fullName) {
            invalid = true
            this.setState({ driverNameError: 'Please enter a valid name' })
        } else {

            if (fullName.split(' ').length < 2) {
                invalid = true
                this.setState({ driverNameError: 'Please enter a valid first name and last name' })
            }
            else {
                const id = getters.general.SelectedDriver()
                setters.driver.GivenName(id, '')
                setters.driver.Surname(id, '')
                const driversList = get(values, 'quoteInfo.request.ACORD.InsuranceSvcRq.PersAutoPolicyQuoteInqRq.PersAutoLineBusiness.PersDriver', [])
                const found = driversList.find(driver => {
                    const driverGivenName = get(driver, 'GeneralPartyInfo.NameInfo.PersonName.GivenName')
                    const driverSurName = get(driver, 'GeneralPartyInfo.NameInfo.PersonName.Surname')
                    const driverName = `${driverGivenName}${driverSurName ? ` ${driverSurName}` : ''}`
                    const driverBirthDt = get(driver, 'DriverInfo.PersonInfo.BirthDt')
                    return (driverName.toLowerCase() === fullName.toLowerCase() && driverBirthDt === birthDt)
                })
                if (!!found) {
                    invalid = true
                    this.setState({ driverNameError: 'A driver with that name and date of birth is already added' })
                }
            }
        }

        return invalid;
    }

    birthDateCheck = (value) => {
        const error = validateBirthDate(value)

        this.setState({
            birthDateMessage: error ? error : 'Please enter a valid birthdate',
        })

        if (!error) {
            return false
        } else {
            return true
        }
    }

    maritalCheck = (value) => {
        if (value && (value === 'S' || value === 'M' || value === 'D' || value === 'W')) {
            return false
        }
        return true
    }

    genderCheck = (value) => {
        if (value && (value === 'M' || value === 'F')) {
            return false
        }
        return true
    }

    SR22Check = (value) => {
        if (this.state.excluded === 'No') {
            if (value && (value === 'Yes' || value === 'No')) {
                return false
            }
            return true
        }
        return false
    }

    excludedCheck = (value) => {
        if (value && (value === 'Yes' || value === 'No')) {
            return false
        }
        return true
    }

    getAdditionalInfo = () => {
        return (
            <React.Fragment>
                <div className="policy-name-dropdowns">
                    <DropdownInput
                        label="Gender"
                        options={[
                            { value: '', label: '' },
                            { value: 'M', label: 'Male' },
                            { value: 'F', label: 'Female' },
                        ]}
                        defaultValue={this.state.gender}
                        onChange={(val) => {
                            this.setState({
                                gender: val,
                            })
                        }}
                        isError={this.state.isError[3]}
                        errorMessage={'Please enter a gender'}></DropdownInput>
                    <DropdownInput
                        label="Marital Status"
                        options={[
                            { value: '', label: '' },
                            { value: 'S', label: 'Single' },
                            { value: 'M', label: 'Married' },
                            { value: 'D', label: 'Divorced' },
                            { value: 'W', label: 'Widowed' },
                        ]}
                        defaultValue={this.state.maritalStatus}
                        onChange={(val) => {
                            this.setState({
                                maritalStatus: val,
                            })
                        }}
                        isError={this.state.isError[4]}
                        errorMessage={'Please enter your marital status'}></DropdownInput>
                </div>
                {this.state.excluded === 'No' && <RadioInput
                    inLine={true}
                    label="Do you need SR22?"
                    style={{ marginTop: '56px' }}
                    defaultValue={this.state.sr22}
                    valueFront={this.state.sr22}
                    onChange={(val) => {
                        this.setState({
                            sr22: val,
                        })
                    }}
                    isError={this.state.isError[5]}
                    errorMessage={'Please enter if you need SR22 Coverage'}>
                    <InfoButton
                        title="What is SR22?"
                        text={
                            "An SR22 form is an official document that proves that you've purchased the minimum liability insurance available in your state."
                        }></InfoButton>
                </RadioInput>}
            </React.Fragment>
        )
    }

    checkAll = () => {
        const { setters } = this.props

        this.setState(
            {
                isError: [
                    this.excludedCheck(this.state.excluded),
                    this.nameCheck(this.state.fullName),
                    this.birthDateCheck(this.state.birthDt),
                    this.genderCheck(this.state.gender),
                    this.maritalCheck(this.state.maritalStatus),
                    this.SR22Check(this.state.sr22),
                ],
            },
            () => {
                var errors = this.state.isError

                if (!errors.includes(true)) {
                    const id = this.props.getters.general.SelectedDriver()

                    const fullName = this.state.fullName.split(' ')
                    setters.driver.GivenName(id, fullName[0])
                    setters.driver.Surname(id, fullName[1])
                    setters.driver.GenderCd(id, this.state.gender)
                    setters.driver.BirthDt(id, new Date(this.state.birthDt))
                    setters.driver.MaritalStatusCd(id, this.state.maritalStatus)
                    setters.driver.SR22(id, this.state.sr22)

                    if (id === 'DRV1') {
                        setters.policy.GivenName(fullName[0])
                        setters.policy.Surname(fullName[1])
                        this.props.history.push(`/${this.context.agencyName}/drivers`)
                    } else {
                        if (this.state.excluded === 'Yes') {
                            setters.driver.LicensePermitNumber(id, 'EXCLUDED') // set excluded status
                            this.props.history.push(`/${this.context.agencyName}/drivers`)
                        } else {
                            this.props.history.push(`/${this.context.agencyName}/drivers/license`)
                        }
                    }
                }
            }
        )
    }

    render = () => {
        const { driverNameError, excluded, fullName, birthDt, birthDateMessage, isError } = this.state
        return (
            <div className="driver-name">
                <div className="quote-headline-two">Please enter this Driver's information</div>
                <div className="policy-input-block">
                    <div
                        className="driver-input-section"
                        style={{ height: excluded === 'No' ? '560px' : '480px' }}>
                        {this.props.getters.general.SelectedDriver() !== 'DRV1' && (
                            <RadioInput
                                inLine={true}
                                label="Is this an excluded driver?"
                                defaultValue={'Yes'}
                                valueFront={excluded}
                                onChange={(val) => {
                                    this.setState({
                                        excluded: val,
                                    })
                                }}
                                isError={isError[0]}
                                errorMessage={'Please enter if this is an excluded driver'}>
                                <InfoButton
                                    title="Excluded Drivers"
                                    text={
                                        'An excluded driver is a driver who will not be covered on your policy'
                                    }
                                />
                            </RadioInput>
                        )}
                        <BasicInput
                            label="Full Name"
                            defaultValue={fullName}
                            onChange={(val) => {
                                this.setState({
                                    fullName: val,
                                })
                            }}
                            isError={isError[1]}
                            errorMessage={driverNameError}
                        />
                        <BasicInput
                            defaultValue={birthDt}
                            label="Date of Birth"
                            onChange={(val) => {
                                this.setState({
                                    birthDt: val,
                                })
                            }}
                            isError={isError[2]}
                            errorMessage={birthDateMessage}
                            mask={[
                                /[01]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                            ]}
                        />
                        {this.getAdditionalInfo()}
                    </div>
                    <Button buttonText="Continue" onClick={this.checkAll} />
                </div>
            </div>
        )
    }
}

export default withRouter(DriverName)
